import React, { Fragment, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header.css";
import { Image } from "react-bootstrap";
import { Menu } from "lucide-react";

const Header_2 = (props) => {
  const showSubscribePageOnly = props.showSubscribePageOnly;
  const userDetails = props.userDetails;
  const analyticsSusbscribed = props.analyticsSusbscribed;
  const tokenLoaded = props.tokenLoaded;
  const setOpenModal = props.setOpenModal;
  const toggleSidebar = props.toggleSidebar;

  const [extendedView, setExtendedView] = useState(true);

  function toggleExtendedView() {
    if (showSubscribePageOnly) {
      return 0;
    }
    setExtendedView(!extendedView);
    if (extendedView) {
      document.body.classList.add("toggle-sidebar");
    } else {
      document.body.classList.remove("toggle-sidebar");
    }
  }
  if (showSubscribePageOnly && tokenLoaded) {
    document.body.classList.add("toggle-sidebar");
  }

  let location = window.location.href;

  const url =
    "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  const UserMenu = (
    <Image
      src={"assets/img/avataar.png"}
      alt="UserName profile image"
      roundedCircle
      style={{ width: "40px", height: "40px", objectFit: "cover" }}
    />
  );

  return (
    <Navbar
      fixed={"top"}
      collapseOnSelect
      bg="white"
      expand="xl"
      className=" header-pd"
      style={{ height: "50px", paddingLeft: "0px" }}
    >
      <Container fluid className="px-0">
        <div style={{ display: "flex", gap: 10 }}>
          {/* {showSubscribePageOnly ? null : (
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={toggleExtendedView}
            />
          )} */}
          {/* <Navbar.Toggle onClick={toggleExtendedView} className="toggler" /> */}
          {/* <div style={{ marginRight: "10px" }} /> */}

          <div className="cursor-pointer min-w-[45px] justify-center rounded-lg flex items-center">
            <Menu onClick={toggleSidebar} size={"25px"} />
          </div>

          <Navbar.Brand href="#home" lg={4}>
            <img
              src="assets/img/LD_logo_banner_long_dark_bg.png"
              alt=""
              className="image-cl"
            />
          </Navbar.Brand>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
          }}
        >
          <NavDropdown title={UserMenu} id="nav-dropdown">
            <NavDropdown.Item eventKey="4.1">
              <h6>{userDetails.name ? userDetails.name : "NA"}</h6>
              <span>
                {userDetails.roles ? userDetails.roles.join(", ") : "NA"}
              </span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="4.2">
              <a
                className="dropdown-item d-flex align-items-center"
                href={process.env.REACT_APP_API_URL + "/#/users/2"}
                target="_blank"
              >
                <i className="bi bi-person"></i>
                <span>My Profile</span>
              </a>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="4.3">
              <a className="dropdown-item d-flex align-items-center" href="#/">
                <i className="bi bi-envelope"></i>
                <span>{userDetails.email || "NA"}</span>
              </a>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="4.4">
              {" "}
              <a className="dropdown-item d-flex align-items-center" href="#/">
                <i className="bi bi-telephone"></i>
                <span>{userDetails.phone || "NA"}</span>
              </a>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="4.4">
              {" "}
              <a className="dropdown-item d-flex align-items-center" href="#/">
                <i className="bi bi-check-circle"></i>
                <span>{userDetails.state || "NA"}</span>
              </a>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="4.4">
              {" "}
              <a className="dropdown-item d-flex align-items-center" href="#/">
                {analyticsSusbscribed ? (
                  <>
                    {/* <i className="bi bi-bell-slash"></i> */}
                    <i className="bi bi-check-circle"></i>
                    <span>Subscribed</span>
                  </>
                ) : (
                  <>
                    {/* <i className="bi bi-bell"></i> */}
                    <i className="bi bi-clock-history"></i>
                    <span>Subscription Pending</span>
                  </>
                )}
              </a>
            </NavDropdown.Item>

            {location.includes("price_co-pilot") ? (
              <Fragment>
                <NavDropdown.Divider />

                <NavDropdown.Item
                  eventKey="4.4"
                  onClick={() => setOpenModal(true)}
                >
                  {" "}
                  <div className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-check-circle"></i>
                    Set Rapnet Username/Password
                  </div>
                </NavDropdown.Item>
              </Fragment>
            ) : (
              ""
            )}
          </NavDropdown>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header_2;
