import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CardComponent from "../CardComponent/CardComponent";
import "./CardTable.css";
import { ColorRing } from "react-loader-spinner";

const CardTable = ({ data, pageNumber, handlePageChange, pending }) => {
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-center">
      {/* {console.log({ pageNumber })} */}
      {pending ? (
        <div>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {data.map((item) => {
              return <CardComponent data={item} />;
            })}
          </div>

          <div className="gallery_pagination_controls">
            <button onClick={() => handlePageChange(pageNumber - 1)}>
              {"<"}
            </button>
            {pageNumber}
            <button onClick={() => handlePageChange(pageNumber + 1)}>
              {">"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardTable;
