import React from "react";
import { cn } from "../../utils/utils";

const CellContainer = ({ children, className, ref }) => {
  return (
    <div
      ref={ref}
      className={cn("px-1 min-w-[30px] w-full flex items-center", className)}
    >
      {children}
    </div>
  );
};

export default CellContainer;

// diacenter inc
