import React from "react";
import {
  colorGeneratorPositive2,
  generateColorMap,
} from "../../../utils/utils";
import CellContainer from "../cellContainer";
import { flexRender } from "@tanstack/react-table";

const LdRecodiff = ({ data, cell }) => {
  const rap = cell.row.original["Rap %"];
  const LDR = cell.row.original["LD Reco"];

  if (isNaN(parseFloat(LDR)) || isNaN(parseFloat(rap)))
    return (
      <>
        <td key={cell.id} className="h-10">
          <CellContainer
            className={"text-[0.685rem] font-medium mt-1 justify-center "}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </CellContainer>
        </td>
      </>
    );
  const ldRecoData = data
    ? Array.from(
        new Set(
          data
            .map((item) =>
              parseFloat((item["LD Reco"] - item["Rap %"]).toFixed(1))
            )
            .filter(
              (aging) =>
                aging !== "NA" &&
                aging !== null &&
                aging !== undefined &&
                !isNaN(aging)
            )
        )
      ).sort((a, b) => a - b)
    : [];

  const colorMapLDReco = generateColorMap(ldRecoData);
  return (
    <>
{/*      
      <td
        key={cell.id}
        className="h-8"
        style={{
          backgroundColor:
            "rgba(" +
            colorMapLDReco[
              parseFloat(
                (
                  cell.row.original["LD Reco"] - cell.row.original["Rap %"]
                ).toFixed(1)
              )
            ]?.join(",") +
            ", 0.5)",
        }}
      >
*/}      
      <td
        key={cell.id}
        className="h-8"
      >
        <CellContainer
          className={"text-[0.685rem] font-medium mt-1 justify-center"}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </CellContainer>
      </td>
    </>
  );
};

export default LdRecodiff;
