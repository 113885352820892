import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  selectedItem: {
    index: 0,
  },
  data: {},
  open: false,
};

export const pricingModalSlice = createSlice({
  name: "pricingModal",
  initialState,
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setModalData: (state, action) => {
      let x = [...action.payload.data];
      state.data = { ...state.data, [action.payload.id]: x };
    },

    setDefaultModalData: (state, action) => {
      let x = action.payload;
      state.data = x;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setDefault: (state) => {
      let x = current(state).data["Market Diamonds"];
      let y = current(state).data["Inventory Diamonds"];
      let z = current(state).data["Competition Diamonds"];

      if (x.length > 0) {
        state.selectedItem = {
          id: "Market Diamonds",
          data: x[0],
          index: 0,
        };
      } else if (z.length > 0) {
        state.selectedItem = {
          id: "Competition Diamonds",
          data: z[0],
          index: 0,
        };
      } else {
        state.selectedItem = {
          id: "Inventory Diamonds",
          data: y[0],
          index: 0,
        };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedItem,
  setModalData,
  setOpen,
  setDefault,
  setDefaultModalData,
} = pricingModalSlice.actions;

export const pricingModal = (state) => state.pricingModal;

export default pricingModalSlice.reducer;
