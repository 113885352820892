import React from 'react';
import {filterReverseMapping} from "../../../utils/utils";

// Filter example
// const filters = {
//   shape: ["princess"],
//   carats: { from: 2, to: 2.29 },
//   color: ["d", "e", "f", "g"],
//   clarity: ["vs2", "si1", "si2", "si3", "i1"],
//   polish: ["id", "ex", "vg"],
//   symmetry: ["id", "ex", "vg"],
//   fluor: ["n", "vsl", "sl", "f"],
//   cert: ["gia", "gia_dor"],
//   location: ["usa"]
// };

const BroadFilters = ({ filters }) => {
  const renderFilters = () => {
    return Object.entries(filters).map(([key, value]) => {
      if (Array.isArray(value)) {
        // If the filter value is an array, join them with commas
        return (
          <tr key={key}>
            <td>
                <strong>{filterReverseMapping[key]}:</strong>
            </td>
            <td>
                {value.join(", ").replace(/_/g, ' ').toUpperCase()}
            </td>         
          </tr>
        );
      } else if (typeof value === 'object' && value.from !== undefined && value.to !== undefined) {
        // If the filter value is an object with "from" and "to" properties
        return (
        <tr key={key}>
            <td>
                <strong>{filterReverseMapping[key]}:</strong>
            </td>
            <td>
                {value.from} - {value.to}
            </td>         
          </tr>
        );
      }
      return null;
    });
  };

  return (
    <div className="max-w-[27rem] overflow-auto max-h-[25rem]" style={{scrollbarColor:"auto"}}>
      <table>
        <thead>
          <tr>
            <td colSpan={2}><strong>Broad Filters</strong></td>
          </tr>
        </thead>
        <tbody>
            {renderFilters()}
        </tbody>
      </table>
    </div>
  );
};

export default BroadFilters;