import React, { useState } from "react";
import { cn } from "../utils/utils";
import { Tabs } from "react-bootstrap";
import CustomTabs from "./ui/tabs";

const Carousel = ({ items, activeIndex, filterContent, getCertURL }) => {
  const [value, setValue] = React.useState(0);
  let url = getCertURL(items[activeIndex])
  //TODO: handle api integration to get the plot

  return (
    <div className="flex flex-col w-full h-[100%] max-w-[700px] lg:max-w-[600px] rounded-lg relative border-none border-[#EAEAEA] justify-start">
      {items.map((item, index) => (
        <div
          className={cn(
            "w-full h-full flex justify-center",
            index == activeIndex ? "" : "hidden"
          )}
        >
          {value === 0 && filterContent(item["Video Url"])}
          {value === 1 && <iframe 
                    src={url} 
                    loading="lazy" 
                    className="w-full h-full rounded-lg"
                  />}
                
          
        </div>
      ))}
      <div className="flex justify-end overflow-none h-max py-1  w-full rounded-md">           
        <CustomTabs value={value} setValue={setValue} lab={items[activeIndex]['Lab']} />
       </div>
    </div>
  );
};

export default Carousel;
