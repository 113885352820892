import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

// Create and Install Session Replay Plugin
const sessionReplayTracking = sessionReplayPlugin({
    forceSessionTracking: false,
    sampleRate: 1, // 100% sample rate, should reduce for production traffic.
    defaultTracking: true,
});
amplitude.add(sessionReplayTracking);
 
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

export default amplitude;
