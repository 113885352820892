import React, { useState, useEffect } from "react";
import "./CardComponent.css";
import ReactPlayer from "react-player";
const CardComponent = ({ data }) => {
  // console.log({ data });

  const [keySymbols, setKeySymbols] = useState();
  const [isHovering, setIsHovering] = useState(false);

  //get key to symbol from the network data.
  const getKeyToSymbol = (keyToSymbol) => {
    if (keyToSymbol.ori) {
      let key_array = keyToSymbol.ori.split(",").map((item) => item.trim());
      let key_images = "";
      key_array.forEach((key) => {
        let filterKey = key.replaceAll("_", " ");
        key_images += filterKey + ", ";
      });

      setKeySymbols(key_images);
    } else {
      setKeySymbols("");
    }
  };

  // determine which type of media is to be shown.
  const setMedia = (asset) => {
    //if no media urls are there, return a placeholder for the same.
    if (!asset || asset.length <= 0) return <div>No Media</div>;

    let images = [];
    let htmls = [];
    let videos = [];

    // we iterate through all the assets and seperate them in the
    // different arrays

    asset.map((item) => {
      switch (item.content_type) {
        case "image/jpeg" || "image/png":
          images.push(item);
          break;
        case "html":
          if (item.url.toLowerCase().includes(".pdf")) break;
          htmls.push(item);
          break;
        case "video/mp4":
          videos.push(item);
          break;
      }
    });

    // as per precedence, if videos are present return a video.
    if (videos.length > 0) {
      return (
        <div className="player-div">
          <ReactPlayer controls={true} url={videos[0].url} />
        </div>
      );
    } else if (htmls.length > 0) {
      // if v360 is present return a v360
      return (
        <iframe
          loading="lazy"
          // src={htmls[0].url + "&btn=0&zoomslide=0&sv=0&z=0"}
          src={
            "https://storage.googleapis.com/v360_store/Vision360.html?d=494177459"
          }
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        ></iframe>
      );
    } else if (images.length > 0) {
      // if image is present return a image.
      if (data.id == "8884676") {
        // console.log({ images });
      }

      return (
        // we check if the user is hovering?
        // if yes, then we show a v360 in iframe,
        // or else, we show a image.
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          style={{ width: "100%", height: "100%" }}
        >
          {isHovering ? (
            <iframe // show a v360
              loading="lazy"
              // src={htmls[0].url + "&btn=0&zoomslide=0&sv=0&z=0"}
              src={"http://127.0.0.1:5500/v360/Vision360.html?d=N0245201"}
              style={{
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
            ></iframe>
          ) : (
            <img // show a image
              src={images[0].url}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </div>
      );
    } else {
      return <div>No Media</div>; // if there is a blank array and no media.
    }
  };

  useEffect(() => {
    // check if the kts is empty or null
    if (
      data.item_attributes["diamond#key_to_symbol"] !== null ||
      data.item_attributes["diamond#key_to_symbol"] !== ""
    ) {
      getKeyToSymbol(data.item_attributes["diamond#key_to_symbol"]);
    }
  }, []);

  return (
    <div className="card-container">
      <div className="card-media">{setMedia(data.asset_urls)}</div>
      <div className="card-details">
        <div className="card-details-row">
          <label>Gia</label>
          <div className="value">
            {data.item_attributes["diamond#cert_number"].trans}
          </div>
        </div>
        <div className="card-details-col">
          <div>
            {`
                ${data.item_attributes["diamond#shape"].trans}, 
                ${data.item_attributes["gem#carats"].trans},
                ${data.item_attributes["diamond#color"].trans}, 
                ${data.item_attributes["diamond#clarity"].trans}, 
                ${data.item_attributes["diamond#make"].trans}, 
                ${data.item_attributes["diamond#fluor"].trans}, 
                ${data.item_attributes["diamond#cert"].trans}


                `}
          </div>
        </div>
        {/* <div className="card-details-row">
            <div className="card-key">{keySymbols}</div>
          </div> */}
        <div className="card-details-row">
          <div className="value">{` ${data.item_attributes["diamond#rap_price"].trans},  
            ${data.item_attributes["gem#price_per_carat"].trans} 
            ${data.item_attributes["diamond#rap_percent"].trans}`}</div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
