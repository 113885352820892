import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { useState } from "react";
import DownloadReport from "../DownloadReport/DownloadReport";

const Filter = ({
  options,
  noRecords,
  applyFilter,
  handleDownloadSuccess,
  handleDownloadError,
  handleDownloadRequestError,
  downloadParams,
}) => {
  const SUMMARY_DATA_PROPERTIES = {
    lab: "diamond#cert",
    shape: "diamond#shape",
    color: "diamond#color",
    clarity: "diamond#clarity",
    carats: "gem#carats",
    milky: "diamond#milky",
    shade: "diamond#shade",
    make: "diamond#make",
    fluor: "diamond#fluor",
    bic: "diamond#black_in_center",
    bis: "diamond#black_in_side",
    wis: "diamond#white_in_side",
    wic: "diamond#white_in_center",
  };

  const [filters, setFilters] = useState({});

  const onSelect = (selectedList, removedItem) => {
    var filterValues = [];
    let filter_map = {};
    var attrName = null;
    if (selectedList.length == 0) {
      attrName = SUMMARY_DATA_PROPERTIES[removedItem.className];
    }
    Object.entries(selectedList).map(([key, option]) => {
      attrName = SUMMARY_DATA_PROPERTIES[option.className];
      if (attrName) {
        if (attrName === "gem#carats") {
          let carat_range = option.key.split("-");
          filterValues.push({ to: carat_range[0], from: carat_range[1] });
        } else {
          filterValues.push(option.key);
        }
      }
    });

    filter_map[attrName] = filterValues.flat();

    setFilters((prevState) => ({
      ...prevState,
      ...filter_map,
    }));
  };

  return (
    <>
      {noRecords ? (
        <div
          className="row justify-content-start border-secondary"
          style={{ margin: "10px" }}
        >
          {Object.entries(options).map(([key, options]) => {
            return (
              <div className="col-sm-3" style={{ padding: "10px" }} key={key}>
                <Multiselect
                  optionLabel={"Select " + key}
                  onSelect={onSelect}
                  onRemove={onSelect}
                  className={key}
                  options={options}
                  displayValue="name"
                  placeholder={"Select " + key}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className="row justify-content-start border-secondary"
          style={{ margin: "10px" }}
        >
          {Object.entries(options).map(([key, options]) => {
            return (
              <div className="col-sm-3" style={{ padding: "10px" }} key={key}>
                <Multiselect
                  optionLabel={"Select " + key}
                  onSelect={onSelect}
                  onRemove={onSelect}
                  className={key}
                  options={options}
                  displayValue="name"
                  placeholder={"Select " + key}
                />
              </div>
            );
          })}
        </div>
      )}

      <div className="col-lg-12" style={{}}>
        <div
          className="row"
          style={{
            justifyContent: "end",
          }}
        >
          <div style={{ marginBottom: "8px", width: "fit-content" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => applyFilter(filters)}
            >
              <i className=" bi bi-sliders me-1"></i> Apply Filters
            </button>
          </div>

          <DownloadReport
            handleDownloadSuccess={handleDownloadSuccess}
            handleDownloadError={handleDownloadError}
            handleDownloadRequestError={handleDownloadRequestError}
            captureReportDownloadParams={downloadParams}
          />
        </div>
      </div>
    </>
  );
};

export default Filter;
