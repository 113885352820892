import React from 'react';
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { ClickAwayListener, Popper } from "@mui/material";
import CellContainer from "../cellContainer";
import { flexRender } from "@tanstack/react-table";
import "./TableCell.css";
import { TimeSince } from "../../../utils/utils";
import { BarChart, Plus, Minus } from "lucide-react";

const ArrowIcon = ({ direction, color }) => (
  direction !== 0 && (
    direction > 0 ? (
      <FaCaretDown size={17} color={color} enableBackground={"white"} />
    ) : (
      <FaCaretUp size={17} color={color} enableBackground={"white"} />
    )
  )
);

const AddedSoldIcon = ({ direction, color }) => (
  direction !== 0 && (
    direction > 0 ? (
      <Minus strokeWidth={3} size={17} color={color} enableBackground={"white"}/>
    ) : (
      <Plus strokeWidth={3} size={17} color={color} enableBackground={"white"}/>
    )
  )
);

// Params:
// diffValue - float - 10.5
// threshold - float - 10
const RapChangeCell = ({ cell, diffValue, threshold, original, text, trackingDate, addedCount, soldCount }) => {
  // console.log("diffValue", diffValue)
  // console.log("threshold", threshold)
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleMouseOver = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.stopPropagation();
  };

  const handleMouseOut = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let previousValue = original - diffValue;

  const color = diffValue > threshold ? 'red' : diffValue < (threshold*-1) ? 'green' : 'gray';

  if (diffValue === null) {
    return (
      <>
        <Popper open={open} anchorEl={anchorEl} placement="bottom">
          <ClickAwayListener onClickAway={handleMouseOut}>
            <div className="gap-2 border border-[#cbcbcb] rounded-xl px-3 py-2 bg-white ">
              <table>
                <tr>
                  <td>Previous {text}: </td>  
                  <td><strong>{previousValue}</strong></td>  
                </tr>  
                <tr>
                  <td>Current {text}: </td>  
                  <td><strong>{original}</strong></td>  
                </tr>  
                <tr>
                  <td colSpan={2}><TimeSince dateString={trackingDate} /></td>
                </tr>
              </table> 
            </div>
          </ClickAwayListener>
        </Popper>
        <td key={cell.id} className="h-8 leaflet" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
          <CellContainer
            className={
              "text-[0.685rem] font-medium justify-center pt-1"
            }
          >
            <span>-</span>
          </CellContainer>
        </td>
      </>
    );
  }

  if (threshold > 0){
    return (
      <>
        <Popper open={open} anchorEl={anchorEl} placement="bottom">
          <ClickAwayListener onClickAway={handleMouseOut}>
            <div className="inventory-info bg-white">
              <h5>Rank Movement
              </h5>
              <p>Rank <strong>{previousValue}</strong> (<TimeSince dateString={trackingDate} /> ago)</p>
              <p>Rank <strong>{original}</strong> (Now)</p>
              
            </div>
          </ClickAwayListener>
        </Popper>
        <td key={cell.id} className="h-8 leaflet" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
          <CellContainer
            className={
              "text-[0.685rem] font-medium justify-center pt-1"
            }
          >
            <ArrowIcon direction={diffValue} color={color} />
            <span style={{ color }}>{Math.abs(diffValue)}</span>
          </CellContainer>
        </td>
      </>
    );
  }else{
    return (
      <>
        <Popper open={open} anchorEl={anchorEl} placement="bottom">
          <ClickAwayListener onClickAway={handleMouseOut}>
            <div className="inventory-info bg-white">
              <h5>Market Movement</h5>
              <p><strong>{previousValue}</strong> stones (<TimeSince dateString={trackingDate} /> ago)</p>
              <p><strong>{original}</strong> stones (Now)</p>
              <div className="change-info">
                <p className="added">
                  <AddedSoldIcon direction="-1" color="green" />{addedCount} added
                </p>
                <p className="sold">
                  <AddedSoldIcon direction="1" color="red" />{soldCount} sold
                </p>
              </div>
            </div>
          </ClickAwayListener>
        </Popper>
        <td key={cell.id} className="h-8 leaflet" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
          <CellContainer
            className={
              "text-[0.685rem] font-medium justify-center pt-1"
            }
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </CellContainer>
        </td>
      </>
    );
  }
};

export default RapChangeCell;