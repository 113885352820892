import React from "react";
import { ColorRing } from "react-loader-spinner";
import "./StaticReport.css";

const StaticReport = ({ DataisLoaded, items }) => {
  const getBgColor = (min, max, value) => {
    let rgb_string = "";
    if (value === max) {
      rgb_string = "rgba(44,186,0,0.7)";
    } else if (value === min) {
      rgb_string = "rgba(255,0,0,0.7)";
    } else {
      const range = max - min;
      const percentage = parseInt(((value - min) / range) * 100);
      let red, green;
      if (percentage < 50) {
        // get color between red to yellow
        red = 255;
        green = (244 * percentage) / 50;
      } else {
        // get color between yellow to green
        red = (211 * (100 - percentage)) / 50 + 44;
        green = (60 * (100 - percentage)) / 50 + 185;
      }
      rgb_string = `rgba(${red},${green},10,0.7)`;
    }
    return rgb_string;
  };

  return (
    <>
      {!DataisLoaded ? (
        <div className="row">
          <ColorRing
            visible={true}
            height="60"
            width="60"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
          />
          <div className="align-items-center justify-content-center">
            <h3 className=" text-center"> Please wait... </h3>
          </div>
        </div>
      ) : (
        <div className="row">
          {Object.entries(items).map(([key, value]) => {
            // Getting min max amounts of each tables
            const max = Math.max(
              ...value.map((e) =>
                parseInt(e.total_amount.slice(1, -3).split(",").join(""))
              )
            );
            const min = Math.min(
              ...value.map((e) =>
                parseInt(e.total_amount.slice(1, -3).split(",").join(""))
              )
            );
            return (
              <div
                className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 "
                key={key}
              >
                <h5
                  className="card-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {key.toUpperCase()}
                </h5>
                <div
                  className="card"
                  style={{
                    minHeight: "480px",
                    maxHeight: "480px",
                    overflow: "auto",
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      paddingTop: "0px",
                    }}
                  >
                    <table className="table table-sm table-hover">
                      <thead
                        className="sticky-top bg-white"
                        style={{
                          top: -1,
                          zIndex: 100,
                          borderTop: "hidden",
                        }}
                      >
                        <tr>
                          <th className="pd-15">Name</th>
                          <th className="pd-15">Count</th>
                          <th className="pd-15">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {value.map((element, idx) => {
                          return (
                            <tr key={idx}>
                              <td scope="row" style={{ textAlign: "left" }}>
                                {element.name}
                              </td>
                              <td
                                scope="row"
                                style={{
                                  textAlign: "right",
                                  paddingRight: "15px",
                                }}
                              >
                                {element.count}
                              </td>
                              <td
                                scope="row"
                                style={{
                                  backgroundColor: `${getBgColor(
                                    min,
                                    max,
                                    parseInt(
                                      element.total_amount
                                        .slice(1, -3)
                                        .split(",")
                                        .join("")
                                    )
                                  )}`,
                                  opacity: 1,
                                  textAlign: "right",
                                  paddingRight: "15px",
                                }}
                              >
                                {element.total_amount.slice(0, -3)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default StaticReport;
