import { Popper } from "@mui/material";
import { BarChart, Plus, Minus } from "lucide-react";
import React from "react";
import amplitude from "../../utils/ampli";
import { useEffect } from "react";
import ColorChangingText from "./colorChangingText";
import RapChangeGrading from "../newTable/cellComponents/rapChangeGrading";
import AnimatedText from "./colorChangingText";
import {sortByMapping, TimeSince} from "../../utils/utils";

const GradingChip = ({ rapnetData,metaTags, isFiltered, recoData, confidence, configSort, mappedItem }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMouseOver = (event) => {
    event.stopPropagation();

    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMouseOut = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open) {
      // amplitude events.
      const buttonClickedEvent = {
        event_type: `v2_itemDetails_grading_active`,
      };
      amplitude.track(buttonClickedEvent);
    }
  }, [open]);

  const getBgColor = (value) => {
    if (!value) return "#FFF1EB";

    if (value.toLowerCase() == "high") {
      return "#EBFFF3";
    } else if (value.toLowerCase() == "medium") {
      return "#FFFDEB";
    } else if (value.toLowerCase() == "low") {
      return "#FFF1EB";
    }
  };

  const rapKeys = ["1st", "5th", "10th", "25th", "50th", "100th"];

  const rankKeys = ["Rap Rank", "Rap Matching count"];

  const renderCellContent = (key, value, filteredCount) => {
    switch (key) {
      case "count":
        return <AnimatedText key={key} text={metaTags.count} />;
      case "Rank Change":
        return <RapChangeGrading diffValue={value} threshold={5} />;
      default:
        return value;
    }
  };
  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        className="!z-[1000]"
      >
        <div className="flex flex-col gap-2 border border-[#cbcbcb] rounded-xl px-2 py-2 bg-white ">
          <div className="flex text-xs justify-between gap-2">

            
              {rankKeys.map((key) => {
                const previousValue =
                  key === "Rap Rank"
                    ? mappedItem[key] - mappedItem["Rap Rank Diff"]
                    : mappedItem[key] - mappedItem["Rap Count Diff"];
                const currentValue = mappedItem[key];

                if (previousValue == null || currentValue == null) {
                  return null;
                }
                const isCountMovement = (key === "Rap Matching count");
                if (isCountMovement){
                  return(
                    <div className="grading-inventory-info bg-white">
                      <h5>Market Movement</h5>
                      <p className="text-gray-500 pholder w-44 text-[0.67rem] tracking-normal font-medium leading-[0.76875rem] ">Stones entered or left the market based on the search criteria</p>
                      <br />
                      <p><strong>{previousValue}</strong> stones (<TimeSince dateString={mappedItem.trackingDate} /> ago)</p>
                      <p><strong>{currentValue}</strong> stones (Now)</p>
                      <div className="change-info">
                        <p className="added  flex justify-between items-center">
                          <Plus className="p-1" direction="-1" color="green" />{mappedItem["Supply Count"]} added
                        </p>
                        <p className="sold flex justify-between items-center">
                          <Minus className="p-1" direction="1" color="red" />{mappedItem["Demand Count"]} sold
                        </p>
                      </div>
                    </div>
                  )
                } else {
                  return(
                    <div className="grading-inventory-info bg-white">
                      <h5>Rank Movement</h5>
                      <p className="pholder text-gray-500 w-44 text-[0.67rem] font-medium tracking-normal leading-[0.76875rem]">Your ranking is based on the search criteria's in the market</p>  
                      <br />
                      <p><strong>{previousValue}</strong> stones (<TimeSince dateString={mappedItem.trackingDate} /> ago)</p>
                      <p><strong>{currentValue}</strong> stones (Now)</p>
                    </div>
                  )
                }
              })}
          </div>
          <div className="flex px-3 py-2 justify-center border border-[#cbcbcb] rounded-xl ">
          <BarChart
                          height={"14px"}
                          className="mr-1 cursor-pointer self-center"
                        />
                            {rapKeys.map((key) => {
                              if (rapnetData[key] !== null && rapnetData[key] ) {
                                return (
                                  <div className="flex flex-col justify-center mr-2" key={key}>
                                    <div className="text-[0.66rem] flex justify-center mx-auto items-start font-bold text-gray-700">
                                      {key}
                                    </div>
                                    <div className="text-xs  font-normal text-gray-400">
                                      {rapnetData[key]}
                                    </div>
                                  </div>
                                );
                              } else {
                                return; 
                              }
                            })}
        </div>
        
      </div>
       
      </Popper>
      <div
        className=" bottom-0 px-3 py-1  bg-[#EEEEEE] rounded-xl overflow-visible flex gap-2 justify-center z-[100000] cursor-pointer "
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        style={{
          backgroundColor: getBgColor(
            confidence ? confidence.toLowerCase() : null
          ),
        }}
      >
        {rapnetData
          ? Object.entries(rapnetData).map(([key, value]) => {
            
              if ( key.toLowerCase() == "1st" ) {
                return;
              }

              if ( key.toLowerCase() == "filteredcount" ) {
                return;
              }

              if (value == null) {
                return;
              }

              if (!rapKeys.includes(key.toLowerCase())) {
                if (key == "3rd") {
                  return;
                }
                let keyToDisplay = key === "rank" ? `${key.toUpperCase()} by ${sortByMapping[configSort]}` : key.toUpperCase();
                {/* return isFiltered && (key == "rank") ? ( */}

                return (key == "rank") ? (
                  
                  <div
                    className="flex flex-col justify-center items-center"
                    key={key}
                  >
                    <div className="text-[0.66rem] font-bold text-gray-700">
                      {keyToDisplay} 
                    </div>
                    <div className="text-sm font-normal text-gray-400 flex ">
                      {/* <AnimatedText text={value} /> /{rapnetData.filteredCount} */}
                      <AnimatedText text={metaTags.rank} />
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex flex-col justify-center items-center"
                    key={key}
                  >
                    <div className="text-[0.66rem] font-bold text-gray-700">
                      {keyToDisplay} 
                    </div>
                    <div className="text-xs font-normal text-gray-400">
                     {renderCellContent(key, value, rapnetData.filteredCount)}
                      
                    </div>
                  </div>
                );
              }
            })
          : ""}
      </div>
    </>
  );
};

export default GradingChip;