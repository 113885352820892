import React from "react";
import { generateColorMap } from "../../../utils/utils";
import CellContainer from "../cellContainer";
import { flexRender } from "@tanstack/react-table";

const LdReco = ({ data, cell }) => {
  const getbg = (val) => {
    // console.log(val);
    switch (val) {
      case "high":
        return "#C7FFAC";

      case "medium":
        return "#FFE3AC";

      case "low":
        return "#fa946d";
    }
  };

  return (
    <>
      <td
        key={cell.id}
        className="h-10 max-w-[50px]"

        // style={{
        //   backgroundColor: getbg(cell.row.original["Suggested Confidence"]),
        // }}
      >
        <CellContainer
          className={"text-[0.685rem] font-medium mt-1 justify-center "}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </CellContainer>
        <div className="absolute top-0 w-[60px] flex justify-center">
          <div
            className="  text-[0.55rem] mx-auto rounded-bl-md rounded-br-md font-semibold px-2 "
            style={{
              backgroundColor: getbg(cell.row.original["Suggested Confidence"]),
            }}
          >
            {/*{cell.row.original["Suggested Confidence"]}*/}
            beta
          </div>
        </div>
      </td>
    </>
  );
};

export default LdReco;
