import React, { useState } from "react";
import { motion } from "framer-motion";

import SidebarContent from "./sidebarContent";
import { cn } from "../utils/utils.js";
// import {
//   ArrowLeftFromLine,
//   ArrowRightFromLine,
//   ChevronLeft,
//   ChevronRight,
// } from "lucide-react";

const Sidebar = ({ isVisible }) => {
  return (
    <aside className="h-full w-full overflow-hidden">
      {/* Sidebar */}
      <motion.div
        className={cn(
          "w-[250px] h-full bg-white fixed top-[50px] left-0 z-[1000]  overflow-visible p-1 px-4  border-r-2 border-solid border-t-0 border-l-0 border-b-0 border-[#EAEAEA]",
          !isVisible
            ? "block w-[50px] px-1 border-r-2 border-solid border-t-0 border-l-0 border-b-0 border-[#EAEAEA]  "
            : "block"
        )}
        initial={{ width: isVisible ? 250 : 50 }} // Initial width based on isVisible
        animate={{ width: isVisible ? 250 : 50 }} // Animated width based on isVisible
        transition={{ duration: 0.3, ease: "easeOut" }}
      >
        {/* Sidebar content */}
        <SidebarContent isVisible={isVisible} />
      </motion.div>
    </aside>
  );
};

export default Sidebar;
