import React from "react";
import {
  colorGeneratorPositive,
  colorGeneratorPositive2,
  generateColorMap,
} from "../../../utils/utils";
import CellContainer from "../cellContainer";
import { flexRender } from "@tanstack/react-table";

const Aging = ({ data, cell }) => {
  const ldRecoData = data
    ? Array.from(
        new Set(
          data
            .map((item) => item["Aging"])
            .filter(
              (aging) => aging !== "NA" && aging !== null && aging !== undefined
            )
        )
      ).sort((a, b) => a - b)
    : [];

  const colorMapLDReco = colorGeneratorPositive2(ldRecoData);

  return (
    <>
      <td
        key={cell.id}
        className="h-10"
        style={{
          backgroundColor:
            "rgba(" +
            colorMapLDReco[cell.row.original["aging"]]?.join(",") +
            ", 0.5)",
          // discuss with chetan on to grade the color map.
        }}
      >
        <CellContainer
          className={"text-[0.685rem] font-medium mt-1  justify-center"}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </CellContainer>
      </td>
    </>
  );
};

export default Aging;
