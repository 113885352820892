import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaAngleDown } from "react-icons/fa";
import NewTable from "../newTable/newTable";
import NewTableInventory from "../newTable/newTableInventory";

const PriceItemDetailAccordion = ({
  data,
  isExpanded,
  setIsExpanded,
  tableClassName,
  children,
  isScaled = false,
  filtered,
  setFiltered,
  id,
  isHeader = false,
  isModal = false,
  labelArray,
  sorting,
  setSorting,
  scrollRef,
  marketDiamondsLoading,
  setMarketDiamondsLoading,
  marketFilters
}) => {
  const [rowHeight, setRowHeight] = useState(52);

  const numberOfRows = 5;
  const accordionHeight = rowHeight ? rowHeight * numberOfRows + 64 : "auto";

  // const toggleExpansion = () => {
  //   setIsExpanded((prevExpanded) => !prevExpanded);
  // }

  return (
    <Accordion
      elevation={0}
      disableGutters
      className="m-0 border-l-0 border-r-0 border-b-0 border-t-[1px] border-[#ECECEC] border-solid "
      // defaultExpanded={isExpanded}
      expanded={isExpanded}
      key={id}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary
        expandIcon={<FaAngleDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="h-12 min-h-[3rem] border-b-[1px] border-[#ECECEC] border-solid m-0 flex-row-reverse"
        style={{
          position: "sticky",
        }}
      >
        {children}
      </AccordionSummary>
      <AccordionDetails className="px-0 py-0">
        <div
          style={{
            height: isScaled && data.length > 5 ? accordionHeight : "",
            overflowY: isScaled ? "auto" : "hidden",
            //add height to the market diamonds table as it helps in making the render lag go away
          }}
          ref={scrollRef}
        >
          <NewTableInventory
            data={data}
            rowHeight={(height) => setRowHeight(height)}
            className={tableClassName}
            hideHeader={true}
            comparison={true}
            filtered={filtered}
            setFiltered={setFiltered}
            id={id}
            isModal={isModal}
            isHeader={isHeader}
            labelArray={labelArray}
            sorting={sorting}
            setSorting={setSorting}
            containerRef={scrollRef}
            marketDiamondsLoading={marketDiamondsLoading}
            setMarketDiamondsLoading={setMarketDiamondsLoading}
            marketFilters={marketFilters}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default PriceItemDetailAccordion;
