import React from "react";
import "./TableFilter.css";
import { capitalizeWords, cn } from "../../utils/utils";
import Tick from "../../assets/svgs/tick.svg";
import Cross from "../../assets/svgs/cross.svg";
import { Chip } from "@mui/material";

const TopFilterLabels = ({ value, isFiltered, setFiltered, count = null }) => {
  return (
    <>
      <Chip
        onClick={() => setFiltered(value)}
        className={cn(
          "m-1 p-0 cursor-pointer",
          isFiltered ? "bg-[#F2F2F2]" : "bg-[#A4D9FF]"
        )}
        size="small"
        label={
          <div className="flex justify-center items-center gap-1 ">
            <h5
              className={cn(
                "font-bold text-[0.6rem] m-0",
                isFiltered ? "text-[black]" : "text-[#0075FF]"
              )}
            >
              {count
                ? `${value.toUpperCase()} - ${count}`
                : value.toUpperCase()}
            </h5>
            {/* <div class="border-l border-solid border-[#dbd8d8] h-[12px] border-[0.5px]" /> */}
            {isFiltered ? (
              <img 
              alt="Cross"
              src={Cross}
              style={{
                  width: "14px",
                  height: "14px",
                }}
                className="bg-[#F2F2F2]"
              />
            ) : (
              <img
                 alt="Tick"
                src={Tick}
                style={{
                  width: "14px",
                  height: "14px",
                }}
              />
            )}
          </div>
        }
      />
    </>
  );
};

export default TopFilterLabels;