import React from "react";
import { strToInt } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Repricing from "../../ui/repricing";
import { setAnchorData } from "../../../redux/slices/pricingSlice";
import axios from "axios";
import { auth } from "../../../redux/slices/authSlice";
import { apiSlice } from "../../../redux/slices/apiSlice";
import { useState } from "react";
import { setSeverity, setAlertText, setToast } from "../../../redux/slices/commonComponentSlice";

// marketFilters (Object) - filter object values(changed with filter drawer) from priceItemDetails
// updateMetaTags (Function/Method) - first market mutation query response, as meta tags:{rank, count}, built updateMetaTags method to update with the new values as we reprice
// isDetails (boolean) - Use to clear the cache related to price table and selectedItem(top cell)
const RepricingCell = ({
  info,
  table,
  data,
  fieldName,
  isFormatted,
  isDetails = false,
  marketFilters,
  updateMetaTags
}) => {
  const dispatch = useDispatch();

  const value = isFormatted
    ? strToInt(info.getValue())?.toFixed(0)
    : strToInt(info.getValue());
  const token = useSelector(auth).token;
  const accountDetails = useSelector(auth).userDetails;

  const [loader, setLoader] = useState(false);

  
  const savePriceChange = async ({
    value,
    item_id,
    is_total_price,
    is_price_per_carat,
    is_rap_discount,
    comment = null,
  }) => {
    const url = `${process.env.REACT_APP_API_URL}/update_price_and_comments_of_item`;

    if (comment != null) {
      data.comment = comment;
    }

    data.filters = marketFilters || {};

    try {
      const res = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (isDetails) {
        dispatch(apiSlice.util.invalidateTags(["priceTable"]));
        dispatch(apiSlice.util.invalidateTags([`Rapnet matching ${item_id}`]));
      }
      dispatch(setToast(true));
      dispatch(setSeverity('success'));
      dispatch(setAlertText(res.data?.message));

      return { success: true, data: res.data, metaTags: res.data.data.meta };
    } catch (err) {
      console.log(err);
      dispatch(setToast(true));
      dispatch(setSeverity('error'));
      dispatch(setAlertText(err.response?.data?.message));
      // TODO - Need to reset the values in case of error 
      return false;
    }
  };

  const handleSave = async (ev, comment) => {
    ev.stopPropagation();

    dispatch(setAnchorData(value));

    try {
      const data = {
        value: info.getValue(),
        item_id: info.row.original["LD ID"],
        is_total_price: false,
        is_price_per_carat: false,
        is_rap_discount: true,
        filters: marketFilters,
      };

      if (comment != "") {
        data.comment = comment;
      }

      setLoader(true);
      const res = await savePriceChange(data);
      setLoader(false);
      if (!res.error) {
        table.options.meta?.updateEditState(res.data.data, info.row.index);
        if (updateMetaTags) {
          updateMetaTags(res.metaTags);
        }
      } else {
        throw new Error(res.error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}> {/* Prevent row click when interacting with Repricing component */}
      <Repricing
        getValue={value}
        column={info.column}
        row={info.row}
        table={table}
        save={handleSave}
        value={value}
        isFormatted={isFormatted}
        fieldName={fieldName}
        loader={loader}
      />
    </div>
  );
};

export default RepricingCell;
