import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import React, {useState, useEffect} from "react";
import { SlidersHorizontal } from 'lucide-react';
import DefaultFilterSet from "./defaultFilterSet";
import amplitude from "../../../utils/ampli";
import { FaLock, FaLockOpen, FaSpinner  } from "react-icons/fa";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../redux/slices/apiSlice";
// import { setOpenDrawerRedux } from "../../../redux/slices/pricingSlice";
import { setSeverity, setAlertText, setToast } from "../../../redux/slices/commonComponentSlice";

const TableLockFilter = ({ filters, isLocked, itemId, certNum, shape, size, color, clarity, cut, polish, sym, flr, stockNum }) => {
  // console.log("isLocked: ", isLocked);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterLocked, setIsFilterLocked] = useState(isLocked);
  // console.log("isFilterLocked: ", isFilterLocked);
  useEffect(() => {
    // console.log("useEffect triggered for isLocked", isLocked);
    setIsFilterLocked(isLocked);
  }, [isLocked]);

  const lockDefaultFilters = async () => {
    const url = `${process.env.REACT_APP_API_URL}/save_pricing_report_search_criteria`;
    const data = {
      item_ids: [itemId],
      cert_number: certNum,
      use_rank_filter: true,
    };
    let token = Cookies.get("access_token");
    setIsLoading(true);
    axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      // handle success
      // NotificationManager.success('Success', response.data.message)
      dispatch(apiSlice.util.invalidateTags(["priceTable"]));
      dispatch(apiSlice.util.invalidateTags([`Rapnet matching ${itemId}`]));
      setIsLoading(false);
      dispatch(setToast(true));
      dispatch(setAlertText(response.data.message));
      dispatch(setSeverity("success"));
      let isResponseLocked = response?.data?.data?.pr_item?.is_filter_locked;
      setIsFilterLocked(isResponseLocked);
    }).catch(function (error) {
      // handle error
      // NotificationManager.error('Error', error.response.data.message, 5000, () => {
      //   alert('callback');
      // });
      dispatch(setToast(true));
      dispatch(setAlertText(error.response.data.message));
      dispatch(setSeverity("error"));
      setIsLoading(false);
    }).finally(function () {
      // always executed
      setIsLoading(false);
    });
  };

  const renderIcon = () => {
    if (isLoading) {
      return <FaSpinner />;
    } else if(isFilterLocked){
      return <FaLock />;
    } else {
      return <FaLockOpen />;
    }
  };

  return (
    <>
      <PopupState variant="popover" popupId="comment-popover">
        {(popupState) => (
          <div>
            <div {...bindHover(popupState)}>
              {renderIcon()}
            </div>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {/* Example
              filters = [
                {key: "carats", value: {from: 1, to: 1.19}, is_default: true, can_expand: false, is_discrete: false},
                {key: "color", value: {discrete: ["d"]}, is_default: true, can_expand: false, is_discrete: true},
                {key: "clarity", value: {discrete: ["si1"]}, is_default: true, can_expand: false, is_discrete: true}
              ] */}
            <div className={`text-[0.685rem] p-3 ${isFilterLocked ? "bg-[#FFF9E4]" : "" }`}>
                <p className="text-[0.77rem] font-bold text-center">
                  {" "}
                  {/* {isIcon ? ("Filters have been locked for this stone as following: ") : ("Not enough Data, Expanded the Filter to the following: ")} */}
                  {/* {"Showing some random text which can be replaced: "} */}
                  {stockNum} - {shape} {size} {color} {clarity} {cut} {polish} {sym} {flr}
                </p>
                <hr/>
                <DefaultFilterSet filters={filters} />
                <div className="flex justify-around">
                    <button
                        className={`flex-1 h-8 border-none cursor-pointer text-sm my-1 min-w-[200px] ${isFilterLocked ? "bg-grey" : "bg-[#A4D9FF]"}`}
                        disabled={isFilterLocked}
                        onClick={(ev) => {
                            // amplitude events.
                            const buttonClickedEvent = {
                                event_type: `v3_inventoryTable_filter_lock`,
                                event_properties: {
                                    value: filters,
                                },
                            };
                            amplitude.track(buttonClickedEvent);
                            lockDefaultFilters();
                            ev.stopPropagation();
                        }}
                    >
                      {renderIcon()}
                      {isLoading ? "Locking" : (isFilterLocked ? " Locked" : " Lock")}
                    </button>
                    <button
                        className="flex-1 h-8 bg-white border-[#EAEAEA] border-solid outline-none cursor-pointer text-sm my-1 min-w-[200px]"
                        onClick={(ev) => {
                            // amplitude events.
                            const buttonClickedEvent = {
                                event_type: `v3_inventoryTable_filter_changefilters`,
                            };
                            amplitude.track(buttonClickedEvent);
                            // dispatch(setOpenDrawerRedux(true))
                        }}
                    > <SlidersHorizontal height={"10px"} /> Change Filters
                    </button>
                </div>
              </div>
            </HoverPopover>
          </div>
        )}
      </PopupState>
    </>
  );
};

export default TableLockFilter;
