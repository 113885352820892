import { Chip, ClickAwayListener, Popper } from "@mui/material";
import amplitude from "../../utils/ampli";
import React from "react";
// import { categoryMinimizer } from "../../utils/utils";

const CategoryArrayGenerated = ({ data }) => {
  // Replace with your dynamic array generation

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleLastItemRendered = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // Get a reference to the last item in the array using a ref
  // const lastItemRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const buttonClickedEvent = {
        event_type: `v2_itemDetails_categories_hover`,
      };
      amplitude.track(buttonClickedEvent);
    }
  }, [open]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        style={{
          zIndex: 1000,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div className="  border flex flex-col  flex-wrap border-gray-300 gap-1 justify-start  items-start px-2 py-3 rounded-lg bg-white mt-2">
            {Object.entries(data).map(([key, value], idx) => {
              if (idx >= 4) {
                return (
                  <Chip
                    key={key}
                    label={`${key} : ${value}`}
                    className="h-4 text-xs italic text-[0.54rem] text-[#8D8D8D]"
                  />
                );
              }
            })}
          </div>
        </ClickAwayListener>
      </Popper>
      <div className="flex gap-1 items-center">
        {/* {categoriesArray.map((category, index) => {
          if (index === categoriesArray.length - 1) {
            return (
              <div
                onMouseEnter={(ev) => handleLastItemRendered(ev)}
                onMouseLeave={handleMouseLeave}
                className="p-0 m-0 flex items-start"
              >
                <Chip
                  key={category + index}
                  label={category}
                  ref={
                    index === categoriesArray.length - 1 ? lastItemRef : null
                  }
                  className="h-4 text-xs italic text-[0.54rem] text-[#8D8D8D]"
                />
              </div>
            );
          }

          return (
            <Chip
              key={category + index}
              label={category}
              ref={index === categoriesArray.length - 1 ? lastItemRef : null}
              className="h-4 text-xs italic text-[0.54rem] text-[#8D8D8D]"
            />
          );
        })}
         */}

        {Object.entries(data).map(([key, value], idx) => {
          if (idx <= 3) {
            return (
              <Chip
                key={key}
                label={`${key} : ${value}`}
                className="h-4 text-xs italic text-[0.54rem] text-[#8D8D8D]"
              />
            );
          }

          if (idx === 4) {
            return (
              <div
                onMouseEnter={handleLastItemRendered}
                onMouseLeave={handleMouseLeave}
              >
                <Chip
                  key={key}
                  label={"more"}
                  className="h-4 text-xs italic text-[0.54rem] text-[#8D8D8D]"
                />
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default CategoryArrayGenerated;
