import React from "react";
import {filterReverseMapping} from "../../../utils/utils";

const DefaultFilterSet = ({ filters }) => {
  {/* Example
  filters = [
    {key: "carats", value: {from: 1, to: 1.19}, is_default: true, can_expand: false, is_discrete: false},
    {key: "color", value: {discrete: ["d"]}, is_default: true, can_expand: false, is_discrete: true},
    {key: "clarity", value: {discrete: ["si1"]}, is_default: true, can_expand: false, is_discrete: true}
  ] */}

  const renderFilter = (filter) => {
    if(filter.is_discrete){
      // return <tr key={filter.key} className={`${filter.is_default ? "bg-[#ff000080]" : "" }`}><td><strong>{filterReverseMapping[filter.key]}: </strong></td><td>{filter.value.discrete.join(", ").toUpperCase()}</td></tr>;
      if(filter.key == "shade"){
        const allShade = "NONE, MIXED, YELLOW, BROWN, FAINT_BROWN, GREEN, FAINT_GREEN, GREY, BLACK, PINK, BLUE, LIGHT_BROWN, VERY_LIGHT_BROWN, OTHER, NA";
        if(filter.value.discrete.join(", ").toUpperCase() == allShade){
          return "";
        }
      }else if(filter.key == "location"){
        const allLocations = "USA, INDIA, HONG_KONG, BELGIUM, UAE, ISRAEL, UNITED_KINGDOM, THAILAND, ITALY, BOTSWANA, MALAYSIA, CANADA, JAPAN, TAIWAN, SINGAPORE, FRANCE, SOUTH_AFRICA, AUSTRALIA, SWEDEN, GERMANY, SWITZERLAND, MONACO, INDONESIA, SOUTH_KOREA, TURKEY, GREECE, SPAIN, NEW_ZEALAND, ANTILLES, NETH, NETHERLANDS, VIETNAM, CAMBODIA, MEXICO, PHILIPPINES, SAUDI_ARABIA, AUSTRIA, CZECH_REPUBLIC, IRELAND, CHINA, NA";
        const allLocationsWOChina = "USA, INDIA, HONG_KONG, BELGIUM, UAE, ISRAEL, UNITED_KINGDOM, THAILAND, ITALY, BOTSWANA, MALAYSIA, CANADA, JAPAN, TAIWAN, SINGAPORE, FRANCE, SOUTH_AFRICA, AUSTRALIA, SWEDEN, GERMANY, SWITZERLAND, MONACO, INDONESIA, SOUTH_KOREA, TURKEY, GREECE, SPAIN, NEW_ZEALAND, ANTILLES, NETH, NETHERLANDS, VIETNAM, CAMBODIA, MEXICO, PHILIPPINES, SAUDI_ARABIA, AUSTRIA, CZECH_REPUBLIC, IRELAND, NA";
        const allLocationsWOChinaWONA = "USA, INDIA, HONG_KONG, BELGIUM, UAE, ISRAEL, UNITED_KINGDOM, THAILAND, ITALY, BOTSWANA, MALAYSIA, CANADA, JAPAN, TAIWAN, SINGAPORE, FRANCE, SOUTH_AFRICA, AUSTRALIA, SWEDEN, GERMANY, SWITZERLAND, MONACO, INDONESIA, SOUTH_KOREA, TURKEY, GREECE, SPAIN, NEW_ZEALAND, ANTILLES, NETH, NETHERLANDS, VIETNAM, CAMBODIA, MEXICO, PHILIPPINES, SAUDI_ARABIA, AUSTRIA, CZECH_REPUBLIC, IRELAND";
        const allLocationsWONA = "USA, INDIA, HONG_KONG, BELGIUM, UAE, ISRAEL, UNITED_KINGDOM, THAILAND, ITALY, BOTSWANA, MALAYSIA, CANADA, JAPAN, TAIWAN, SINGAPORE, FRANCE, SOUTH_AFRICA, AUSTRALIA, SWEDEN, GERMANY, SWITZERLAND, MONACO, INDONESIA, SOUTH_KOREA, TURKEY, GREECE, SPAIN, NEW_ZEALAND, ANTILLES, NETH, NETHERLANDS, VIETNAM, CAMBODIA, MEXICO, PHILIPPINES, SAUDI_ARABIA, AUSTRIA, CZECH_REPUBLIC, IRELAND, CHINA";
        if(filter.value.discrete.join(", ").toUpperCase() == allLocations || filter.value.discrete.join(", ").toUpperCase() == allLocationsWOChina || filter.value.discrete.join(", ").toUpperCase() == allLocationsWOChinaWONA || filter.value.discrete.join(", ").toUpperCase() == allLocationsWONA){
          return "";
        }
      }else if(filter.key == "milky"){
        const allMilky = "NONE, LIGHT, MEDIUM, HEAVY, NA";
        if(filter.value.discrete.join(", ").toUpperCase() == allMilky){
          return "";
        }
      }
      return <tr key={filter.key}><td><strong>{filterReverseMapping[filter.key]}: </strong></td><td>{filter.value.discrete.join(", ").replace(/_/g, ' ').toUpperCase()}</td></tr>;
    }else{
      if(filter.value.from == 0 && filter.value.to == 100){
        return "";
      }else{
        // return <tr key={filter.key} className={`${filter.is_default ? "bg-[#ff000080]" : "" }`}><td><strong>{filterReverseMapping[filter.key]}: </strong></td><td>{filter.value.from} - {filter.value.to}</td></tr>;
        return <tr key={filter.key}><td><strong>{filterReverseMapping[filter.key]}: </strong></td><td>{filter.value.from} - {filter.value.to}</td></tr>;
      }
    }
  };

  return (
    <div className="max-w-[27rem] overflow-auto max-h-[25rem]" style={{scrollbarColor:"auto"}}>
      <table>
        <thead>
          <tr>
            <td colSpan={2}><strong>Current Filters</strong></td>
          </tr>
        </thead>
        <tbody>
          {filters.map((filter) => (
            renderFilter(filter)
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DefaultFilterSet;
