import React from 'react'

const KeyToSymbol = ({
    keySymbols,
    modal
}) => {



    return (
        <div style={modal ? {

            alignSelf: "end",
            marginTop: "20px"
            // position: "absolute",
            // bottom: "80px",
            // backgroundColor: "white",
            // padding: "20px",
            // right: "-250px"
        } : {
            // bottom: "0px",
            // position: "absolute",
            // bottom: "40px",
            marginLeft: "20px"
        }}>
            <div
                style={{
                    fontWeight: "bold",
                    fontSize: "0.9rem"

                }}

            >Key To Symbol</div>
            {
                Object.keys(keySymbols).map((key) => {

                    return (
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <div
                                style={{
                                    margin: "5px",
                                    marginLeft: "0px",
                                    fontSize: "0.8rem"

                                }}
                            >{key}</div>

                            <img src={keySymbols[key]} style={{
                                height: "35px",
                                width: "35px",
                                objectFit: "contain"

                            }} />
                        </div>
                    )
                })


            }


        </div>
    )
}

export default KeyToSymbol