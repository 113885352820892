import React from "react";
import {
  colorGeneratorPositive,
  colorGeneratorPositive2,
} from "../../../utils/utils";
import CellContainer from "../cellContainer";
import { flexRender } from "@tanstack/react-table";
import { BsCaretUpFill } from "react-icons/bs";
import { ClickAwayListener, Popper } from "@mui/material";
import DefaultFilterSet from "./defaultFilterSet";
import BroadFilters from "./broadFilters";
import "./TableCell.css";

const RapRank = ({ data, cell, filters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleMouseOver = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.stopPropagation();
  };

  const handleMouseOut = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const rapRank = data
    ? data.map((item) => {
        if (
          typeof item["Rap Rank"] == "string" &&
          item["Rap Rank"].includes("+")
        ) {
          let x = item["Rap Rank"].replace("+", "").trim();
          return parseInt(x);
        } else {
          return item["Rap Rank"];
        }
      })
    : [];

  const colorMap = colorGeneratorPositive2(rapRank.sort((a, b) => a - b));

  const rankDiff = cell.row.original["Rank Diff"];

  const getBg = (value) => {
    if (typeof value == "string" && value.includes("+")) {
      let x = value.replace("+", "").trim();
      return "rgba(" + colorMap[parseInt(x)]?.join(",") + ", 0.5)";
    } else {
      return "rgba(" + colorMap[value]?.join(",") + ", 0.5)";
    }
  };

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="bottom"  style={{zIndex:999}}>
        <ClickAwayListener onClickAway={handleMouseOut}>
          <div className="gap-2 border border-[#cbcbcb] rounded-xl px-3 py-2 bg-white">
            <div style={{fontSize: "14px"}}> Rank <strong>{(cell.getValue() ? cell.getValue() : "-")}</strong> of <strong>{cell.row.original["Rap Matching count"]}</strong> </div>
            <hr/>
            <DefaultFilterSet filters={filters} />
          </div>
        </ClickAwayListener>
      </Popper>
      <td
        key={cell.id}
        className="h-10 leaflet"
        style={{
          backgroundColor: getBg(cell.row.original["Rap Rank"]),
          color: cell.row.original["Rap Rank Exceeded"] ? "red" : "black",
        }}
        onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}
      >
        <CellContainer
          className={"text-[0.685rem] font-medium mt-1 justify-center flex-col"}
        >
          <div>
            {cell.row.original["Rap Rank Exceeded"]
              ? cell.getValue() + "+"
              : flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
          {rankDiff ? (
            <div className="flex text-[0.55rem] items-center justify-between text-gray-500">
              <BsCaretUpFill
                height={"7px"}
                width={"7px"}
                className="hover:cursor-pointer "
              />
              ) +2
              <div className="w-3"></div>
            </div>
          ) : null}
        </CellContainer>
      </td>
    </>
  );
};

export default RapRank;
