import { ClickAwayListener, Popper } from "@mui/material";
import React from "react";
import CellContainer from "../cellContainer";
import { flexRender } from "@tanstack/react-table";
import "./TableCell.css";
import { BsCaretUpFill } from "react-icons/bs";
import DefaultFilterSet from "./defaultFilterSet";
import BroadFilters from "./broadFilters";

const RapCount = ({ data, cell, filters, totalCount }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMouseOver = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.stopPropagation();
  };

  const handleMouseOut = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const rankDiff = cell.row.original["Rank Diff"];

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="bottom" style={{zIndex:999}}>
        <ClickAwayListener onClickAway={handleMouseOut}>
          {totalCount == null ? 
            <div className="gap-2 border border-[#cbcbcb] rounded-xl px-3 py-2 bg-white">
              <BroadFilters filters={filters} />
            </div>
          :
            <div className="gap-2 border border-[#cbcbcb] rounded-xl px-3 py-2 bg-white">
              <div style={{fontSize: "14px"}}> Current filters show <strong>{(cell.getValue() ? cell.getValue() : "-")}</strong> diamonds in the market </div>
              <hr/>
              <DefaultFilterSet filters={filters} />
              <hr/>
              <div style={{fontSize: "14px"}}> Filters can be broadened up to <strong>{totalCount}</strong> diamonds </div>
            </div>
          }
        </ClickAwayListener>
      </Popper>
      <td key={cell.id} className="h-10 items-center leaflet" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
        <CellContainer
          className={
            "text-[0.685rem] font-medium mt-1 justify-end flex-col ml-2"
          }
        >
          <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>

          {rankDiff ? (
            <div className="flex text-[0.55rem] items-center justify-between text-gray-500">
              <BsCaretUpFill
                height={"7px"}
                width={"7px"}
                className="hover:cursor-pointer "
              />
              ) +2
              <div className="w-3"></div>
            </div>
          ) : null}
        </CellContainer>
      </td>
    </>
  );
};

export default RapCount;
