import React from "react";
import { useState } from "react";

const DownloadReport = ({
  handleDownloadSuccess,
  handleDownloadError,
  handleDownloadRequestError,
  captureReportDownloadParams,
}) => {
  const REPORT_HASH = {
    download_inv_report: [true, "Inventory Report"],
    download_sale_report: [false, "Sale Report"],
    download_aging_report: [false, "Aging Report"],
  };

  const [check, setCheck] = useState();

  const getReport = () => {
    // commented the logic, so when testing it does not send the report on email every time.
    // console.log("it is getting called.");
    // let accessToken = Cookies.get('access_token')
    // let queryParams = captureReportDownloadParams()
    // fetch(process.env.REACT_APP_API_URL + "/download_inventory_report", { method: 'POST', headers: { 'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json' }, body: JSON.stringify(queryParams) })
    //     .then(response => response.json())
    //     .then((response) => {
    //         if (response.error)
    //             handleDownloadError(response.message)
    //         else
    //             handleDownloadSuccess(response.message)

    //     })
    //     .catch(() => {
    //         handleDownloadRequestError()

    //     });
  };

  const handleCheckClick = (event) => {
    setCheck((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };
  return (
    <div style={{ marginBottom: "8px", width: "fit-content" }}>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#verticalycentered"
      >
        <i className=" bi bi-download me-1"></i> Download Report
      </button>
      <div className="modal fade" id="verticalycentered" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reports to Download</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-sm-10">
                  {Object.entries(REPORT_HASH).map(([key, value_n_name]) => {
                    return (
                      <div className="form-check" key={key}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={key}
                          name={key}
                          defaultChecked={value_n_name[0]}
                          onChange={handleCheckClick}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="gridCheck1"
                        >
                          {value_n_name[1]}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={getReport}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadReport;
