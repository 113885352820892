import React from 'react';
import { FaFlag, FaCaretUp, FaCaretDown } from "react-icons/fa";
import CellContainer from "../cellContainer";


const ArrowIcon = ({ direction, color }) => (
  direction !== 0 && (
    direction > 0 ? (
      <FaCaretDown size={17} color={color} enableBackground={"white"} />
    ) : (
      <FaCaretUp size={17} color={color} enableBackground={"white"} />
    )
  )
);


const RapChangeGrading = ({ diffValue, threshold }) => {


  const color = diffValue > threshold ? 'red' : diffValue < (threshold*-1) ? 'green' : 'gray';

  if (diffValue === null) {
    return (    
        <div className="h-max w-max">
          <p
            className={
              "text-[0.685rem] font-medium justify-center"
            }
          >
            <span>-</span>
          </p>
        </div>
    );
  }

  if (threshold > 0){
    return (

          <div
            className={
              "text-[0.685rem] font-medium justify-center "
            }
          >

            <ArrowIcon direction={diffValue} color={color} />
            <span style={{ color }}>{isNaN(diffValue) ? "-" : Math.abs(diffValue)}</span>
          </div>


    );
  }else{
    return (

          <div
            className={
              "text-[0.685rem] font-medium justify-center"
            }
          >

          </div>

    );
  }
};

export default RapChangeGrading;