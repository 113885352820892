import React, { useEffect, useState } from "react";

const RepricingInput = ({ inputValue, setInputValue, className, disabled }) => {
  // const [inputLocal, setInputLocal] = useState(inputValue);

  // useEffect(() => {
  //   setInputLocal(inputValue);
  // }, [inputValue]);

  // useEffect(() => {
  //   // if (isNaN(parseFloat(inputLocal))) {
  //   //   setInputValue(inputValue);
  //   // } else {
  //   //   setInputValue(inputLocal);
  //   // }

  //   setInputValue(inputLocal)
  // }, [inputLocal]);

  const handleInputChange = (e) => {
    e.stopPropagation();
    // Get the user's input
    let value = e.target.value;

    // Remove non-numeric characters, except for decimal points and minus sign
    value = value.replace(/[^0-9.-]/g, "");

    // Limit the input to two decimal places
    const decimalParts = value.split(".");
    if (decimalParts.length > 1) {
      const integerPart = decimalParts[0];
      const decimalPart = decimalParts[1].slice(0, 2);
      value = `${integerPart}.${decimalPart}`;
    }

    // Check for a minus sign at the beginning and make sure it's not in the middle of the number
    const minusSignIndex = value.indexOf("-");
    if (minusSignIndex > 0) {
      value = value.substring(0, minusSignIndex); // Remove minus sign in the middle
    }

    // Set the input value in the state
    setInputValue(value);
  };

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      className={className}
      placeholder="Enter a number"
      disabled={disabled}
    />
  );
};

export default RepricingInput;
