import React from "react";

import { ColorRing } from "react-loader-spinner";
import Cookies from "js-cookie";
import DataTable from "react-data-table-component";
import * as Bootstrap from "react-bootstrap";
import Moment from "moment";
import ExpandableRows from "../ExpandableRows/ExpandableRows";
import { useState } from "react";
import { useEffect, useRef } from "react";
import "./AgingReport.css";
import Form from "react-bootstrap/Form";
import CardTable from "../CardTable/CardTable";

const AgingReport = (props) => {
  const cardRef = useRef();
  const [width, setWidth] = useState();
  const [isCards, setIsCards] = useState(false);
  const [pageNumberx, setPageNumberx] = useState(1);

  useEffect(() => {
    const element = cardRef?.current;
    const resizeObserver = new ResizeObserver((event) => {
      // Depending on the layout, you may need to swap inlineSize with blockSize
      // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize

      let size =
        event[0].contentBoxSize[0].inlineSize > 600
          ? event[0].contentBoxSize[0].inlineSize - 32
          : event[0].contentBoxSize[0].inlineSize;
      setWidth(size);
      // setHeight(event[0].contentBoxSize[0].blockSize);
      // console.log({
      //   width: event[0].contentBoxSize[0].inlineSize,
      //   height: event[0].contentBoxSize[0].blockSize,
      // });
    });

    if (element) {
      resizeObserver.observe(element);
    }

    // setTimeout(() => {
    //   resizeObserver.observe(element);
    // }, 1000);
  }, [cardRef]);

  const [pending, setPending] = useState(false);
  const availableSizes = [50, 100, 250];
  const CENTS_TO_DOLLAR_CONVERSION_FACTOR = 100.0;
  const REPORT_VALUE_MAPPING = {
    STK: "attr_types.diamond#stock_number",
    CERT: "attr_types.diamond#cert_number",
    AGING: "aging_group",
    SHP: "attr_types.diamond#shape",
    COL: "attr_types.diamond#color",
    CLAR: "attr_types.diamond#clarity",
    CTS: "attr_types.gem#carats",
    MILKY: "attr_types.diamond#milky",
    SHADE: "attr_types.diamond#shade",
    MAKE: "attr_types.diamond#make",
    FLOUR: "attr_types.diamond#fluor",
    BIC: "attr_types.diamond#black_in_center",
    BIS: "attr_types.diamond#black_in_side",
    WIC: "attr_types.diamond#white_in_center",
    WIS: "attr_types.diamond#white_in_side",
    LAB: "attr_types.diamond#cert",
    "RAP %": "attr_types.diamond#rap_percent",
    "$/CT": "attr_types.gem#price_per_carat.cents",
    Total: "sell_offer_cents",
  };
  const REPORT_HASH = {
    download_inv_report: [false, "Inventory Report"],
    download_sale_report: [false, "Sale Report"],
    download_aging_report: [true, "Aging Report"],
  };
  const DEFAULT_REPORT_DOWNLOAD_PARAMS = {
    end_date: Moment(new Date()).format("yyyy-MM-DD"),
    start_date: Moment(
      new Date(new Date().setMonth(new Date().getMonth() - 1))
    ).format("yyyy-MM-DD"),
    inventory_date: Moment(new Date()).format("yyyy-MM-DD"),
    seller_account_id: [],
  };
  const columns_data = [];
  const DEFAULT_COLUMNS = {
    "LD ID": (row) => row.id,
    STK: (row) => row.item_attributes["diamond#stock_number"].trans || "N/A",
    CERT: (row) => row.item_attributes["diamond#cert_number"].trans || "N/A",
    AGING: (row) => row.aging_group,
    SHP: (row) => row.item_attributes["diamond#shape"].trans || "N/A",
    COL: (row) => row.item_attributes["diamond#color"].trans || "N/A",
    CLAR: (row) => row.item_attributes["diamond#clarity"].trans || "N/A",
    CTS: (row) => row.item_attributes["gem#carats"].trans || "N/A",
    MILKY: (row) => row.item_attributes["diamond#milky"].trans || "N/A",
    SHADE: (row) => row.item_attributes["diamond#shade"].trans || "N/A",
    MAKE: (row) => row.item_attributes["diamond#make"].trans || "N/A",
    FLOUR: (row) => row.item_attributes["diamond#fluor"].trans || "N/A",
    // 'BIC'   : row => row.item_attributes['diamond#black_in_center'].trans || 'N/A',
    // 'BIS'   : row => row.item_attributes['diamond#black_in_side'].trans || 'N/A',
    // 'WIC'   : row => row.item_attributes['diamond#white_in_center'].trans || 'N/A',
    // 'WIS'   : row => row.item_attributes['diamond#white_in_side'].trans || 'N/A',
    // 'LAB'   : row => row.item_attributes['diamond#cert'].trans || 'N/A',
    "RAP %": (row) => row.item_attributes["diamond#rap_percent"].trans || "N/A",
    "$/CT": (row) => row.item_attributes["gem#price_per_carat"].trans || "N/A",
    Total: (row) => "$" + Math.round(row.sell_offer_cents / 100),
  };

  const agingGroupSort = (rowA, rowB) => {
    const a = rowA.title.split("/-|+/")[0];
    const b = rowB.title.split("/-|+/")[0];
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  Object.entries(DEFAULT_COLUMNS).map(([key, selector_attr]) => {
    // console.log(key);
    columns_data.push({
      name: key,
      title: key,
      selector: selector_attr,
      sortable: REPORT_VALUE_MAPPING[key] ? true : false,
      width: "95px",
    });
  });

  // constructor --- code---

  let defaultFilter = {};
  let isSellReport = false;
  let sellerAccountId = "";
  let startDate = "";
  let endDate = "";

  const expanableComponent = ({ data }) => (
    <div style={{ width, maxWidth: width }}>
      <ExpandableRows data={data} isCsr={props.isCsr} />
    </div>
  );

  if (props.filterHash) {
    defaultFilter = props.filterHash.item_filter;
    isSellReport = props.filterHash.is_sale_report;
    sellerAccountId = props.filterHash.seller_account_id;
    startDate = props.filterHash.start_date;
    endDate = props.filterHash.end_date;
  } else defaultFilter = { discrete: { state: ["for_sale"] } };

  const [state, setState] = useState({
    expandedComponent: "<p></p>",
    itemsData: {},
    columns: columns_data,
    totalRows: 0,
    DataisLoaded: false,
    apiDataisLoaded: false,
    showAlert: false,
    filterHash: {
      pageNumber: 0,
      from: 0,
      size: availableSizes[0],
      show_mine: true,
      show_others: true,
      seller_account_id: sellerAccountId,
      start_date: startDate,
      end_date: endDate,
      get_filtered_data: true,
      is_sale_report: isSellReport,
      item_filter: defaultFilter,
      get_only_matching_count: false,
      change_breakdown: false,
      get_turn: false,
      sort_by: "sell_offer_cents",
      asc_desc: "asc",
    },
    download_inv_report: false,
    download_sale_report: false,
    download_aging_report: true,
    downloadReportMessage: "",
    downloadErrorAlert: false,
    downloadSuccessAlert: false,
  });

  let multi_sort =
    "&multi_sort%5B0%5D%5Battr_types.diamond%23shape%5D=asc&multi_sort%5B1%5D%5Bcarat_ranges%5D=asc&multi_sort%5B2%5D%5Battr_types.diamond%23color%5D=asc&multi_sort%5B3%5D%5Battr_types.diamond%23clarity%5D=asc";

  const handleCheckClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
    // console.log(state);
  };

  const requestForReports = () => {
    let accessToken = Cookies.get("access_token");
    let queryParams = captureReportDownloadParams();
    fetch(process.env.REACT_APP_API_URL + "/download_inventory_report", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(queryParams),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error)
          setState((prevState) => ({
            ...prevState,
            downloadErrorAlert: true,
            downloadSuccessAlert: false,
            downloadReportMessage: response.message,
          }));
        else
          setState((prevState) => ({
            ...prevState,
            downloadSuccessAlert: true,
            downloadErrorAlert: false,
            downloadReportMessage: response.message,
          }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          downloadErrorAlert: true,
          downloadSuccessAlert: false,
          downloadReportMessage:
            "Something went Wronge, Please contact Administrator.",
        }));
      });
  };

  const captureReportDownloadParams = () => {
    let paramsHash = DEFAULT_REPORT_DOWNLOAD_PARAMS;
    let filterHash = state.filterHash;
    paramsHash["seller_account_id"] = filterHash.seller_account_id;
    paramsHash["download_inv_report"] = state.download_inv_report;
    paramsHash["download_sale_report"] = state.download_sale_report;
    paramsHash["download_aging_report"] = state.download_aging_report;
    return paramsHash;
  };

  const doSort = (column, sortDirection) => {
    // if (column.name === 'AGING')
    //     return

    var filterHash = state.filterHash;
    filterHash["sort_by"] = REPORT_VALUE_MAPPING[column.name];
    filterHash["asc_desc"] = sortDirection;
    multi_sort = "";
    setState((prevState) => {
      return { ...prevState, filterHash: filterHash, apiDataisLoaded: false };
    });
    setPending(true);
    collectAgingData();
  };

  const handlePerRowsChange = (sizePerPage) => {
    let filterHash = state.filterHash;
    filterHash.size = sizePerPage;
    filterHash.from = 0;
    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
      apiDataisLoaded: false,
    }));
    collectAgingData();
  };

  const handlePageChange = (pageNumber) => {
    setPageNumberx(pageNumber);
    // console.log({ pageNumber });
    let filterHash = state.filterHash;
    filterHash.from = filterHash.size * (pageNumber - 1);
    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
      apiDataisLoaded: false,
    }));
    setPending(true);
    collectAgingData();
  };

  const collectAgingData = () => {
    captureUrls();
    let accessToken = Cookies.get("access_token");
    let queryParams = serialize(state.filterHash) + multi_sort;
    fetch(process.env.REACT_APP_API_URL + "/items.json?" + queryParams, {
      method: "GET",
      headers: { Authorization: "Bearer " + accessToken },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.meta.total < 1)
          setState((prevState) => ({ ...prevState, showAlert: true }));
        if (response.errors) alert(response.errors.error);
        else
          setState((prevState) => {
            // console.log("the prevState: ", prevState);

            return {
              ...prevState,
              itemsData: response.items,
              apiDataisLoaded: true,
              expandedComponent: JSON.stringify(response.item, null, 2),
              totalRows: response.meta.total,
              DataisLoaded: true,
            };
          });
        setPending(false);
      })
      .catch((err) => {
        // console.log({ error: err });

        // resetting the sort

        var filterHash = state.filterHash;
        filterHash["sort_by"] = "sell_offer_cents";
        filterHash["asc_desc"] = "asc";
        setState((prevState) => {
          // console.log({ prevState });
          return {
            ...prevState,
            filterHash: filterHash,
          };
        });

        collectAgingData();
        setPending(false);
      });
  };

  const serialize = (obj, prefix) => {
    var str = [],
      p,
      m;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        if (!isNaN(p)) {
          m = "";
        } else {
          m = p;
        }
        var k = prefix ? prefix + "[" + m + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };

  const captureUrls = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let sellerIds;
    let filterHash = state.filterHash;
    var sellerIdString = queryParams.get("sellerIds");
    if (sellerIdString) {
      sellerIds = sellerIdString.split(",");
      filterHash["seller_account_id"] = sellerIds;
    }
    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
    }));
  };

  useEffect(() => {
    collectAgingData();
  }, []);

  const {
    DataisLoaded,
    apiDataisLoaded,
    itemsData,
    columns,
    totalRows,
    expandedComponent,
    filterHash,
    downloadReportMessage,
    downloadErrorAlert,
    downloadSuccessAlert,
  } = state;
  const tableOpacity = apiDataisLoaded ? 1 : 0.5;
  const displayLoader = apiDataisLoaded ? "none" : "block";
  let showAlert = totalRows < 1 && state.showAlert;

  return (
    <>
      {/* {console.log(state)} */}
      {!DataisLoaded ? (
        <div className="row">
          <div className="align-items-center justify-content-center">
            <h3 className=" text-center"> Searching Diamonds for you.... </h3>
          </div>
          <ColorRing
            visible={true}
            height="60"
            width="60"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
          />
        </div>
      ) : (
        <div className="row justify-content-start border-secondary">
          {showAlert ? (
            <Bootstrap.Alert
              key="warning"
              variant="warning"
              onClose={() =>
                setState((prevState) => ({ ...prevState, showAlert: false }))
              }
              style={{ width: "80%", marginLeft: "10%" }}
            >
              Oops, we couldn’t find any data.
            </Bootstrap.Alert>
          ) : (
            <div>
              {downloadErrorAlert ? (
                <div
                  className="alert alert-danger alert-dismissible fade show text-center"
                  role="alert"
                >
                  <i className="bi bi-exclamation-triangle me-1"></i>
                  {downloadReportMessage}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              ) : null}

              {downloadSuccessAlert ? (
                <div
                  className="alert alert-success alert-dismissible fade show text-center"
                  role="alert"
                >
                  <i className="bi bi-exclamation-triangle me-1"></i>
                  {downloadReportMessage}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              ) : null}

              <div className="col-lg-12"></div>
              {/* <Form
                style={{
                  margin: "10px 0px",
                  display: "flex",

                  justifyContent: "flex-end",
                }}
              >
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Toggle View"
                  onChange={() => setIsCards((prev) => !prev)}
                />
              </Form> */}

              {isCards ? (
                <CardTable
                  data={itemsData}
                  pageNumber={pageNumberx}
                  handlePageChange={handlePageChange}
                  pending={pending}
                />
              ) : (
                <div className="row">
                  <div className="col-lg-12 remove-pd">
                    <div className="card">
                      <div
                        className="card-body"
                        style={{ opacity: "1" }}
                        ref={cardRef}
                      >
                        {props.filterHash ? null : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              // display: "flex",
                              // justifyContent: "end",
                              // alignContent: "center",
                              // width: "100%",
                              // overflow: "hidden"
                            }}
                          >
                            <div
                              className="col-xxl-2 col-md-12"
                              style={{
                                width: "fit-content",
                                justifyContent: "end",
                                padding: "0px",
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#verticalycentered"
                              >
                                <i className=" bi bi-download me-1"></i>{" "}
                                Download Report
                              </button>
                              <div
                                className="modal fade"
                                id="verticalycentered"
                                tabIndex="-1"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title">
                                        Reports to Download
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row mb-3">
                                        <div className="col-sm-10">
                                          {Object.entries(REPORT_HASH).map(
                                            ([key, value_n_name]) => {
                                              return (
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={key}
                                                    name={key}
                                                    defaultChecked={
                                                      value_n_name[0]
                                                    }
                                                    onChange={handleCheckClick}
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="gridCheck1"
                                                  >
                                                    {value_n_name[1]}
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={requestForReports}
                                      >
                                        Save changes
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <hr />

                        <DataTable
                          progressPending={pending}
                          progressComponent={
                            <ColorRing
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#00319f",
                                "#2e56b1",
                                "#5c7bc2",
                                "#8aa1d3",
                                "#b2c1e2",
                              ]}
                            />
                          }
                          columns={columns}
                          data={itemsData}
                          onSort={doSort}
                          expandableRows
                          expandableRowsComponent={expanableComponent}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          paginationPerPage={availableSizes[0]}
                          paginationRowsPerPageOptions={availableSizes}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePerRowsChange}
                          highlightOnHover={true}
                          expandOnRowClicked={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div style={{ display: displayLoader, zIndex: 2, margin: '00px' }}>
                                    <div className='loader'>
                                        <ColorRing
                                            visible={true}
                                            height="80"
                                            width="80"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#00319f', '#2e56b1', '#5c7bc2', '#8aa1d3', '#b2c1e2']}
                                        />
                                    </div>
                                    <div>
                                        <h1 className="mb-3"> Please wait.... </h1>
                                    </div>
                                </div> */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AgingReport;
