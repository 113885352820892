import React, { useState } from 'react';
import { Typography } from '@mui/material';
import CustomTabs from './ui/tabs';

const DiamondCarousel = ({ userDiamond, filterContent, getCertURL, iframeUrl }) => {
  const [value, setValue] = React.useState(0);
  
  return (

    <div className="w-full overflow-y-auto flex flex-col items-center gap-2">
      <div className="flex flex-col w-full h-full items-center">
        <div className="flex flex-col items-center mb-3">
          <Typography variant="h7" className="font-bold">
            My Diamond
          </Typography>
          <Typography
          variant="h7"
            className="text-gray-400 cursor-pointer"
            onClick={() => {
            const selection = window.getSelection();
            let url = getCertURL(userDiamond);
            if (selection.type !== "Range") {
              window.open(url, "_blank");
            }
            }}
          >
            {userDiamond["Cert Num"] !== "" ? userDiamond["Cert Num"] : "--"}
          </Typography>
        </div>

        <div className="relative w-full h-full mb-2">
          <div className="w-full h-full">
            {value === 0 && filterContent(userDiamond["Video Url"])}
            {value === 1 && <iframe 
              src={iframeUrl} 
              loading="lazy" 
              className="w-full h-full rounded-lg"
            /> }
          </div>
        </div>
      </div>
      <div className="flex  justify-end h-max py-1  w-full rounded-md ">
        <CustomTabs value={value} setValue={setValue} lab={userDiamond["Lab"]} />
      </div>
    </div>
  );
};

export default DiamondCarousel;