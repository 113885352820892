import { Drawer } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import FilterMinMax from "./filterMinMax";
import TableFilter from "./TableFilter/TableFilter";
import amplitude from "../utils/ampli";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  setSeverity,
  setAlertText,
  setToast,
} from "../redux/slices/commonComponentSlice";
import FilterActions from "./filterActions";
import { cn } from "../utils/utils";
import { setBroadCount } from "../redux/slices/pricingSlice";
const FilterDrawer = ({
  open,
  onClose,
  configData,
  userdiamond,
  refreshMarketItems,
  setFiltered,
  setMappedItemSearchCriteria,
  checked,
  setChecked,
  setCurrentPage,
  setMarketFrom

}) => {
  // we set the filter state to the filtered value, when the drawer is opened.
  // this is done so that the drawer shows the filtered values.
  
  const [filterConfigData, setFilterConfigData] = useState(configData);
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const isInitialMount = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setFilterConfigData(configData);
      // setHasFilterChanged(false);
    }
  }, [configData]);
  

  const measumentsFilter = [
    { label: "Carat", key: "carats" },
    { label: "Length", key: "dim_l" },
    { label: "Width", key: "dim_w" },
    { label: "Depth", key: "dim_d" },
    { label: "Ratio", key: "ratio" },
  ];

  const minMaxFilters = [
    { label: "Table%", key: "table_percentage" },
    { label: "Depth%", key: "depth_percentage" },
  ];

  const handleMeasurement = (key, value) => {
    setFilterConfigData((prev) => {
      const x = { ...prev };
      const obj = x[key];
      if (obj && obj.value) {
        const newValue = {
          ...obj.value,
          from: value.min,
          to: value.max,
        };
        x[key] = {
          ...obj,
          value: newValue,
        };      
      }
      setMappedItemSearchCriteria(x);
      return x; // return the new state array
    });

    setHasFilterChanged(true);
  };

  const handleSetFilter = (key, value) => {
    // If no value is selected, then send empty array for that key
    // else
    // send values which are selected
    setFilterConfigData((prev) => {
      const x = { ...prev };
      const obj = x[key];
      if (obj && Array.isArray(obj.value)) {
        // Create a new array with updated is_selected values
        const newValue = obj.value.map((valObj) => ({
          ...valObj, // Shallow copy each object
          is_selected: value.includes(valObj.key),
        }));

        // Update the specific key with the new value array
        x[key] = {
          ...obj, // Shallow copy the object
          value: newValue, // Replace the value array with the new one
        };
      }
      setMappedItemSearchCriteria(x);
      return x; // return the new state array
    });

    setHasFilterChanged(true);
  };

  function getObjectById(array, id) {
    let x = array.find((obj) => obj.id === id);

    if (x) {
      return x;
    }

    return {
      id: id,
      value: [],
    };
  }
  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <div className="w-[90vw] min-w-[400px] md:w-[30vw] md:min-w-[450px] md:max-w-[450px] min-h-full h-[full] flex flex-col overflow-hidden">
        <header className="min-h-[60px] border-x-0  border-t-0 border-b border-solid border-[#D8D8D8] flex items-center gap-4">
          <svg
            className="ml-4 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="15px"
            height="15px"
            viewBox="0 -2 32 32"
            onClick={onClose}
          >
            <g id="Lager_86" data-name="Lager 86" transform="translate(0 -3)">
              <path
                id="Path_94"
                data-name="Path 94"
                d="M16.587,6.42,27.167,17,16.587,27.58a2,2,0,0,0,2.833,2.833L31.414,18.42a2.013,2.013,0,0,0,0-2.84L19.42,3.587A2,2,0,0,0,16.587,6.42Z"
                fill="#161615"
              />
              <path
                id="Rectangle_45"
                data-name="Rectangle 45"
                d="M0,0H26a2,2,0,0,1,2,2V2a2,2,0,0,1-2,2H0A0,0,0,0,1,0,4V0A0,0,0,0,1,0,0Z"
                transform="translate(28 19) rotate(180)"
                fill="#161615"
              />
            </g>
          </svg>
          <h4 className="m-0 font-medium text-base">Filter Options.</h4>
        </header>

        <div className="overflow-auto h-full ">
          <div className="flex flex-col px-3 py-3 gap-2">
            <h6 className="my-0 px-2 mb-2 m-0 font-bold text-xs text-[#838383]">
              DIMENSIONS
            </h6>
            <div className="grid grid-col-1 gap-2">
              {measumentsFilter.map((item) => {
                const configItem = filterConfigData[item.key];
                if (configItem) {
                  return (
                    <FilterMinMax
                      key={item.key}
                      label={configItem.label}
                      setFiltered={handleMeasurement}
                      dMin={configItem.value.min}
                      dMax={configItem.value.max}
                      val={item.key}
                      from={configItem.value.from}
                      to={configItem.value.to}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
          <div className="h-[1px] w-[90%] border-x-0  border-t-0 border-b border-solid border-[#D8D8D8] m-auto" />
          <div className="flex flex-col px-3 py-3 gap-2">
            <h6 className="my-0 px-2 mb-2 m-0 font-bold text-xs text-[#838383]">
              PROPORTIONS
            </h6>
            <div className="grid grid-col-1 gap-2">
              {minMaxFilters.map((item) => {
                const configItem = filterConfigData[item.key];
                if (configItem) {
                  return (
                    <FilterMinMax
                      key={item.key}
                      label={configItem.label}
                      val={item.key}
                      setFiltered={handleMeasurement}
                      dMin={configItem.value.min}
                      dMax={configItem.value.max}
                      from={configItem.value.from}
                      to={configItem.value.to}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>

          {[
            "color",
            "clarity",
            "cut",
            "polish",
            "symmetry",
            "fluor",
            "cert",
            "shade",
            "milky",
            "location",
            "key_to_symbol",
            {/* "cert_comment_tags", */}
          ].map((key) => {
            const configItem = filterConfigData[key];
            if (configItem) {
              return (
                <div key={key} className="flex flex-col px-3 gap-2">
                  <TableFilter
                    data={configItem.value.map((item) => item.key)}
                    label={configItem.label}
                    filterKey={key}
                    filterOptions={configItem.value}
                    setFiltered={handleSetFilter}
                  />
                </div>
              );
            }

            return null;
          })}

          <div className="h-[1px] w-[90%] border-x-0  border-t-0 border-b border-solid border-[#D8D8D8] m-auto" />
        </div>

        <div className="min-h-12 w-full  bg-white  border-x-0  border-t border-b-0 border-solid border-[#D8D8D8] flex items-center justify-evenly gap-4 px-4 py-2">
          <button
            className="h-8 max-w-[180px] w-full border-none rounded-xl cursor-pointer text-sm font-medium bg-[#A4D9FF]"
            onClick={(ev) => {
              const buttonClickedEvent = {
                event_type: `v4_itemDetails_filter_and_lock`,
                event_properties: {
                  value: filterConfigData,
                  id: userdiamond["LD ID"],
                },
              };
              refreshMarketItems({
                isReset: false,
                isFilter: true,
                isDefault: false,
                lock: true,
                isCompetition: checked,
                isBroad: false,
              });
              // lockFilters(filterState);
              
              setCurrentPage(1)
              setMarketFrom(0)
              setChecked(false)
              dispatch(setBroadCount(false));
              setHasFilterChanged(false)
              onClose(ev);
            }}
          >
            Apply & Lock
          </button>
          <button
            className={cn(
              "h-8 max-w-[180px] w-full border-none rounded-xl cursor-pointer text-sm font-medium",
              hasFilterChanged ? "bg-[#A4D9FF] " : "bg-gray-200 text-gray-400 cursor-not-allowed"
            )}
            disabled={!hasFilterChanged}
            onClick={(ev) => {
              const buttonClickedEvent = {
                event_type: `v4_itemDetails_filter`,
                event_properties: {
                  value: filterConfigData,
                  id: userdiamond["LD ID"],
                },
              };
              // amplitude.track(buttonClickedEvent);
              refreshMarketItems({
                isReset: false,
                isFilter: true,
                isDefault: false,
                lock: false,
                isCompetition: checked,
                isBroad: false,
              });
              setCurrentPage(1);
              setMarketFrom(0);
              setHasFilterChanged(false)
              dispatch(setBroadCount(false));
              // call a method of PriceItemDetails which would change the filterState over there and call the query, which should update the market stones
              onClose(ev);
            }}
          >
            Apply
          </button>

          <FilterActions
            onClose={onClose}
            setFiltered={setFiltered}
            refreshMarketItems={refreshMarketItems}
            userdiamond={userdiamond}
            setFilterConfigData={setFilterConfigData}
            setChecked={setChecked}
            setMappedItemSearchCriteria={setMappedItemSearchCriteria}
            setCurrentPage={setCurrentPage}
            setMarketFrom={setMarketFrom}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;