import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { pricingModal } from "../redux/slices/pricingModalSlice";
import { cn, filterLiquidUrls, getCertURL } from "../utils/utils";
import Carousel from "./carousel";
import { Video, XCircle, ShieldCheck} from "lucide-react";
import amplitude from "../utils/ampli";
import CircularProgress from "@mui/material/CircularProgress";
import ComparisonTable from "./newTable/comparisonTable";
import DiamondCarousel from "./diamondCarousel";

const getActiveTab = (id) => {
  switch (id) {
    case "Inventory Diamonds":
      return 0;

    case "Market Diamonds":
      return 1;

    case "Competition Diamonds":
      return 2;

    default:
      return 0;
  }
};

const CompareModal = ({ handleClose, pageSize , marketFilters, updateMetaTags}) => {
  const state = useSelector(pricingModal);

  const { data, selectedItem } = state;

  const [activeTab, setActiveTab] = React.useState(
    getActiveTab(selectedItem?.id)
  );
  const [index, setIndex] = React.useState(0);

  const getActiveTabData = (idx) => {
    switch (idx) {
      case 0:
        return inventoryDiamonds;
      case 1:
        return marketDiamonds;
      case 2:
        return competitionDiamonds;
    }
  };

  // const priceItem = useSelector(pricing).selectedItem;

  const userDiamond = data["User Diamond"][0];
  const inventoryDiamonds = data["Inventory Diamonds"];
  const marketDiamonds = data["Market Diamonds"];
  const competitionDiamonds = data["Competition Diamonds"];

  let iframeUrl = getCertURL(userDiamond);

  const [activeData, setActiveData] = React.useState(
    getActiveTabData(activeTab)
  );

  const [activeIndex, setActiveIndex] = React.useState({
    inventory:
      selectedItem?.id == "Inventory Diamonds" ? selectedItem.index : 0,
    market: selectedItem?.id == "Market Diamonds" ? selectedItem.index : 0,
    competition:
      selectedItem?.id == "Competition Diamonds" ? selectedItem.index : 0,
  });
  const [prevTab, setPrevTab] = React.useState(null);

  useEffect(() => {
    setPrevTab(activeTab);
    switch (selectedItem.id) {
      case "Inventory Diamonds":
        setActiveTab(0);

        break;
      case "Market Diamonds":
        setActiveTab(1);
        break;
      case "Competition Diamonds":
        setActiveTab(2);
        break;

      default:
        setActiveTab(0);
    }
  }, [selectedItem]);

  useEffect(() => {
    switch (activeTab) {
      case 0:
        setActiveData(inventoryDiamonds); // setting the active data for the items
        // setting the previous tabs based on which tab was clicked.
        prevTab == 1
          ? setActiveIndex((prev) => ({ ...prev, market: index }))
          : setActiveIndex((prev) => ({ ...prev, competition: index }));
        setIndex(activeIndex.inventory); // setting the index from the index data.
        break;
      case 1:
        setActiveData(marketDiamonds);
        prevTab == 0
          ? setActiveIndex((prev) => ({ ...prev, inventory: index }))
          : setActiveIndex((prev) => ({ ...prev, competition: index }));
        setIndex(activeIndex.market);
        break;
      case 2:
        setActiveData(competitionDiamonds);
        prevTab == 0
          ? setActiveIndex((prev) => ({ ...prev, inventory: index }))
          : setActiveIndex((prev) => ({ ...prev, market: index }));
        setIndex(activeIndex.competition);
        break;
    }

    // if (activeTab == 0) {
    //   setActiveData(inventoryDiamonds);
    //   setActiveIndex((prev) => ({ ...prev, market: index }));
    //   setIndex(activeIndex.inventory);
    // } else {
    //   setActiveData(marketDiamonds);
    //   setActiveIndex((prev) => ({ ...prev, inventory: index }));
    //   setIndex(activeIndex.market);
    // }
  }, [activeTab]);

  const handleNext = () => {
    const buttonClickedEvent = {
      event_type: `v2_itemDetails_comparisonModal_next`,
    };
    amplitude.track(buttonClickedEvent);
    setIndex((prev) => (prev < marketDiamonds.length - 1 ? prev + 1 : prev));
  };
  const handlePrev = () => {
    const buttonClickedEvent = {
      event_type: `v2_itemDetails_comparisonModal_prev`,
    };
    amplitude.track(buttonClickedEvent);
    setIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };


  const filterContent = (url) => {
    const newUrl = filterLiquidUrls(url);

    if (!newUrl)
      return (
       <div className="w-full flex justify-center h-full">
        <img
          src={"assets/img/no-image.png"}
          loading="lazy"
          className="w-[200px] px-2 min-h-[300px] max-w-[700px] lg:max-w-[600px] mx-auto rounded-lg object-contain relative  "
        />
        </div>
      );

    if (newUrl.includes("jpg") || newUrl.includes("png")) {
      return (
        <img
          src={newUrl}
          loading="lazy"
          className="w-full  rounded-lg object-contain relative "
        />
      );
    } else if (newUrl.includes(".mp4")) {
      return (
        <video
          src={newUrl}
          loading="lazy"
          className="w-[90%]  max-h-[100%] min-h-[250px] rounded-lg relative "
          controls
        />
      );
    } else {
      return (
        <iframe
          src={newUrl}
          loading="lazy"
          className="w-full h-full relative"
        />
      );
    }
  };


  const generateCompareTableData = (data1, data2, activeTab) => {
    const l2 =
      activeTab == 0
        ? "Inventory Diamond"
        : activeTab == 1
        ? "Market Diamonds"
        : "Competition Diamond";

    return [
      {
        isHeader: true,
        Owner: "My Diamond",
        ...data1,
      },
      {
        Owner: l2,
        ...data2,
      },
    ];
  };

  const getTabList = () => {
    const x = [];

    if (inventoryDiamonds.length > 0) {
      x.push("Inventory Diamonds");
    }

    if (marketDiamonds.length > 0) {
      x.push("Market Diamonds");
    }

    if (competitionDiamonds.length > 0) {
      x.push("Competition Diamonds");
    }

    return x;
  };

  return (
    <div
      className="absolute top-0 left-0 w-full h-screen flex justify-center items-center "
      onClick={handleClose}
    >
      <div
        class="relative m-16 w-[calc(100%-100px)] max-h-[calc(100%-50px)] md:h-full lg:h-full  max-w-[1500px] rounded-lg bg-white border border-gray-300 p-8 pb-1 overflow-hidden"
        onClick={(ev) => ev.stopPropagation()}
      >
        <div
          className="absolute top-0 right-0 cursor-pointer "
          onClick={handleClose}
        >
          <XCircle className="hover:fill-red-200" />
        </div>
        <div className="w-full flex justify-start mb-2 max-h-[125px] overflow-y-auto ">
          <ComparisonTable
            data={generateCompareTableData(
              userDiamond,
              activeData[index],
              activeTab
            )}

            marketFilters={marketFilters}
            updateMetaTags={updateMetaTags}

          />
        </div>
        <div className="flex flex-col w-full h-[calc(100%-125px)] lg:flex-row gap-3 overflow-y-auto">
          <DiamondCarousel 
            userDiamond={userDiamond}
            filterContent={filterContent}
            getCertURL={getCertURL}
            iframeUrl={iframeUrl}  
          />

          <div className="w-full overflow-y-auto flex flex-col items-center gap-2">
            {activeData[index] ? (
              <div className="flex flex-col items-center w-full">
                <div className="flex w-full justify-between">
                  <button
                    className={cn(
                      "rounded-lg flex justify-center items-center   border border-[#EAEAEA] bg-white ",
                      index == 0
                        ? "cursor-not-allowed "
                        : "flex shadow-md lg:shadow-md custom-shadow"
                    )}
                    disabled={index == 0}
                    onClick={handlePrev}
                  >
                    <p className="text-sm font-bold m-0 px-1 py-2">Prev</p>
                  </button>
                  <div className="flex gap-3 ">
                    {activeData.map((item, idx) => {
                      let indexArr = [];

                      if (index == 0) {
                        indexArr = [index, index + 1, index + 2];
                      } else if (index == 1) {
                        indexArr = [index - 1, index, index + 1];
                      } else if (index < activeData.length - 2) {
                        indexArr = [index - 1, index, index + 1];
                      } else if (index == activeData.length - 2) {
                        indexArr = [index - 1, index, index + 1];
                      } else {
                        indexArr = [index - 2, index - 1, index];
                      }

                      return (
                        <div
                          className={cn(
                            "flex-col p-2 rounded-lg justify-center items-center gap-1 cursor-pointer",
                            indexArr.includes(idx)
                              ? idx == index
                                ? "flex bg-[#D9D9D9]/25 min-w-[180px]"
                                : "flex max-w-[90px]"
                              : " hidden"
                          )}
                          onClick={() => {
                            const buttonClickedEvent = {
                              event_type: `v2_itemDetails_comparisonModal_carouselItemClick`,
                              event_properties: {
                                value: activeData[idx]["LD ID"],
                              },
                            };
                            amplitude.track(buttonClickedEvent);
                            setIndex(idx);
                          }}
                        >
                          <p
                            className={cn(
                              "p-0 m-0 text-xs  overflow-hidden whitespace-nowrap cursor-auto",
                              idx == index ? "" : "w-[56px]"
                            )}
                          >
                            {item && item["Video Url"] ? (
                              <div className="flex">{item["Supplier"]}</div>
                            ) : (
                              item["Supplier"]
                            )}
                          </p>

                          {(item && item["Cert Url"] !== "") ||
                          (item && item["Cert Num"] !== null) ||
                          (item && item["Cert Num"] !== undefined) ? (
                            <p className="text-xs font-bold m-0 p-0 text-gray-400 flex cursor-auto">
                              {item && item["Cert Num"] !== ""
                                ? item["Cert Num"]
                                : "--"}
                              {item && item["Cert Num"] !== "" ? (
                                <div className="flex text-[#212529]">
                                  <ShieldCheck 
                                    height={"16px"}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      const buttonClickedEvent = {
                                        event_type: `v2_itemDetails_comparisonModal_certWindow`,
                                        event_properties: {
                                          value: activeData[index]["Video Url"],
                                        },
                                      };
                                      amplitude.track(buttonClickedEvent);

                                      const selection = window.getSelection();
                                      let url = getCertURL(item);
                                      if (selection.type !== "Range") {
                                        window.open(url, "_blank");
                                      }
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}

                              {item && item["Video Url"] ? (
                                <div className="flex text-[#212529]">
                                  <Video
                                    height={"16px"}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      const buttonClickedEvent = {
                                        event_type: `v2_itemDetails_comparisonModal_newWindow`,
                                        event_properties: {
                                          value: activeData[index]["Video Url"],
                                        },
                                      };
                                      amplitude.track(buttonClickedEvent);

                                      window.open(
                                        activeData[index]["Video Url"],
                                        "_blank"
                                      );
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </p>
                          ) : (
                            <p className="text-xs font-bold m-0 p-0 text-gray-400">
                              {item && item["Cert Num"] !== ""
                                ? item["Cert Num"]
                                : "--"}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <button
                    className={cn(
                      "rounded-lg flex justify-center items-center   border border-[#EAEAEA] bg-white ",
                      index == activeData.length - 1
                        ? "cursor-not-allowed "
                        : "flex shadow-md lg:shadow-md custom-shadow"
                    )}
                    disabled={index == pageSize - 1}
                    onClick={handleNext}
                  >
                    <p className="text-sm font-bold m-0 px-1 py-2">Next</p>
                  </button>
                </div>

                {/* {console.log(activeData[index])} */}
              </div>
            ) : (
              <CircularProgress />
            )}
            {
              <Carousel
                items={activeData}
                activeIndex={index}
                filterContent={filterContent}
                getCertURL={getCertURL}
              />
            }
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareModal;
