import React from 'react'

const Subscription = () => {
    return (
        <div className="row border-secondary text-center" style={{ padding: "100px" }}>
            <p>We would like to thank you for trying out our Inventory & Sales Analysis report during your free trial.</p>
            <p>We hope that you found it valuable and that it helped you understand your Inventory better.</p>
            <p>We wanted to remind you that your free trial has now expired, and to continue using Inventory & Sales Analysis report, you will need to upgrade to a paid plan.</p>
            <p>But, before you do, we would love to <a href="mailto:cgupta@liquid.diamonds">chat</a> with you and learn more about your experience.</p>
            <p>Please don't hesitate to <a href="mailto:cgupta@liquid.diamonds">contact us</a> to learn more.</p>
        </div>
    )
}

export default Subscription