import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/slices/apiSlice";
import { fakeSlice } from "./slices/fakeSlice";
import pricingReducer from "../redux/slices/pricingSlice";
import commonComponentReducer from "../redux/slices/commonComponentSlice";
import authReducer from "../redux/slices/authSlice";
import pricingModalReducer from "./slices/pricingModalSlice";

export const store = configureStore({
  reducer: {
    pricing: pricingReducer,
    commonComponent: commonComponentReducer,
    auth: authReducer,
    pricingModal: pricingModalReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [fakeSlice.reducerPath]: fakeSlice.reducer,
  
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware,fakeSlice.middleware),
});
