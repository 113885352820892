import React, { Fragment, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./RapnetModal.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";
import { CheckCircle } from 'lucide-react';
import { useSelector } from "react-redux";
import { auth } from "../../redux/slices/authSlice";


const RapnetModal = ({ openModal, setOpenModal, isSet, toggleRapnet }) => {
  const handleClose = () => {
    setOpenModal(false);
    setValidateCreds(false);
    setShow(isSet);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [validateCreds, setValidateCreds] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(isSet);

  /*
  the data types:
  {
    error : boolean,
    errorMessage : string,
    message: string
  }

  */

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    try {
      setLoading(true);
      // const tokena = useSelector(auth).token;
      let token = Cookies.get("access_token");
      let queryParams = {
        username: email,
        password: password,
      }

      let resp = await fetch(
        process.env.REACT_APP_API_URL + `/save_rapnet_credentials`,
        {
          method: 'POST',
          headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, 
          body: JSON.stringify(queryParams)
        }
      );

      if (resp.ok) {
        setValidateCreds(true);
        setData({
          error: false,
          message: "The credentials are validated and saved",
        });
        setLoading(false);
      } else {
        const errorMessage = await resp.json();
        console.log(errorMessage);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setLoading(false);
    }
  };


  return (
    <div className="rapnet-modal-container">
      <Modal show={openModal} onHide={handleClose} dialogClassName="modal-50w">
        <Modal.Header closeButton>Rapnet Credentials</Modal.Header>

        <Modal.Body style={{ overflow: "scroll" }}>
          {show ? (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div><CheckCircle width="16px" color="green"/><p>The Rapnet Credentials is Already set.</p></div>
                <div
                  onClick={() => setShow(false)}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  Click to Reset the Creds
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {validateCreds ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  {data.error ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#FF0000"
                        className="w-3 h-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>

                      {data["errorMessage"]}
                    </div>
                  ) : (
                    <div>
                      <CheckCircle width="16px" color="green"/> &nbsp;&nbsp;
                      {data["message"]}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* {console.log({ show })} */}
                  <Form
                    onSubmit={handleFormSubmit}
                    style={{ width: "100%", padding: "20px" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email"
                        onChange={({ target: { value } }) => setEmail(value)}
                        value={email}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={({ target: { value } }) => setPassword(value)}
                        value={password}
                      />
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading ? true : false}
                    >
                      {loading ? "Validating" : "Submit"}
                    </Button>
                  </Form>
                </div>
              )}
            </Fragment>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RapnetModal;
