import { ClickAwayListener, Popper } from "@mui/material";
import { MessageSquare } from "lucide-react";
import React from "react";
import PopupState, {
  bindTrigger,
  bindPopover,
  bindHover,
} from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { cn, convertTimestampToReadableDate2 } from "../../../utils/utils";
import { useState } from "react";
import amplitude from "../../../utils/ampli";
import { FaCommentAlt  } from "react-icons/fa";

const Comment = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMouseOver = (event) => {
    event.stopPropagation();
    // console.log("event");
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMouseOut = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <PopupState variant="popover" popupId="comment-popover">
        {(popupState) => (
          <div>
            <div {...bindHover(popupState)}>
              <FaCommentAlt height={"10px"} />
            </div>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="bg-transparent  border border-[#cbcbcb]">
                <div className="p-1 rounded-lg bg-white ">
                  <div className="h-full bg-[#F3F3F3] rounded-lg min-w-[180px] min-h-[200px] max-h-[250px] overflow-y-auto">
                    {data ? (
                      <div className="max-h-[350px] overflow-y-auto px-2 py-1">
                        {data.map((item) => {
                          if (!item.is_repricing_comment) {
                            return (
                              <>
                                <div className="text-[0.55rem] font-base my-1 text-[#9A9A9A] italic">
                                  @{item.user_name} {" : "}
                                  {convertTimestampToReadableDate2(item.date)}
                                </div>

                                <div className="text-[0.66rem] font-base my-1 mb-2 text-[#5A5A5A] italic">
                                  {item.comment}
                                </div>
                              </>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </HoverPopover>
          </div>
        )}
      </PopupState>
    </>
  );
};

export default Comment;
