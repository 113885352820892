import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  userDetails: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetailsState: (state, action) => {
      state.userDetails = action.payload.details;
      state.token = action.payload.token;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserDetailsState } = authSlice.actions;
export const auth = (state) => state.auth;

export default authSlice.reducer;
