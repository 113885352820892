import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAnchorData } from "../../redux/slices/pricingSlice";
import PricingPopOver from "../pricingPopOver";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { cn, formatter } from "../../utils/utils";
import amplitude from "../../utils/ampli";

const Repricing = ({
  getValue,
  row,
  column,
  table,
  save,
  value,
  fieldName,
  loader,
  isFormatted,
  // inputValue,
  // setInputValue,
}) => {
  const [anchor, setAnchor] = useState(null);
  const anchorData = useSelector((state) => state.pricing.anchorData);

  const dispatch = useDispatch();

  const { selectedField } = table.getState();

  const data = {
    value: getValue,
    row: row.original,
    rowIndex: row.index,
    columnId: column.id,
    table,
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchor({
      element: event.currentTarget,
      data: data,
    });

    // amplitude events.
    const buttonClickedEvent = {
      event_type: `v2_pricetable_repriceOpen_${fieldName}`,
      event_properties: {
        LD_ID: row.original["LD ID"],
      },
    };
    amplitude.track(buttonClickedEvent);

    table.setState((old) => {
      // console.log(old);
      return {
        ...old,
        selectedField: {
          columnId: column.id,
          rowIndex: row.index,
        },
      };
    });

    dispatch(setAnchorData(getValue));
  };

  const handleClose = (ev) => {
    ev.stopPropagation();

    table.setState((old) => {
      // console.log(old);
      return {
        ...old,
        selectedField: {
          columnId: null,
          rowIndex: null,
        },
      };
    });

    setAnchor(null);
    dispatch(setAnchorData(null));
  };

  const handleSave = async (ev, comment) => {
    ev.stopPropagation();
    // handleClose(ev);
    await save(ev, comment);
  };

  // const calculateOriginalPrices()
  const calculateOriginalPrices = (row) => {
    let originalTotalPriceCents = row.original["originalTotalPrice"]
    let originalTotalPrice = Math.round(originalTotalPriceCents/100)
    let rapPrice = row.original["Rap $/Ct"]
    let caratStr = row.original["Carat"]
    let caratFloat = parseFloat(caratStr)
    let originalPPC = Math.round(originalTotalPrice/caratFloat);
    let originalRap = ((originalPPC - rapPrice)*100/rapPrice).toFixed(2);
    return [
      originalTotalPrice,
      originalPPC,
      originalRap
    ]
  };

  const open =
    Boolean(anchor?.element) &&
    selectedField?.columnId === column.id &&
    selectedField?.rowIndex === row.index;

  // Calculation of Change in Price
  let use_price = null;
  const [originalTotalPrice, originalPPC, originalRap] = calculateOriginalPrices(row);
  if (fieldName === 'Rap %') {
    use_price = (row.original["Rap %"] - originalRap).toFixed(2);
  } else if (fieldName === '$/Ct') {
    use_price = (row.original["$/Ct"] - originalPPC).toFixed();
  } else {
    use_price = (row.original["Total Price"] - originalTotalPrice).toFixed();
  }
  const isNegative = use_price < 0;


  return (
    <>
      <PricingPopOver
        open={open}
        handleClose={handleClose}
        anchorEl={anchor?.element}
        data={data}
        anchorData={anchorData}
        fieldName={fieldName}
        handleSave={handleSave}
        loader={loader}
        key={row["LD ID"]}
        // inputValue={inputValue}
        // setInputValue={setInputValue}
      />

      <div
        onClick={handleClick}
        className={cn(
          "border-1 border-solid border-[#EAEAEA] rounded-xl px-1 py-2 w-[70px] text-center overflow-visible cursor-pointer relative",
          open ? "border-green-400" : "border-[#EAEAEA]"
        )}
      >
        {row.original["State"] === "repriced" ? (
          <>
            <div className="absolute top-[-7px] left-1/2 transform -translate-x-1/2 bg-white bg-opacity-[0.5] rounded-xl border-solid border-1 px-1 text-[9px]" style={{borderColor: isNegative ? 'red' : 'green'}}>
              {isNegative ? (
                <FaCaretDown
                  size={12}
                  color="red"
                  enableBackground={"white"}
                />
              ) : (
                <FaCaretUp
                  size={12}
                  color="green"
                  enableBackground={"white"}
                />
              )}
              <span style={{ color: isNegative ? 'red' : 'green' }}>
                {use_price}
              </span>
            </div>
          </>
        ) : null}

        <div className="overflow-hidden">
          {isFormatted
            ? formatter.format(Math.round(value))
            : parseFloat(value).toFixed(2)}
        </div>
      </div>
    </>
  );
};

export default Repricing;
