import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const findInsertionIndex = (array, value) => {
  let low = 0;
  let high = array.length;
  let rank = 0;

  // while (low < high) {
  //     const mid = Math.floor((low + high) / 2);

  //     if (array[mid] <= value) {
  //         rank = mid + 1;
  //         low = mid + 1;
  //     } else {
  //         high = mid;
  //     }
  // }
  let rankDiscovered = false;
  while (!rankDiscovered){
    const mid = array[low]
    if(mid >= value || low == high){
      rank = (low+1); // As index starts from 0
      rankDiscovered = true;
    }
    low += 1;
  }
  return rank;
};

export const getValuesAtIndexes = (arr) => {
  const indexes = [0, 4, 9, 24, 49, 99];
  const values = [];

  for (const index of indexes) {
    if (index >= arr.length) {
      values.push("");
    } else {
      values.push(parseFloat(arr[index]["Rap %"]).toFixed(1));
    }
  }

  return values;
};

export const filterValuesBetween = (array, min, max) => {
  return array.filter((value) => value >= min && value <= max);
};

export const isValueInRange = (value, min, max) => {
  return value >= min && value <= max;
};

export const filterValues = (obj, minL, maxL, minW, maxW, minH, maxH) => {
  const condition =
    (minL === "" || obj.L >= minL) &&
    (maxL === "" || obj.L <= maxL) &&
    (minW === "" || obj.W >= minW) &&
    (maxW === "" || obj.W <= maxW) &&
    (minH === "" || obj.H >= minH) &&
    (maxH === "" || obj.H <= maxH);

  return condition;
};

const currentHeaders = [
  "Location",
  "Carat",
  "Rap %",
  "$/Ct",
  "Total Price",
  "Color",
  "Clarity",
  "Cut",
  "Polish",
  "Sym",
  "Fluor",
  "Shade",
  "Measurements",
  "Table %",
  "Depth %",
  "Ratio",
  "Milky",
  "BIC",
  "Key To Symbol",
];

const rapHeaders = [
  "country",
  "carat",
  "Rap%",
  "price_per_carat",
  "total_price",
  "color",
  "clarity",
  "cut",
  "polish",
  "symmetry",
  "fluor",
  "shade",

  "Measurements",
  "table %",
  "depth %",
  "ratio",
  "milky",
  "BIC",
  "key to symbols",
];

export const checkForPresenceIncludingZero = (value) => {
  let isValid = false;
  if (value !== undefined && value !== null && !isNaN(value) && value !== '') {
    isValid = true
  }
  return isValid;
}

export const comparisonGenerator = (obj1, obj2) => {
  const x = [];

  // Assuming you have a valid definition for the `currentHeaders` and `rapHeaders` arrays

  for (let i = 0; i < rapHeaders.length; i++) {
    let value1 = obj1[currentHeaders[i]] ?? [];
    let value2 = obj2[rapHeaders[i]] ?? [];

    if (currentHeaders[i] == "Key To Symbol") {
      value1 = obj1[currentHeaders[i]]
        ? obj1[currentHeaders[i]].split(",").map((item) => item.trim())
        : [];
      value2 = obj2[rapHeaders[i]]
        ? obj2[rapHeaders[i]].split(",").map((item) => item.trim())
        : [];
    }

    if (currentHeaders[i] == "Measurements") {
      const length = obj2["length"] ?? 0;
      const width = obj2["width"] ?? 0;
      const height = obj2["height"] ?? 0;
      value2 = `${length} x ${width} x ${height}`;
    }

    if (currentHeaders[i] == "Ratio") {
      const length = obj2["length"] ?? 1; // Assuming a default non-zero value
      const width = obj2["width"] ?? 1; // Assuming a default non-zero value
      value2 = width !== 0 ? (length / width).toFixed(2) : "N/A";
    }

    const header =
      currentHeaders[i] == "Total Price" ? "Amount" : currentHeaders[i];
    const d = {
      header,
      value1,
      value2,
    };
    x.push(d);
  }

  return x;
};

export const comparisonGenerator2 = (obj1, obj2) => {
  const x = [];

  // Assuming you have a valid definition for the `currentHeaders` and `rapHeaders` arrays

  for (let i = 0; i < currentHeaders.length; i++) {
    let value1 = obj1[currentHeaders[i]] ?? [];
    let value2 = obj2[currentHeaders[i]] ?? [];

    if (currentHeaders[i] == "Key To Symbol") {
      value1 = obj1[currentHeaders[i]]
        ? obj1[currentHeaders[i]].split(",").map((item) => item.trim())
        : [];
      value2 = obj2[currentHeaders[i]]
        ? obj2[currentHeaders[i]].split(",").map((item) => item.trim())
        : [];
    }

    if (currentHeaders[i] == "Measurements") {
      const length = obj2["length"] ?? 0;
      const width = obj2["width"] ?? 0;
      const height = obj2["depth"] ?? 0;
      value2 = `${length} x ${width} x ${height}`;
    }

    if (currentHeaders[i] == "Ratio") {
      const length = obj2["length"] ?? 1; // Assuming a default non-zero value
      const width = obj2["width"] ?? 1; // Assuming a default non-zero value
      value2 = width !== 0 ? (length / width).toFixed(2) : "N/A";
    }

    const header =
      currentHeaders[i] == "Total Price" ? "Amount" : currentHeaders[i];
    const d = {
      header,
      value1,
      value2,
    };
    x.push(d);
  }

  return x;
};

export const getMinMaxOfMeasureMentData = (data, value) => {
  let valueMap = {
    length: 0,
    width: 1,
    height: 2,
  };

  if (data) {
    let minA = Infinity; // Initialize with a large value
    let maxA = -Infinity; // Initialize with a small value

    for (const obj of data) {
      let aValue;
      // Convert to number if necessary

      if (obj["Measurements"]) {
        aValue = parseFloat(
          obj["Measurements"].split("x").map((el) => el.trim())[valueMap[value]]
        );
      } else {
        let x = `${obj["dim_l"]} x ${obj["dim_w"]} x ${obj["dim_d"]}`;

        aValue = parseFloat(
          x.split("x").map((el) => el.trim())[valueMap[value]]
        );
      }

      if (!isNaN(aValue)) {
        if (aValue < minA) {
          minA = aValue;
        }

        if (aValue > maxA) {
          maxA = aValue;
        }
      }
    }

    if (minA != Infinity || maxA != -Infinity) {
      return [minA, maxA];
    }

    return [0, 0];
  }
};

export const getMinMaxOfData = (data, value) => {
  if (data) {
    let minA = Infinity; // Initialize with a large value
    let maxA = -Infinity; // Initialize with a small value

    for (const obj of data) {
      const aValue = parseFloat(obj[value]); // Convert to number if necessary

      if (!isNaN(aValue)) {
        if (aValue < minA) {
          minA = aValue;
        }

        if (aValue > maxA) {
          maxA = aValue;
        }
      }
    }

    if (minA != Infinity || maxA != -Infinity) {
      return [minA, maxA];
    }

    return [0, 0];
  }
};

export const getRatioMinMaxOfData = (data) => {
  if (data) {
    let minA = Infinity; // Initialize with a large value
    let maxA = -Infinity; // Initialize with a small value

    for (const obj of data) {
      const aValue = parseFloat(obj["length"] / obj["width"]).toFixed(2); // Convert to number if necessary

      if (!isNaN(aValue)) {
        if (aValue < minA) {
          minA = aValue;
        }

        if (aValue > maxA) {
          maxA = aValue;
        }
      }
    }

    if (minA != Infinity || maxA != -Infinity) {
      return [minA, maxA];
    }

    return [0, 0];
  }
};

export const getCaratFromtoFromRange = (range) =>{
  if(typeof range == "string"){
    return range.split("-").map((i) => parseFloat(i))
  }
  return [0.01, 99.0]
};

export const getKTSWithCount = (data) => {
  if (data) {
    const ktsCountMap = new Map();

    data.forEach((obj) => {
      if (obj["Key To Symbol"]) {
        const kts = obj["Key To Symbol"].split(",");

        kts.forEach((value) => {
          const lowercaseValue = value.toLowerCase();
          if (ktsCountMap.has(lowercaseValue)) {
            ktsCountMap.set(
              lowercaseValue,
              ktsCountMap.get(lowercaseValue) + 1
            );
          } else {
            ktsCountMap.set(lowercaseValue, 1);
          }
        });
      }
    });

    const ktsValuesArray = [];

    ktsCountMap.forEach((count, value) => {
      ktsValuesArray.push({ value, count });
    });

    return ktsValuesArray;
  }
};

export const getShapesWithCount = (data) => {
  if (data) {
    const shapeCountMap = new Map();

    data.forEach((obj) => {
      if (obj["Shape"]) {
        const shape = obj["Shape"].split(",");

        shape.forEach((value) => {
          const lowercaseValue = value.toLowerCase();
          if (shapeCountMap.has(lowercaseValue)) {
            shapeCountMap.set(
              lowercaseValue,
              shapeCountMap.get(lowercaseValue) + 1
            );
          } else {
            shapeCountMap.set(lowercaseValue, 1);
          }
        });
      }
    });

    const shapeValuesArray = [];

    shapeCountMap.forEach((count, value) => {
      shapeValuesArray.push({ value, count });
    });

    return shapeValuesArray;
  }
};

// export const getLabWithCount = (data) => {
//   if (data) {
//     const labCountMap = new Map();

//     data.forEach((obj) => {
//       if (obj["Lab"]) {
//         const lab = obj["Lab"].split(",");

//         lab.forEach((value) => {
//           const lowercaseValue = value.toLowerCase();
//           if (labCountMap.has(lowercaseValue)) {
//             labCountMap.set(
//               lowercaseValue,
//               labCountMap.get(lowercaseValue) + 1
//             );
//           } else {
//             labCountMap.set(lowercaseValue, 1);
//           }
//         });
//       }
//     });

//     const labValuesArray = [];

//     labCountMap.forEach((count, value) => {
//       labValuesArray.push({ value, count });
//     });

//     return labValuesArray;
//   }
// };

export const getLabWithCount = (data) => {
  if (data) {
    const labCountMap = new Map();

    data.forEach((obj) => {
      let lab = obj["Lab"];
      if (
        lab === "" ||
        lab === "NA" ||
        lab === "N/A" ||
        lab === null ||
        lab === undefined ||
        lab == "none" ||
        lab == "NONE"
      ) {
        lab = "NA";
      } else {
        lab = lab.toLowerCase();
      }


      if (labCountMap.has(lab)) {
        labCountMap.set(lab, labCountMap.get(lab) + 1);
      } else {
        labCountMap.set(lab, 1);
      }
    });

    const labValuesArray = [];

    labCountMap.forEach((count, lab) => {
      labValuesArray.push({ value: lab, count });
    });

    return labValuesArray;
  }
};

export const getLocationsWithCount = (data) => {
  if (data) {
    const locationCountMap = new Map();
    
    data.forEach((obj) => {
      let location = obj["Location"];
      if (
        location === "" ||
        location === "NA" ||
        location === "N/A" ||
        location === null ||
        location === undefined
      ) {
        location = "NA";
      } else {
        location = location.toLowerCase();
      }

      if (locationCountMap.has(location)) {
        locationCountMap.set(location, locationCountMap.get(location) + 1);
      } else {
        locationCountMap.set(location, 1);
      }
    });

    const locationArray = [];

    locationCountMap.forEach((count, location) => {
      locationArray.push({ value: location, count });
    });

    return locationArray;
  }
};

export const createUniqueSet = (marketFilters, data, property) => {
  if(marketFilters[property] != undefined){
    // if any filter is found then we show all the values from end to end
    // "-" for Color, Clarity, Cut, Polish and Symmetry
    //    we will need full set to fill up values as per our mapped values
    // ", " separated for Fluor, Shade and Milky
    //    just separate them by ", " and map them to our values
    let filterKeys = [];
    if(marketFilters[property].includes("-")){
      filterKeys = convertFromToStrToArray(property, marketFilters[property]);
    }else if (marketFilters[property].includes(", ")){
      filterKeys = marketFilters[property].split(", ");
    }
    if(property == "Fluor"){
      filterKeys = filterKeys.map((flr) => {
        return fluorValues[flr.toLowerCase()]
      }); 
    }else if(property == "Cut" || property == "Polish" || property == "Symmetry"){
      filterKeys = filterKeys.map((k) => {
        return cutPolSymValues[k]
      });
      filterKeys = Array.from(new Set(filterKeys))
    }else if(property == "Shade"){

    }else if(property == "Milky"){

    }
    // console.log(property," from filterset - ",  filterKeys)
    return filterKeys;
  }
  if (data) {
    const uniqueSet = new Set();

    data.forEach((obj) => {
      let value = obj[property];

      if (
        value === "" ||
        value === "NA" ||
        value === "N/A" ||
        value === null ||
        value === undefined
      ) {
        value = "NA";
      } else {
        value = value.toLowerCase();
      }

      uniqueSet.add(value);
    });
    console.log(property," from data - ",  Array.from(uniqueSet))
    return Array.from(uniqueSet);
  }
};

export const findCommonElements = (array1, array2) => {
  return array1.filter((element) => array2.includes(element));
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const capitalizeWords = (inputString) => {
  return inputString.replace(/\b\w/g, (match) => match.toUpperCase());
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  let daySuffix;
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  } else {
    daySuffix = "th";
  }

  return `${day}${daySuffix} of ${monthNames[month]} ${year}`;
};

// export const colorPalette = new Map([
//   ["bearded girdle", { foreground: "#FFD700", background: "#FFD7004D" }], // Bright Yellow
//   ["brown patch of color", { foreground: "#FF6347", background: "#FF63474D" }], // Tomato Red
//   ["bruise", { foreground: "#FF69B4", background: "#FF69B44D" }], // Hot Pink
//   ["cavity", { foreground: "#00CED1", background: "#00CED14D" }], // Dark Turquoise
//   ["chip", { foreground: "#FFA500", background: "#FFA5004D" }], // Orange
//   ["cloud", { foreground: "#87CEEB", background: "#87CEEB4D" }], // Sky Blue
//   ["crystal", { foreground: "#00FF7F", background: "#00FF7F4D" }], // Spring Green
//   ["etch channel", { foreground: "#9932CC", background: "#9932CC4D" }], // Dark Orchid
//   ["extra facet", { foreground: "#32CD32", background: "#32CD324D" }], // Lime Green
//   ["feather", { foreground: "#FF1493", background: "#FF14934D" }], // Deep Pink
//   ["grain center", { foreground: "#00BFFF", background: "#00BFFF4D" }], // Deep Sky Blue
//   ["indented natural", { foreground: "#FF4500", background: "#FF45004D" }], // Orange Red
//   ["internal graining", { foreground: "#FFD700", background: "#FFD7004D" }], // Bright Yellow
//   ["knot", { foreground: "#8A2BE2", background: "#8A2BE24D" }], // Blue Violet
//   ["laser drilling", { foreground: "#20B2AA", background: "#20B2AA4D" }], // Light Sea Green
//   ["manufacturing remnant", { foreground: "#FFA07A", background: "#FFA07A4D" }], // Light Salmon
//   [
//     "minor details of polish",
//     { foreground: "#BA55D3", background: "#BA55D34D" },
//   ], // Medium Orchid
//   ["natural", { foreground: "#228B22", background: "#228B224D" }], // Forest Green
//   ["needle", { foreground: "#00FFFF", background: "#00FFFF4D" }], // Aqua
//   ["pinpoint", { foreground: "#1E90FF", background: "#1E90FF4D" }], // Dodger Blue
//   ["reflecting", { foreground: "#4682B4", background: "#4682B44D" }], // Steel Blue
//   ["surface graining", { foreground: "#FF8C00", background: "#FF8C004D" }], // Dark Orange
//   ["twinning wisp", { foreground: "#8B008B", background: "#8B008B4D" }],
// ]);

export const colorPalette = new Map([
  ["bearded girdle", { foreground: "#A52137", background: "#A521374D" }],
  ["brown patch of color", { foreground: "#C22842", background: "#C228424D" }],
  ["bruise", { foreground: "#D92E4A", background: "#D92E4A4D" }],
  ["cavity", { foreground: "#0D5E9B", background: "#0D5E9B4D" }],
  ["chip", { foreground: "#2872A9", background: "#2872A94D" }],
  ["cloud", { foreground: "#3A7DAE", background: "#3A7DAE4D" }],
  ["crystal", { foreground: "#6B19A1", background: "#6B19A14D" }],
  ["etch channel", { foreground: "#8334B7", background: "#8334B74D" }],
  ["extra facet", { foreground: "#9855C4", background: "#9855C44D" }],
  ["feather", { foreground: "#355832", background: "#3558324D" }],
  ["grain center", { foreground: "#557652", background: "#5576524D" }],
  ["indented natural", { foreground: "#457241", background: "#4572414D" }],
  ["internal graining", { foreground: "#B06424", background: "#B064244D" }],
  ["knot", { foreground: "#BE773C", background: "#BE773C4D" }],
  ["laser drilling", { foreground: "#CB742B", background: "#CB742B4D" }],
  ["manufacturing remnant", { foreground: "#B28D08", background: "#B28D084D" }],
  [
    "minor details of polish",
    { foreground: "#BD9A1E", background: "#BD9A1E4D" },
  ],
  ["natural", { foreground: "#C29E1E", background: "#C29E1E4D" }],
  ["needle", { foreground: "#821876", background: "#8218764D" }],
  ["pinpoint", { foreground: "#A41E95", background: "#A41E954D" }],
  ["reflecting", { foreground: "#AC2C9D", background: "#AC2C9D4D" }],
  ["surface graining", { foreground: "#B41B36", background: "#B41B364D" }],
  ["twinning wisp", { foreground: "#BE1E3B", background: "#BE1E3B4D" }],
  ["missing code", { foreground: "#CF3C56", background: "#CF3C564D" }],
]);
export const KTSMap = {
  beardedgirdle: "B Girdle",
  brownpatchofcolor: "Brown POC",
  etchchannel: "Etch C",
  extrafacet: "Extra F",
  graincenter: "Grain C",
  indentednatural: "Ind Nat",
  internalgraining: "Int Grain",
  laserdrilling: "Laser D",
  manufacturingremnant: "Mfg Rem",
  minordetailsofpolish: "Minor DOP",
  surfacegraining: "Surf Grain",
  twinningwisp: "Twinning W",
};

// export const filterOrdering = (a, b) => {
//   if (!a || !b) return b || [];

//   // Create a map to store the indices of lowercase elements in array 'a'
//   const indexMap = new Map();
//   a.forEach((item, index) => {
//     indexMap.set(item.toLowerCase(), index);
//   });

//   // Sort array 'b' based on the indices from lowercase elements in array 'a'
//   b.sort((item1, item2) => {
//     const lowerItem1 = item1.toLowerCase();
//     const lowerItem2 = item2.toLowerCase();
//     const index1 = indexMap.has(lowerItem1) ? indexMap.get(lowerItem1) : -1;
//     const index2 = indexMap.has(lowerItem2) ? indexMap.get(lowerItem2) : -1;
//     return index1 - index2; // Reverse the comparison
//   });

//   return b;
// };

export const filterOrdering = (a, b) => {
  if (!a || !b) return b || [];

  // Create a map to store the indices of lowercase elements in array 'a'
  const indexMap = new Map();
  a.forEach((item, index) => {
    indexMap.set(item.toLowerCase(), index);
  });

  // Sort array 'b' based on the indices from lowercase elements in array 'a'
  b.sort((item1, item2) => {
    const lowerItem1 = item1.toLowerCase();
    const lowerItem2 = item2.toLowerCase();
    const index1 = indexMap.has(lowerItem1) ? indexMap.get(lowerItem1) : -1;
    const index2 = indexMap.has(lowerItem2) ? indexMap.get(lowerItem2) : -1;
    return index1 - index2; // Reverse the comparison
  });

  return b;
};

export const diamondAttributes = {
  // cut: ["ID", "EX", "VG", "GD", "FR", "PR", "NA"],
  // polish: ["EX", "VG", "GD", "FR", "PR", "NA"],
  // sym: ["EX", "VG", "GD", "FR", "PR", "NA"],
  cut: ["EX", "VG", "G", "F", "P", "NA"],
  polish: ["EX", "VG", "G", "F", "P", "NA"],
  sym: ["EX", "VG", "G", "F", "P", "NA"],
  clarity: [
    "FL",
    "IF",
    "VVS1",
    "VVS2",
    "VS1",
    "VS2",
    "SI1",
    "SI2",
    "SI3",
    "I1",
    "I2",
    "I3",
    "NA",
  ],
  milky: ["None", "Slightly Milky", "Moderately Milky", "Very Milky", "NA"],
  color: [
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "NA",
  ],
  shade: ["Light", "Medium", "Dark", "None", "NA"],
  fluor: ["N", "F", "M", "S", "VS", "NA"],
};

export const transformObject = (obj, at) => {
  const result = [{}, {}];

  const l2 =
    at == 0
      ? "Inventory Diamond"
      : at == 1
      ? "Market Diamonds"
      : "Competition Diamond";

  // for (const item of obj) {
  //   const { header, value1, value2 } = item;
  //   result[0][header] = value1;
  //   result[1][header] = value2;
  // }

  obj.map((item, idx) => {
    if (idx == 0) {
      result[0]["Diamond"] = "My Diamond";
      result[1]["Diamond"] = l2;
    } else {
      const { header, value1, value2 } = item;
      result[0][header] = value1;
      result[1][header] = value2;
    }
  });

  return result;
};

export const mergeTableData = (inputObject) => {
  let mapping;

  if (inputObject.hasOwnProperty("total_price")) {
    // Use mapping for "obj a"
    mapping = {
      "Stock No": "Stock No",
      Shape: "shape",
      Carat: "carat",
      Color: "color",
      Clarity: "clarity",
      Cut: "cut",
      Polish: "polish",
      Sym: "symmetry",
      Lab: "lab",
      "Rap($/Ct)": "Rap($/Ct)",
      "Rap %": "Rap%",
      "Rap Total": "Rap Total",
      "$/Ct": "price_per_carat",
      "Total Price": "total_price",
      Fluor: "fluor",
      Shade: "shade",
      Milky: "milky",
      Table: "table %",
      Depth: "depth %",
      "Cert Num": "certificate #",
      Girdle: "girdle min",
      "Girdle Con": "girdle condition",
      Culet: "culet size",
      "Eye Clean": "eyeclean",
      BIC: "BIC",
      BIS: "BIS",
      WIC: "WIC",
      WIS: "WIS",
      OT: "OT",
      OC: "OC",
      OP: "OP",
      "Video Url": "videolink",
      Measurements: ["width", "length", "height"],
      "Report Date": "report date",
      "Cert Comment": "cert comment",
      "Member Comment": "member comment",
      "Key To Symbol": "key to symbols",
      Cert: "certificate #",
      Location: "country",
      Supplier: "Stock No",
      Cutlet: "cutlet size",
      CrAng: "CA",
      CrHeight: "CH",
      PavAng: "PA",
      PavDepth: "PD",
      OT: "OT",
      OC: "OC",
      OP: "OP",
      "Flr Clr": "fluor color",
    };
  } else {
    // Use mapping for "obj b"
    mapping = {
      "Stock No": "Stock Num",
      Shape: "Shape",
      Carat: "Carats",
      Color: "Color",
      Clarity: "Clarity",
      Cut: "Cut",
      Polish: "Polish",
      Sym: "Sym",
      Lab: "Lab",
      "Rap $/Ct": "Rap $/Ct",
      "Rap %": "Rap %",
      "Rap Total": "Rap Total",
      "$/Ct": "$/Ct",
      "Total Price": "Total Price",
      Fluor: "Fluor",
      Shade: "Shade",
      Milky: "Milky",
      "Table %": "Table",
      "Depth %": "Depth",
      "Cert Num": "Cert Num",
      Girdle: "Girdle",
      "Girdle Con": "Girdle Con",
      Culet: "Culet",
      "Eye Clean": "Eye Clean",
      BIC: "BIC",
      BIS: "BIS",
      WIC: "WIC",
      WIS: "WIS",
      OT: "OT",
      OC: "OC",
      OP: "OP",
      "Video Url": "Video Url",
      Measurements: "Measurements",
      "Report Date": "Report Date",
      "Cert Comment": "Cert Comment",
      "Member Comment": "Member Comment",
      "Key To Symbol": "Key To Symbol",
      Cert: "Cert Num",
      Location: "Location",
      Supplier: "",
      Cutlet: "Culet",
      CrAng: "CrAng",
      CrHeight: "CrHeight",
      PavAng: "PavAng",
      PavDepth: "PavAng",
      OT: "OT",
      OC: "OC",
      OP: "OP",

      "Flr Clr": "Flr Clr",
    };
  }

  const outputObject = {};

  for (const key in mapping) {
    const inputKey = mapping[key];
    const value = inputObject[inputKey];

    // Check if the value is a string containing commas
    if (key == "Measurements" && inputObject.hasOwnProperty("width")) {
      outputObject[
        key
      ] = `${inputObject["length"]} x ${inputObject["width"]} x ${inputObject["height"]} `;
    } else if (key == "Rap Total" && !inputObject.hasOwnProperty("width")) {
      outputObject[key] = inputObject["Rap $/Ct"] * inputObject["Carats"];
    } else if (key == "Supplier") {
      if (inputObject["Stock No"]) {
        outputObject[key] = inputObject["Stock No"].split("|")[0];
      }
      outputObject[key] = inputObject[""];
    } else {
      outputObject[key] = value;
    }
  }

  return outputObject;
};

export function cn(...inputs) {
  return twMerge(clsx(...inputs));
}

export const AlignMultipleTables = (class_name, setLoader) => {
  // Align tables with the specified class so that all columns line up across tables.

  // Find the max width of each column across all tables. Only look at 'th' or 'td' cells in the first row of each table.
  var col_widths = [];

  var tables = document.getElementsByClassName(class_name);
  for (var i = 0; i < tables.length; i++) {
    var table = tables[i];
    var rows = table.getElementsByTagName("tr")[0].getElementsByTagName("th");
    if (rows.length === 0) {
      rows = table.getElementsByTagName("tr")[0].getElementsByTagName("td");
    }
    for (var j = 0; j < rows.length; j++) {
      var cell = rows[j];
      col_widths[j] = Math.max(col_widths[j] || 0, cell.offsetWidth);
    }
  }

  // Set each column in each table to the max width of that column across all tables.
  tables = document.getElementsByClassName(class_name);
  for (var i = 0; i < tables.length; i++) {
    var table = tables[i];
    var rows = table.getElementsByTagName("tr")[0].getElementsByTagName("th");
    if (rows.length === 0) {
      rows = table.getElementsByTagName("tr")[0].getElementsByTagName("td");
    }
    for (var j = 0; j < rows.length; j++) {
      var cell = rows[j];
      cell.style.minWidth = col_widths[j] + "px";
    }
  }

  setLoader(false);
};

export const cutPolSymValues = {
  "ideal": "ex",
  "excellent": "ex",
  "very good": "vg",
  "good": "g",
  "fair": "f",
  "poor": "p"
}

export const fluorValues = {
  "none": "n",
  "very slight": "vsl",
  "faint / slight": "f",
  "faint": "f",
  "medium": "m",
  "strong": "s",
  "very strong": "vst"
}

// Cut
export const cutValues = {
  ex: 1, // Excellent
  vg: 2, // Very Good
  good: 3,
  g: 3, // Good
  fair: 4,
  f: 4, // Fair
  poor: 5,
  p: 5, // Poor
};

// Color (D-Z)
export const colorValues = {
  d: 1,
  e: 2,
  f: 3,
  g: 4,
  h: 5,
  i: 6,
  j: 7,
  k: 8,
  l: 9,
  m: 10,
  n: 11,
  o: 12,
  p: 13,
  q: 14,
  r: 15,
  s: 16,
  t: 17,
  u: 18,
  v: 19,
  w: 20,
  x: 21,
  y: 22,
  z: 23,
};

// Clarity
export const clarityValues = {
  fl: 1, // Flawless
  if: 2, // Internally Flawless
  vvs1: 3, // Very, Very Slightly Included (VVS1)
  vvs2: 4, // Very, Very Slightly Included (VVS2)
  vs1: 5, // Very Slightly Included (VS1)
  vs2: 6, // Very Slightly Included (VS2)
  si1: 7, // Slightly Included (SI1)
  si2: 8, // Slightly Included (SI2)
  si3: 9, // Slightly Included (SI2)
  i1: 10, // Included (I1)
  i2: 11, // Included (I2)
  i3: 12, // Included (I3)
};

// Polish
export const polishValues = {
  ex: 1, // Excellent
  vg: 2, // Very Good
  good: 3,
  g: 3, // Good
  fair: 4,
  f: 4, // Fair
  poor: 5,
  p: 5, // Poor
};

// Symmetry
export const symmetryValues = {
  ex: 1, // Excellent
  vg: 2, // Very Good
  good: 3,
  g: 3, // Good
  fair: 4,
  f: 4, // Fair
  poor: 5,
  p: 5, // Poor
};

// Fluorescence
export const fluorescenceValues = {
  none: 1,
  faint: 4,
  medium: 5,
  strong: 6,
  "very strong": 9,
  n: 1,
  vsl: 2,
  sl: 3,
  f: 4,
  m: 5,
  s: 6,
  st: 7,
  vs: 8,
  vst: 9,
};

// Flourish/Shade
export const shadeValues = {
  none: 1,
  yellow: 2,
  green: 3,
  brown: 4,
  blue: 5,
  orange: 6,
  red: 7,
};

// Milky
export const milkyValues = {
  none: 1,
  slight: 2,
  moderate: 3,
  severe: 4,
  none: 1,
  light: 2,
  medium: 3,
  heavy: 4,
};

// Black Inclusions (BIC)
export const bicValues = {
  none: 1,
  very_light: 2,
  light: 3,
  medium: 4,
  heavy: 5,
  minor: 2,
  noticeable: 3,
  obvious: 4,
  prominent: 5,
};

export const allPropMap = {
  "Color": colorValues,
  "Clarity": clarityValues,
  "Cut": {
    ideal: 1, // Ideal
    excellent: 2, // Excellent
    "very good": 3, // Very Good
    good: 4, // Good
    fair: 5, // Fair
    poor: 6, // Poor
  },
  "Polish": {
    ideal: 1, // Ideal
    excellent: 2, // Excellent
    "very good": 3, // Very Good
    good: 4, // Good
    fair: 5, // Fair
    poor: 6, // Poor
  },
  "Symmetry": {
    ideal: 1, // Ideal
    excellent: 2, // Excellent
    "very good": 3, // Very Good
    good: 4, // Good
    fair: 5, // Fair
    poor: 6, // Poor
  },
};

export const compareAttributes = (
  selectedItem,
  info,
  attributeName,
  attributeValues
) => {
  if (!selectedItem || !selectedItem[attributeName] || !info.getValue()) {
    return info.getValue();
  }

  const rank1 = attributeValues[(info.getValue() || "").toLowerCase()];
  const rank2 =
    attributeValues[(selectedItem[attributeName] || "").toLowerCase()];

  if (rank1 !== undefined && rank2 !== undefined) {
    const textColorClass =
      rank1 > rank2 ? "text-red-500" : rank1 == rank2 ? "" : "text-green-500";

    return <div className={cn(textColorClass)}>{info.getValue()}</div>;
  }

  return info.getValue();
};

export const compareAttributes2 = (
  selectedItem,
  info,
  attributeName,
  attributeValues
) => {
  if (!selectedItem || !selectedItem[attributeName] || !info[attributeName]) {
    return info[attributeName];
  }

  const rank1 = attributeValues[(info[attributeName] || "").toLowerCase()];
  const rank2 =
    attributeValues[(selectedItem[attributeName] || "").toLowerCase()];

  if (rank1 !== undefined && rank2 !== undefined) {
    const textColorClass =
      rank1 > rank2 ? "text-red-500" : rank1 == rank2 ? "" : "text-green-500";

    return <div className={cn(textColorClass)}>{info[attributeName]}</div>;
  }

  return info[attributeName];
};

export const categoryMinimizer = (categoriesArray) => {
  const keysToShow = 3;
  const extractedElements = Object.keys(categoriesArray).slice(0, 3);
  const remainingCount =
    Object.keys(categoriesArray).length - keysToShow.length;

  console.log(categoriesArray, extractedElements, remainingCount);

  // // Extract the first 5 elements
  // const extractedElements = categoriesArray.slice(0, maxElements);

  // // Calculate the count of remaining elements
  // const remainingCount = categoriesArray.length - maxElements;

  // Create a new array
  const newCategoriesArray = [
    ...extractedElements.map((item) => categoriesArray[item]),
    remainingCount > 0 ? `+${remainingCount}` : "", // Add "+ count" if there are remaining elements
  ];

  console.log({
    newCategoriesArray,
  });

  return newCategoriesArray;
};

export function filterLiquidUrls(url) {
  if (url?.includes("assets.liquid.diamonds")) {
    const urlObject = new URL(url);
    const asseturlParam = urlObject.searchParams.get("asseturl");
    return asseturlParam;
  }

  return url;
}

const convertPriceStringToInt = (priceString) => {
  if (typeof priceString == "string") {
    return parseInt(priceString.replace(/[$,]/g, ""));
  }

  return priceString;
};

export function convertTimestampToReadableDateTime(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function convertTimestampToReadableDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function convertTimestampToReadableDate2(timestamp) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  return `${day} ${month}`;
}

const generateObject = (propertyPrefix, percentiles, originalData) => {
  const resultObject = {
    rank: originalData.rap_rank,
    count: originalData.rap_matching_count,
  };

  for (const percentile of percentiles) {
    resultObject[percentile] = originalData[`${propertyPrefix}_${percentile}`];
  }

  return resultObject;
};

export function reverseAndSortByDate(jsonArray) {
  // Reverse the array
  const reversedArray = jsonArray.slice().reverse();

  // Sort the reversed array by datetime in descending order
  const sortedArray = reversedArray.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return sortedArray;
}

function convertTimestampToHumanReadable(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = date.toLocaleDateString("en-US", options);

  // Convert day to ordinal format (e.g., 1st, 2nd, 3rd, 4th)
  const day = date.getDate();
  const suffix =
    (day >= 11 && day <= 13) || day % 10 !== 1
      ? "th"
      : day % 10 === 2
      ? "nd"
      : day % 10 === 3
      ? "rd"
      : "th";

  return `${formattedDate}`;
}

export const mapping = (inputObject) => {
  let mapping;

  // console.log(inputObject);

  if (!inputObject) {
    return null;
  }

  mapping = {
    "Stock No": "stock_number",
    Shape: "shape",
    Carat: "carats",
    Color: "color",
    Clarity: "clarity",
    Cut: "cut",
    Polish: "polish",
    Sym: "symmetry",
    Lab: "cert",
    "Rap $/Ct": "rap_ppc_dollars",
    "Rap %": "rap_percent",
    "Rap Total": "Rap Total",
    "$/Ct": "price_per_carat",
    "Total Price": "sell_offer_cents", //(as price is in cents),
    Fluor: "fluor",
    Shade: "shade",
    Milky: "milky",
    "Table %": "table_percentage",
    "Depth %": "depth_percentage",
    "Cert Num": "cert_number",
    "Cert Url": "cert_url",
    Girdle: "girdle",
    "Girdle Con": "girdle_condition",
    Culet: "culet",
    "Eye Clean": "eye_clean",
    BIC: "black_in_center",
    BIS: "black_in_side",
    WIC: "white_in_center",
    WIS: "white_in_side",
    OT: "open_in_table",
    OC: "open_in_crown",
    OP: "open_in_pav",
    "Video Url": "video",
    Measurements: "dims",
    Image: "image",
    length: "dim_l",
    width: "dim_w",
    depth: "dim_d",
    Ratio: "ratio",
    "Report Date": "report_date",
    "Cert Comment": "cert_comment",
    "Cert Tags": "cert_comment_tags",
    // "Member Comment": "Member Comment",
    "Key To Symbol": "key_to_symbol",
    State: "state",
    Location: "location",
    "Flr Clr": "fluor_color",
    "LD Set": "ld_analysis_hash",
    Tag: "tag",
    "Rapnet Percentile": "cached_rapnet_data_hash",
    "Rapnet Price Array": "cached_rapnet_price_array",
    "LD ID": "item_id",
    "PRR ID": "id",
    "LD Reco Diff": "",
    Supplier: "supplier_name",
    Aging: "aging",
    Sold: "days_since_sold",
    Sold: "for_sale_to",
    itemState: "item_state",
    Sort_array: "cached_rapnet_price_array",
    Suggested_pricing: "price_recommendation_hash",
    Comment: "notes",
    Repriced_At: "repriced_datetime",
    Updated_At: "",
    Created_At: "",
    "Has Videos": "has_videos",
    "Suggested Confidence": "ld_overall_data_confidence",
    "Suggested Rap": "ld_overall_data_suggested_rap",
    Competition: "is_competition",
    "Market Label": "tag",
    "Crown Angle": "crown_angle",
    "Crown Height": "crown_height",
    "Pav Angle": "pavilion_angle",
    "Pav Depth": "pavilion_depth",
    "Carat Range": "item_carat_range",
    "Special Cut": "",
    "Rap Filter Expanded": "is_rapnet_filter_expanded",
    "Rapnet Filter Set": "rapnet_filter_sets",
    "Rank Filter Set" : "rank_filter_set",
    "Rap Rank Diff": "change_in_rap_rank", 
    "Rap Count Diff": "change_in_rap_count",
    // "Rap Cluster Count": "rap_cluster_count",
    "Inventory Count": "inventory_items_count", 
    "Sales Count": "sold_items_count",
    "Supply Count": "market_added_items_count", 
    "Demand Count": "market_sold_items_count",
    "originalTotalPrice": "original_price",
    "Is Filter Locked": "is_filter_locked",
    "Cost $/Ct": "cost_price_per_carat",
    "Buyer Name": "",
    "Internal Spec": "",
    "Memo Date": "",
    "Memo Count": "",
    "Memo Stock": "",
    "Buy Date": "",
    "Add Spec": "",
    "Custom tag": "",
    "Total Market Count": "pricing_market_item_count",
    "trackingDate": "tracking_start_date",
    "totalPrmiCount": "total_saved_prmi",
    "make": "make",
    "Added (30 D)": "last_n_days_added_items_count", 
    "Sold (30 D)": "last_n_days_sold_items_count",
    "Rank Change (30 D)": "last_n_days_rank_change", 
    "Count Change (30 D)": "last_n_days_count_change",
  };

  
  const outputObject = {};

  // TODO - 
  // We can claculate the price diff here, how much it Changes while updating the price
  // make sure it changes all the places where it needs?- inventory_table, item_details_table, comaprison_table, particular row updation just after the change
  // See example related to rap1, rap3, rap5 etc...
  // This will just add the keys in output objects and we can process it from later
  for (const key in mapping) {
    const inputKey = mapping[key];
    const value = inputObject[inputKey];

    // Check if the value is a string containing commas
    if (key == "Measurements") {
      outputObject[key] = `${inputObject["dim_l"] ?? "--"} x ${
        inputObject["dim_w"] ?? "--"
      } x ${inputObject["dim_d"] ?? "--"}`;
    } else if (key == "Video Url") {
      if (inputObject.hasOwnProperty("video")) {
        if (inputObject["video"] !== null && inputObject["video"] !== "") {
          outputObject[key] = inputObject["video"];
        } else if (
          inputObject["image"] !== null &&
          inputObject["image"] !== ""
        ) {
          outputObject[key] = inputObject["image"];
        } else {
          outputObject[key] = null;
        }
      } else {
        outputObject[key] = null;
      }
    } else if (key == "Comment") {
      if (inputObject.hasOwnProperty("notes")) {
        const list = inputObject["notes"];

        if (list && list.length > 0) {
          const comments = list.filter((item) => !item.is_repricing_comment);
          const logs = list.filter((item) => item.is_repricing_comment);

          outputObject["Comment"] = comments;
          outputObject["Logs"] = logs;
        } else {
          outputObject["Comment"] = null;
          outputObject["Logs"] = null;
        }
      } else {
        outputObject[key] = null;
      }
    } else if (key == "Rapnet Percentile") {
      if (inputObject["cached_rapnet_data_hash"]) {
        Object.entries(
          getRapnetData(inputObject["cached_rapnet_data_hash"])
        ).map(([key, value]) => {
          outputObject[key] = value;
        });
        outputObject["Rapnet Percentile"] =
          inputObject["cached_rapnet_data_hash"];

        outputObject["Rap Dist PPC"] = generateObject(
          "ppc",
          ["1st", "5th", "10th", "25th", "50th"],
          inputObject["cached_rapnet_data_hash"]
        );
        outputObject["Rap Dist Discount"] = generateObject(
          "rap_percent",
          ["1st", "5th", "10th", "25th", "50th"],
          inputObject["cached_rapnet_data_hash"]
        );
        outputObject["Rap Dist Price"] = generateObject(
          "total_price",
          ["1st", "5th", "10th", "25th", "50th"],
          inputObject["cached_rapnet_data_hash"]
        );
      }
    } else if (key == "Special Cut") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["special_cut"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["special_cut"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["special_cut"]){
        outputObject[key] = inputObject["custom_data"]["special_cut"];
      }
    } else if (key == "Internal Spec") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["internal_grading"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["internal_grading"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["internal_grading"]){
        outputObject[key] = inputObject["custom_data"]["internal_grading"];
      }
    } else if (key == "Buyer Name") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["buyer_name"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["buyer_name"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["buyer_name"]){
        outputObject[key] = inputObject["custom_data"]["buyer_name"];
      }
    // Columns added for Amipi
    // cost_price_per_carat, memo_date, memo_count, memo_stock, buyer_name, purchase_date, internal_grading, additional_specs, tag are related to the pricing co pilot - to show clients specific data
    // Memo Date, Memo Count, Memo Stock, Buy Date, Add Spec, Custom tag
    } else if (key == "Memo Date") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["memo_date"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["memo_date"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["memo_date"]){
        outputObject[key] = inputObject["custom_data"]["memo_date"];
      }
    } else if (key == "Memo Count") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["memo_count"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["memo_count"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["memo_count"]){
        outputObject[key] = inputObject["custom_data"]["memo_count"];
      }
    } else if (key == "Memo Stock") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["memo_stock"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["memo_stock"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["memo_stock"]){
        outputObject[key] = inputObject["custom_data"]["memo_stock"];
      }
    } else if (key == "Buy Date") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["purchase_date"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["purchase_date"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["purchase_date"]){
        outputObject[key] = inputObject["custom_data"]["purchase_date"];
      }
    } else if (key == "Add Spec") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["additional_specs"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["additional_specs"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["additional_specs"]){
        outputObject[key] = inputObject["custom_data"]["additional_specs"];
      }
    } else if (key == "Custom tag") {
      if (
        inputObject["custom_data_hash"] &&
        inputObject["custom_data_hash"]["tag"]
      ) {
        outputObject[key] = inputObject["custom_data_hash"]["tag"];
      }else if (inputObject["custom_data"] && inputObject["custom_data"]["tag"]){
        outputObject[key] = inputObject["custom_data"]["tag"];
      }
    } else if (key == "Rap Total") {
      if (inputObject["rap_ppc_dollars"]) {
        outputObject[key] =
          convertPriceStringToInt(inputObject["rap_ppc_dollars"]) *
          inputObject["carats"];
      } else if (inputObject["rap_price_per_ct"]) {
        outputObject[key] =
          convertPriceStringToInt(inputObject["rap_price_per_ct"]) *
          inputObject["carats"];
      } else {
        outputObject[key] = "";
      }
    } else if (key == "Updated_At") {
      if (inputObject["updated_at"]) {
        outputObject[key] = convertTimestampToReadableDate(
          inputObject["updated_at"]
        );
      } else {
        outputObject[key] = "";
      }
    } else if (key == "Created_At") {
      if (inputObject["created_at"]) {
        outputObject[key] = convertTimestampToReadableDate(
          inputObject["created_at"]
        );
      } else {
        outputObject[key] = "";
      }
    } else if (key == "trackingDate") {
      if (inputObject["tracking_start_date"]) {
        outputObject[key] = convertTimestampToReadableDateTime(
          inputObject["tracking_start_date"]
        );
      } else {
        outputObject[key] = "";
      }
    }else if (key == "totalPrmiCount"){
      if (inputObject["total_saved_prmi"]) {
        outputObject[key] = inputObject["total_saved_prmi"]
      } else {
        outputObject[key] = "";
      }
    } else if (key == "LD Reco Diff") {
      if (inputObject["ld_analysis_hash"]) {
        outputObject[key] = inputObject["ld_analysis_hash"]["rap_diff"];
        outputObject["Reco Rap"] = inputObject["ld_analysis_hash"]["use_rap"];
        outputObject["Reco PPC"] = inputObject["ld_analysis_hash"]["use_price"];
      } else {
        outputObject[key] = "--";
      }
    } else if (key == "Total Price") {
      if (inputObject["sell_offer_cents"]) {
        outputObject[key] = inputObject["sell_offer_cents"];
      } else if (inputObject["total_price"]) {
        outputObject[key] = inputObject["total_price"];
      }
    } else if (key == "Rap $/Ct") {
      if (inputObject["rap_ppc_dollars"]) {
        outputObject[key] = convertPriceStringToInt(
          inputObject["rap_ppc_dollars"]
        );
      } else if (inputObject["rap_price_per_ct"]) {
        outputObject[key] = convertPriceStringToInt(
          inputObject["rap_price_per_ct"]
        );
      } else {
        outputObject[key] = "";
      }
    } else if (key == "Rap %") {
      if (inputObject["rap_percent"]) {
        outputObject[key] = inputObject["rap_percent"];
      } else if (inputObject["rap_discount"]) {
        outputObject[key] = inputObject["rap_discount"];
      } else {
        outputObject[key] = "";
      }
    } else if (key == "LD Set" && inputObject["ld_analysis_hash"]) {
      // console.log(inputObject);
      outputObject["LD Spec"] = inputObject["ld_analysis_hash"]["ld_spec"];
      outputObject["LD Rank"] = inputObject["ld_analysis_hash"]["ld_rank"];
      outputObject["LD Count"] =
        inputObject["ld_analysis_hash"]["ld_matching_count"];
      outputObject["LD Reco"] = inputObject["ld_analysis_hash"]["use_rap"];
      outputObject["LD Rank Percentage"] =
        inputObject["ld_analysis_hash"]["ld_rank_percentage"];
      outputObject["aging"] = inputObject["aging"];

      outputObject["Rap Rank"] = inputObject["ld_analysis_hash"]["rap_rank"];
      outputObject["Rap Rank Exceeded"] =
        inputObject["ld_analysis_hash"]["rap_rank_exceeds"];
      outputObject["Rap Matching count"] =
        inputObject["ld_analysis_hash"]["rap_matching_count"];

      delete outputObject["LD Set"];
    } else if (key == "Supplier") {
      if (inputObject["supplier_name"]) {
        outputObject[key] = inputObject["supplier_name"];
      } else if (inputObject["Stock No"]) {
        outputObject[key] = inputObject["Stock No"].split("|")[0];
      } else {
        outputObject[key] = inputObject[""];
      }
    } else if (key == "$/Ct") {
      outputObject[key] = convertPriceStringToInt(
        inputObject["price_per_carat"]
      );
    } else if (key == "Cost $/Ct") {
      outputObject[key] = convertPriceStringToInt(
        inputObject["cost_price_per_carat"]
      );
    } else if (key == "Girdle") {
      if (inputObject["girdle"]) {
        outputObject[key] = inputObject["girdle"];
      } else if (inputObject["girdle_min"] && inputObject["girdle_max"]) {
        if (inputObject["girdle_min"] == inputObject["girdle_max"]) {
          outputObject[key] = inputObject["girdle_min"];
        } else {
          outputObject[
            key
          ] = `${inputObject["girdle_min"]} - ${inputObject["girdle_max"]} `;
        }
      } else {
        outputObject[key] = "";
      }
    } else if (key == "Location") {
      if (inputObject["country"]) {
        outputObject[key] = inputObject["country"];
      } else if (inputObject["enumlocation"]) {
        outputObject[key] = inputObject["enumlocation"];
      }
      else {
        outputObject[key] = inputObject["location"];
      }
    } else if (key == "Sold") {
      if (inputObject["for_sale_to"]) {
        outputObject[key] = convertTimestampToHumanReadable(
          inputObject["for_sale_to"]
        );
      } else {
        outputObject[key] = null;
      }
    } else {
      outputObject[key] = value;
    }
  }
  return outputObject;
};

export const strToInt = (str) => {
  if (str == "") {
    return 0;
  }

  return typeof str == "string" ? parseInt(str?.replace(/[$,]/g, "")) : str;
};

export const getCert = (data) => {
  if (data) {
    const ktsCountMap = new Map();

    // console.log({
    //   x: data,
    // });

    data.forEach((obj) => {
      if (obj["Cert Tags"] && obj["Cert Tags"].length > 0) {
        const kts = obj["Cert Tags"];

        kts.forEach((value) => {
          const lowercaseValue = value.toLowerCase();
          if (ktsCountMap.has(lowercaseValue)) {
            ktsCountMap.set(
              lowercaseValue,
              ktsCountMap.get(lowercaseValue) + 1
            );
          } else {
            ktsCountMap.set(lowercaseValue, 1);
          }
        });
      }
    });

    const ktsValuesArray = [];

    ktsCountMap.forEach((count, value) => {
      ktsValuesArray.push({ value, count });
    });

    // console.log(ktsValuesArray);
    return ktsValuesArray;
  }
};

export function transformToString(objList) {
  let result = "";
  for (let i = 0; i < objList.length; i++) {
    const item = objList[i];
    if (item.sorting == true) {
      result += `${encodeURIComponent(`multi_sort[${i}][${item.sort_by}]`)}=${
        item.asc_desc
      }&`;
    }
  }
  return result.slice(0, -1);
}

export function getType(objList) {
  let result = "items";
  for (let i = 0; i < objList.length; i++) {
    const item = objList[i];
    if (item.sorting == true) {
      // console.log({
      //   item,
      // });
      if (prrSorting.includes(item.sort_by)) {
        result = "prrs";
      } else {
        result = "items";
      }
    }
  }
  return result;
}

export const sortMapping = {
  Shape: "attr_types.diamond#shape",
  Carat: "attr_types.gem#carats",
  Color: "attr_types.diamond#color",
  Clarity: "attr_types.diamond#clarity",
  Cut: "attr_types.diamond#cut",
  Polish: "attr_types.diamond#polish",
  Sym: "attr_types.diamond#symmetry",
  Fluor: "attr_types.diamond#fluor",
  Shade: "attr_types.diamond#shade",
  Milky: "attr_types.diamond#milky",
  Location: "attr_types.diamond#enumlocation",
  "$/Ct": "attr_types.gem#price_per_carat.cents",
  "$/Ct": "price_per_carat",
  "Total Price": "sell_offer_cents",
  "LD Spec": "ld_analysis_hash.ld_spec",
  "LD Count": "ld_analysis_hash.ld_matching_count",
  "LD Rank": "ld_analysis_hash.ld_rank",
  "Rap %": "rap_percent",
  "LD Reco Diff": "ld_analysis_hash.rap_diff",
  "Use Rap": "ld_analysis_hash.use_rap",
  "Use Price": "ld_analysis_hash.use_price",
  "Rap Matching count": "ld_analysis_hash.rap_matching_count",
  Lab: "attr_types.diamond#cert",
  "Rap Rank": "ld_analysis_hash.rap_rank",
  "Rap Rank Diff": "change_in_rap_rank",
  "Rap Count Diff": "change_in_rap_count",
  "Aging": "attr_types.for_sale_from",
  "Rap Cluster Count": "rap_cluster_count",
  "Inventory Count": "inventory_items_count", 
  "Sales Count": "sold_items_count",
  "Added": "market_added_items_count", 
  "Sold": "market_sold_items_count",
  "Added (30 D)": "last_n_days_added_items_count", 
  "Sold (30 D)": "last_n_days_sold_items_count",
  "Rank Change (30 D)": "last_n_days_rank_change", 
  "Count Change (30 D)": "last_n_days_count_change",
  "Tracking Date": "tracking_start_date",
  "Total Availability": "total_saved_prmi",
  Repriced_At: "repriced_datetime",
  "Carat Range": "item_carat_range",
  "Stock Num": "attr_types.diamond#stock_number",
  "Cost $/Ct": "attr_types.all#cost_price_per_carat.cents",
  "Report Date": "created_at"
};

export const marketSortMapping = {
  Shape: "shape",
  Carat: "carats",
  Color: "color",
  Clarity: "clarity",
  Cut: "cut",
  Polish: "polish",
  Sym: "symmetry",
  Fluor: "fluor",
  Lab: "cert",
  Shade: "shade",
  Milky: "milky",
  "Rap %": "rap_discount",
  "Rap $/Ct": "rap_price_per_ct",
  "Rap Total": "rap_total",
  "$/Ct": "price_per_carat",
  "Total Price": "total_price",
  // Review - id is stock number but srting values are cert numbers
  "Stock Num": "cert_number",
  // Todo - should we disbale the sorting for measurements
  "Measurements": "dim_l",
  "Ratio": "ratio",
  "Table": "table_percentage",
  "Depth": "depth_percentage",
  "Cert Comments": "cert_comment",
  "Girdle": "cert_comment",
  "BIC": "black_in_center",
  "BIS": "black_in_side",
  "WIS": "white_in_center",
  "WIC": "white_in_side",
  "OT": "open_in_table",
  "OC": "open_in_crown",
  "OP": "open_in_pav",
  "Crown Angle": "crown_angle",
  "Crown Height": "crown_height",
  "Pav Angle": "pavilion_angle",
  "Pav Depth": "pavilion_depth",

  "Girdle Con": "girdle_condition",
  "Culet": "culet",
  "Flr Clr": "fluor_color",
  "Competition": "is_competition",

  "Location": "country",

  // Todo
  // Remove Columns based on which we can sort
  // tag
  // girdle

  // Review
  // Cert number key is stock number

  // Backend Columns still not used yet
  // t.text     "source",                                     :null => false
  // t.string   "supplier_name"
  // t.integer  "rapnet_id"
  // t.string   "stock_number"
  // t.string   "cert_number"
  // t.datetime "report_date"
  // t.string   "key_to_symbol"
  // t.string   "member_comments"
  // t.string   "black_inclusion"
  // t.string   "center_inclusion"
  // t.string   "open_inclusion"
  // t.string   "city"
  // t.string   "country"
  // t.string   "culet_condition"
  // t.string   "girdle_min"
  // t.string   "girdle_max"
  // t.float    "girdle_percentage"
  // t.string   "availability"
  // t.string   "eye_clean"
  // t.string   "video"
  // t.string   "image"
  // t.string   "cert_url"
  // t.text     "cert_comment_tags"
  // t.integer  "lock_version",                :default => 0, :null => false
  // t.datetime "created_at",                                 :null => false
  // t.datetime "updated_at",                                 :null => false
  // t.text     "note"
  // t.string   "note_type_title"
  // t.string   "item_type_unique_identifier"
  // t.boolean  "is_competition"
  // t.integer  "pricing_report_result_id"
  // t.integer  "item_id"
  // t.string   "unique_keys"
};

export function convertFromToStrToArray(property, fromToStr) {
  let allPropMaps = allPropMap[property]
  let filterToBeAppliedMinMax = fromToStr.toLowerCase().split("-");
  let allPropKeys = Object.keys(allPropMaps);
  let startIndex = allPropKeys.indexOf(filterToBeAppliedMinMax[0]);
  let endIndex = allPropKeys.indexOf(filterToBeAppliedMinMax[1]);
  if (startIndex === -1) {
    startIndex = 0;
  } 
  if (endIndex === -1) {
    endIndex = (allPropKeys.length - 1);
  } 
  if(startIndex > endIndex){
    let tempStartIndex = startIndex;
    startIndex = endIndex;
    endIndex = tempStartIndex;
  }
  const elementsBetween = allPropKeys.slice(startIndex, endIndex + 1);
  return elementsBetween;
};

// property = "Color", "Clarity", "Cut", ...
export function convertFromToStrToNegativeArray(property, fromToStr) {
  let allPropMaps = allPropMap[property]
  let filterToBeAppliedMinMax = fromToStr.toLowerCase().split("-");
  let allPropKeys = Object.keys(allPropMaps);
  let startIndex = allPropKeys.indexOf(filterToBeAppliedMinMax[0]);
  let endIndex = allPropKeys.indexOf(filterToBeAppliedMinMax[1]);
  if (startIndex === -1) {
    startIndex = 0;
  } 
  if (endIndex === -1) {
    endIndex = (allPropKeys.length - 1);
  } 
  if(startIndex > endIndex){
    let tempStartIndex = startIndex;
    startIndex = endIndex;
    endIndex = tempStartIndex;
  }
  const elementsOutsideRange = allPropKeys.slice(0, startIndex).concat(allPropKeys.slice(endIndex + 1));
  return elementsOutsideRange;
};

export const getFilters = (data) => {
  let arr = [];

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      arr.push(value);
    }
  });

  return arr;
};

export const getLDData = (data) => {
  const ldList = [
    "aging",
    "LD Reco",
    "LD Reco Diff",
    "LD Rank",
    "LD Count",
    "LD Spec",
  ];

  let newObj = {};

  if (data) {
    Object.entries(data).map(([k, v]) => {
      if (ldList.includes(k)) {
        newObj[k] = v;
      }
    });
  }

  return newObj;
};

export const getRapnetData = (data) => {
  //   {
  //     "ppc_1st": 2805,
  //     "rap_percent_1st": -65.8,
  //     "ppc_3rd": 3220,
  //     "rap_percent_3rd": -65,
  //     "ppc_5th": 3220,
  //     "rap_percent_5th": -65,
  //     "ppc_10th": 3586,
  //     "rap_percent_10th": -64.5,
  //     "ppc_25th": 3404,
  //     "rap_percent_25th": -63,
  //     "ppc_50th": 2660,
  //     "rap_percent_50th": -62,
  //     "ppc_100th": 4269,
  //     "rap_percent_100th": -60.1,
  //     "last_ppc": 4132,
  //     "last_rap_percent": -59.1,
  //     "rap_rank": "164+",
  //     "rap_matching_count": 12193
  // }

  let newObj = {};

  if (data) {
    Object.entries(data).map(([key, value]) => {
      if (value) {
        if (key.includes("ppc") || key.includes("last_")) {
          return;
        } else if (key.includes("rap_percent")) {
          const k = key.split("rap_percent_")[1];
          newObj[k] = value;
        } else {
          if (key == "rap_rank") {
            newObj["rank"] = value;
          } else if (key == "rap_matching_count") {
            newObj["count"] = value;
          } else {
            newObj[key] = value;
          }
        }
      }
    });
  }

  let rankUp = {};

  if (newObj["rank"]) {
    rankUp["rank"] = newObj["rank"];
    delete newObj["rank"];
  }

  if (newObj["count"]) {
    rankUp["count"] = newObj["count"];
    delete newObj["count"];
  }

  rankUp = {
    ...rankUp,
    ...newObj,
  };

  delete rankUp["rap_rank_exceeds"];
  delete rankUp["0th_to_10th_percentile"];
  delete rankUp["0th_to_10th_percentile)"];

  return rankUp;
};

export const recoMapping = (data) => {
  let x = {};

  if (data.ppc !== null) {
    x["ppc"] = data.ppc;
  }

  if (data.rap !== null) {
    x["rap"] = data.rap;
  }

  if (data.confidence !== null) {
    x["confidence"] = data.confidence.toUpperCase();
  }

  if (Object.keys(x).length > 0) {
    return x;
  } else {
    return {
      message: "Not Enough Data",
    };
  }
};

export const getLMH = (arr, key = "$/Ct") => {
  if (!arr) {
    return {
      low: null,
      mid: null,
      high: null,
    };
  }

  if (arr.length < 3) {
    return {
      low: null,
      mid: null,
      high: null,
    }; // Return null if there are not enough values in the array.
  }

  let low = arr[0][key];
  let high = arr[0][key];

  for (const obj of arr) {
    const value = obj[key];
    if (value < low) {
      low = value;
    }
    if (value > high) {
      high = value;
    }
  }

  const mid = (low + high) / 2;

  // Find the value closest to the mid within the array.
  let closestValue = arr[0][key];
  let closestDifference = Math.abs(arr[0][key] - mid);

  for (const obj of arr) {
    const value = obj[key];
    const difference = Math.abs(value - mid);

    if (difference < closestDifference) {
      closestValue = value;
      closestDifference = difference;
    }
  }

  return {
    low,
    mid: closestValue,
    high,
  };
};

export function addOrReduceDecimalPlace(value, decimalPlaces = 1) {
  if (value.isNaN) {
    return value;
  }

  if (typeof value === "number") {
    const absoluteValue = Math.abs(value); // Get the absolute value
    const convertedValue = absoluteValue.toFixed(decimalPlaces); // Convert to a string with decimal places

    // Add trailing zeros if needed
    const parts = convertedValue.split(".");

    // console.log(parts, value);
    const formattedValue = parts[0] + "." + parts[1].padEnd(decimalPlaces, "0");

    return value < 0 ? "-" + formattedValue : formattedValue;
  }

  return value;
}

function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
  var color1 = rgbColor1;
  var color2 = rgbColor2;
  var fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade = fade * 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  var diffRed = color2.red - color1.red;
  var diffGreen = color2.green - color1.green;
  var diffBlue = color2.blue - color1.blue;

  var gradient = {
    red: parseInt(Math.floor(color1.red + diffRed * fade), 10),
    green: parseInt(Math.floor(color1.green + diffGreen * fade), 10),
    blue: parseInt(Math.floor(color1.blue + diffBlue * fade), 10),
  };

  return (
    "rgba(" +
    gradient.red +
    "," +
    gradient.green +
    "," +
    gradient.blue +
    ", 0.45)"
  );
}

const colorGeneratorNegative = (array) => {
  const weight = 100 / array.length;

  let colorMap = {};

  let lowColor = {
    red: 82,
    green: 255,
    blue: 88,
  };
  let mediumColor = {
    red: 255,
    green: 255,
    blue: 194,
  };
  let highColor = {
    red: 255,
    green: 0,
    blue: 0,
  };

  for (var i = 0; i < array.length; i++) {
    let diff = (((i * weight) / 100) * 255) / 100;

    colorMap[array[i]] = colorGradient(diff, lowColor, mediumColor, highColor);
  }

  return colorMap;
};

export const colorGeneratorPositive = (array) => {
  const weight = 100 / array.length;

  let colorMap = {};

  let highColor = {
    red: 255,
    green: 0,
    blue: 0,
  };
  let mediumColor = {
    red: 255,
    green: 255,
    blue: 194,
  };
  let lowColor = {
    red: 82,
    green: 255,
    blue: 88,
  };

  for (var i = 0; i < array.length; i++) {
    let diff = (((i * weight) / 100) * 255) / 100;

    colorMap[array[i]] = colorGradient(diff, lowColor, mediumColor, highColor);
  }

  // console.log({
  //   colorMap,
  //   array
  // })

  return colorMap;
};

export function generateColorMap(arr) {
  // Step 1: Sort the array
  // const sortedArray = arr.slice().sort((a, b) => a - b);

  // Step 2: Find the index of the value closest to zero
  let closestIndex = 0;
  let closestDistance = Math.abs(arr[0]);

  for (let i = 1; i < arr.length; i++) {
    const distance = Math.abs(arr[i]);
    if (distance < closestDistance) {
      closestIndex = i;
      closestDistance = distance;
    }
  }

  const negativeValues = arr.slice(0, closestIndex);
  const positiveValues = arr.slice(closestIndex);

  const a = colorGeneratorNegative2(negativeValues.sort((a, b) => a - b));
  const b = colorGeneratorPositive2(positiveValues.sort((a, b) => a - b));

  return {
    ...a,
    ...b,
  };
}

export function debounce(fn, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
}

function generateHeatmapRGB(value, minVal, maxVal) {
  const setMaxVal = 180;
  const setMinVal = 0;
  minVal = setMinVal;
  maxVal = setMaxVal;
  value = Math.max(Math.min(value, setMaxVal), setMinVal);

  var normalizedValue = (value - minVal) / (maxVal - minVal);
  var coldColor = [82, 255, 82];
  var middleColor = [255, 255, 194];
  var hotColor = [255, 0, 0];
  var rgb;
  if (normalizedValue < 0.5) {
    var midNormalizedValue = normalizedValue * 2;
    rgb = coldColor.map(function (color, i) {
      return Math.round(color + midNormalizedValue * (middleColor[i] - color));
    });
  } else {
    var midNormalizedValue = (normalizedValue - 0.5) * 2;
    rgb = middleColor.map(function (color, i) {
      return Math.round(color + midNormalizedValue * (hotColor[i] - color));
    });
  }
  return rgb;
}

function generateHeatmapRGB2(value, minVal, maxVal) {
  // Adjust the minVal and maxVal to cover both negative and positive ranges
  var extendedMin = Math.min(minVal, -maxVal);
  var extendedMax = Math.max(maxVal, -minVal);

  var normalizedValue = (value - extendedMin) / (extendedMax - extendedMin);

  var coldColor = [82, 255, 82];
  var middleColor = [255, 255, 194];
  var hotColor = [255, 0, 0];

  var rgb;

  if (normalizedValue < 0.5) {
    var midNormalizedValue = normalizedValue * 2;
    rgb = coldColor.map(function (color, i) {
      return Math.round(color + midNormalizedValue * (middleColor[i] - color));
    });
  } else {
    var midNormalizedValue = (normalizedValue - 0.5) * 2;
    rgb = middleColor.map(function (color, i) {
      return Math.round(color + midNormalizedValue * (hotColor[i] - color));
    });
  }

  return rgb;
}

export const colorGeneratorPositive2 = (array) => {
  const weight = 100 / array.length;

  let colorMap = {};

  let highColor = {
    red: 255,
    green: 0,
    blue: 0,
  };
  let mediumColor = {
    red: 255,
    green: 255,
    blue: 194,
  };
  let lowColor = {
    red: 82,
    green: 255,
    blue: 88,
  };

  for (var i = 0; i < array.length; i++) {
    colorMap[array[i]] = generateHeatmapRGB(
      array[i],
      array[0],
      array[array.length - 1]
    );
  }

  return colorMap;
};

export const colorGeneratorNegative2 = (array) => {
  const weight = 100 / array.length;

  let colorMap = {};

  for (var i = 0; i < array.length; i++) {
    colorMap[array[i]] = generateHeatmapRGB(
      array[i],
      array[array.length - 1],
      array[0]
    );
  }

  return colorMap;
};

export const fluorMapping = {
  None: "N",
  Faint: "F",
  "Very Slight": "VS",
  Slight: "S",
  Medium: "M",
  Strong: "ST",
  "Very Strong": "VS",
  // Add more grades as needed
};

export const mappingDataTop = {
  shape: "Shape",
  custom_cut: "Special Cut",
  cut: "Cut",
  clarity: "Clarity",
  color: "Color",
  polish: "Polish",
  sym: "Sym",
  fluor: "Fluor",
  flour: "Fluor",
  shade: "Shade",
  milky: "Milky",
  table: "Table %",
  depth: "Depth %",
  "total price": "Total Price",
  key_to_symbol: "Key To Symbol",
  carat: "Carat",
  location: "Location",
  make: "make"
};

export const mappingDataBottom = {
  "table%": "Table",
  "depth%": "Depth",
  key_to_symbols: "Key To Symbol",
  location: "Location",
  lab: "Lab",
  ratio: "Ratio",
  carat: "Carat",
  length: "Length",
  width: "Width",
  color: "Color",
  clarity: "Clarity",
  depth: "Height",
  cut: "Cut",
  polish: "Polish",
  sym: "Sym",
  fluor: "Fluor",
  shade: "Shade",
  milky: "Milky",
  measurement: "Measurements",
  make: "make"
};

export const dataMapping = {
  "table%": "Table %",
  "depth%": "Depth %",
  key_to_symbols: "Key To Symbol",
  location: "Location",
  ratio: "Ratio",
  length: "Length",
  width: "Width",
  depth: "Height",
};


export const sortByMapping = {
  total_price: "Total Price",
  rap_discount: "Rap %",
  price_per_carat: "$/Ct",
};

export const getLocations = (data) => {
  if (data) {
    const locationSet = new Set();

    data.forEach((obj) => {
      if (obj["Location"]) {
        const kts = obj["Location"];

        locationSet.add(kts.toLowerCase());
      }
    });

    const locationArray = Array.from(locationSet);

    return locationArray;
  }
};

export const getShapes = (data) => {
  if (data) {
    const shapeSet = new Set();

    data.forEach((obj) => {
      if (obj["shape"]) {
        const shape = obj["shape"];

        shapeSet.add(shape.toLowerCase());
      }
    });

    const shapeArray = Array.from(shapeSet);

    return shapeArray;
  }
};

export const getLabs = (data) => {
  if (data) {
    const labSet = new Set();

    data.forEach((obj) => {
      if (obj["lab"]) {
        const lab = obj["lab"];

        labSet.add(lab.toLowerCase());
      }
    });

    const labArray = Array.from(labSet);

    return labArray;
  }
};

export const sortPrrMapping = {
  "LD Spec": "ld_analysis_hash.ld_spec",
  "LD Count": "ld_analysis_hash.ld_matching_count",
  "LD Rank": "ld_analysis_hash.ld_rank",
  "Rap %": "rap_percent",
  "LD Reco": "ld_analysis_hash.rap_diff",
  "Rap Count": "ld_analysis_hash.rap_matching_count",
  Aging: "aging",
  "Rap Rank": "ld_analysis_hash.rap_rank",
};

export const prrSorting = [
  "ld_analysis_hash.ld_spec",
  "ld_analysis_hash.ld_matching_count",
  "ld_analysis_hash.ld_rank",
  "ld_analysis_hash.use_rap",
  "rap_percent",
  "ld_analysis_hash.rap_diff",
  "ld_analysis_hash.rap_matching_count",
  "aging",
  "ld_analysis_hash.rap_rank",
];
const filterMapping = {
  Length: "gem#dim_l",
  Width: "gem#dim_w",
  "Table%": "diamond#table_percentage",
  "Depth%": "diamond#depth_percentage",
  Carats: "gem#carats",
  Carat: "gem#carats",
  Height: "gem#dim_d",
  Depth: "gem#dim_d",
  Ratio: "gem#ratio",
  Shape: "diamond#shape",
  Cut: "diamond#cut",
  Color: "diamond#color",
  Polish: "diamond#polish",
  Fluor: "diamond#fluor",
  Shade: "diamond#shade",
  Location: "diamond#enumlocation",
  Clarity: "diamond#clarity",
  Make: "diamond#make",
  Symmetry: "diamond#symmetry",
  Milky: "diamond#milky",
  "Key To Symbol": "diamond#key_to_symbol",
  "LD Reco Confidence": "ld_overall_data_confidence",
  // "Repriced Options": "state",
  Lab: "diamond#cert",
};

export const filterReverseMapping = {
  "gem#dim_l": "Length",
  "dim_l": "Length",
  "gem#dim_w": "Width",
  "dim_w": "Width",
  "diamond#table_percentage": "Table%",
  "table_percentage": "Table%",
  "diamond#depth_percentage": "Depth%",
  "depth_percentage": "Depth%",
  "gem#carats": "Carats",
  "carat": "Carats",
  "carats": "Carats",
  "gem#dim_d": "Depth",
  "dim_d": "Depth",
  "gem#ratio": "Ratio",
  "ratio": "Ratio",
  "diamond#shape": "Shape",
  "shape": "Shape",
  "diamond#cut": "Cut",
  "cut": "Cut",
  "diamond#color": "Color",
  "color": "Color",
  "diamond#polish": "Polish",
  "polish": "Polish",
  "diamond#fluor": "Fluor",
  "fluor": "Fluor",
  "diamond#shade": "Shade",
  "shade": "Shade",
  "diamond#enumlocation": "Location",
  "enumlocation": "Location",
  "location": "Location",
  "diamond#clarity": "Clarity",
  "clarity": "Clarity",
  "diamond#make": "Make",
  "make": "Make",
  "diamond#symmetry": "Symmetry",
  "symmetry": "Symmetry",
  "sym": "Symmetry",
  "diamond#milky": "Milky",
  "milky": "Milky",
  "diamond#key_to_symbol": "KTS",
  "cert_comment_tags": "Cert Comments",
  "key_to_symbol": "KTS",
  "kts": "KTS",
  "ld_overall_data_confidence": "Repl Confidence",
  // "Repriced Options": "state",
  "diamond#cert": "Lab",
  "cert": "Lab",
  "lab": "Lab",
};

const x = ["Ex", "VG", "Good", "Fair", "Poor"];
export const filterKeys = {
  shape: [
    "round",
    "princess",
    "pear",
    "oval",
    "emerald",
    "marquise",
    "heart",
    "cushion_brilliant",
    "cushion_modified",
    "radiant",
    "square_radiant",
    "asscher",
    "square_emerald",
    "tapered_baguette",
    "baguette",
    "europeancut",
    "old_miner",
    "star",
    "rose",
    "half_moon",
    "square",
    "bullets",
    "tapered_bullet",
    "trapezoid",
    "flanders",
    "briolette",
    "pentagonal",
    "hexagonal",
    "octagonal",
    "triangle",
    "trilliant",
    "calf",
    "shield",
    "lozenge",
    "kite",
    "round_modified_brilliant",
    "rectangular",
    "pear_rose_cut",
    "heart_rose_cut",
    "pear_novelty_cut",
    "heart_novelty_cut",
    "round_rose_cut",
    "oval_rose_cut",
    "cushion_rose_cut",
    "marquise_rose_cut",
    "trapezoid_step_cut",
    "triangular_step_cut",
    "triangular_mixed_cut",
    "pentagonal_step_cut",
    "shield_step_cut",
    "pear_mixed_cut",
    "oval_mixed_cut",
    "kite_mixed_cut",
    "cut_cornered_triangular_mixed_cut",
    "round_cornered_rectangular_modified_brilliant",
    "pear_modified",
    "oval_modified",
    "heart_modified",
    "triangular_modified",
    "marquise_modified",
    "shield_modified",
    "hexagonal_modified",
    "pentagonal_modified",
    "trapezoid_modified",
    "other_shape",
  ],
  locations: [
    "India",
    "USA",
    "Hong Kong",
    "Belgium",
    "Israel",
    "UAE",
    "UK",
    "China",
    "Thailand",
    "Canada",
    "Japan",
    "Netherlands",
    "Taiwan",
    "Italy",
    "Mexico",
    "Turkey",
    "Australia",
    "Singapore",
    "Sweden",
    "Germany",
    "South Africa",
    "Philippines",
    "Switzerland",
    "Hungary",
    "France",
    "Spain",
    "Korea",
    "Saudi Arabia",
    "New Zealand",
    "Poland",
    "Unknown",
    "Other",
  ],
  flr: ["none", "faint", "medium", "strong", "v_strong"],
  color: [
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  cut: x,
  clarity: [
    "FL",
    "IF",
    "VVS1",
    "VVS2",
    "VS1",
    "VS2",
    "SI1",
    "SI2",
    "SI3",
    "I1",
    "P1",
    "I2",
    "P2",
    "I3",
    "P3",
  ],
  sym: x,
  shade: [
    "None",
    "Yellow",
    "Brown",
    "Green",
    "Grey",
    "Black",
    "Pink",
    "Blue",
    "Greenish Brown",
    "Fancy Tint",
    "Light Brown",
    "Light Green",
    "Mix Tinge",
    "Other",
    "Unknown",
  ],
  milky: ["None", "Light", "Medium", "Heavy", "Other", "Unknown"],
  polish: x,
  ktsCount: [
    "bearded_girdle",
    "brown_patch_of_color",
    "bruise",
    "cavity",
    "chip",
    "cloud",
    "crystal",
    "etch_channel",
    "extra_facet",
    "feather",
    "grain_center",
    "indented_natural",
    "internal_graining",
    "knot",
    "laser_drilling",
    "manufacturing_remnant",
    "minor_details_of_polish",
    "natural",
    "needle",
    "pinpoint",
    "reflecting",
    "surface_graining",
    "twinning_wisp",
  ],
  ld_diff: ["high", "medium", "low"],
  repriced_filter_options: ["all", "repriced", "pending"],
  lab: [
    "gia",
    "ags",
    "hrd",
    "igi",
    "cgl",
    "dcla",
    "gcal",
    "ghi",
    "iidgr",
    "gsi",
    "gwlab",
    "ngtc",
    "pgs",
    "egl",
    "igl",
    "vgr",
  ],
};

export const negativeFiltersList = [
  "Location",
  // "Key To Symbol",
  "Color",
  "Clarity",
  "Cut",
  "Polish",
  "Symmetry",
  "Fluor",
  "Shade",
  "Milky",
  "Shape",
  "Lab",
  "LD Reco Confidence",
  // "Repriced Options",
  // "Key To Symbol",
];

export const negativeKeyMap = {
  Location: "locations",
  Shape: "shape",
  Color: "color",
  Clarity: "clarity",
  Cut: "cut",
  Polish: "polish",
  Symmetry: "sym",
  Fluor: "flr",
  Shade: "shade",
  Milky: "milky",
  "Key To Symbol": "ktsCount",
  "LD Reco Confidence": "ld_diff",
  // "Repriced Options": "repriced_filter_options",
  Lab: "lab",
};

export function convertToJsonObject(data) {
  const xt = [];

  data.map((item) => {
    if (negativeFiltersList.includes(item.id)) {
      let a = filterKeys[negativeKeyMap[item.id]].filter(
        (s) => !item.value.includes(s)
      );
      // console.log({ a });
      xt.push({
        id: item.id,
        value: a,
      });
    } else {
      xt.push({
        id: item.id,
        value: item.value,
      });
    }
  });

  const jsonObject = {
    item_filter: {
      from: {},
      to: {},
      discrete: {},
      text: {},
    },
  };

  xt.forEach((item, index) => {
    const id = filterMapping[item.id] || item.id.replace(/\s+/g, ""); // Replace with mapped key or remove spaces
    // const key = `000${index}`.slice(-4); // Add leading zeros

    if (item.value.hasOwnProperty("min") && item.value.hasOwnProperty("max")) {
      // console.log({
      //   xt,
      //   id,
      //   item,
      // });
      // Range item
      jsonObject.item_filter["from"][id] = `${item.value.min}`;
      jsonObject.item_filter["to"][id] = `${item.value.max}`;
    } else {
      // Discrete item
      if (id == "diamond#key_to_symbol") {
        jsonObject.item_filter.text[id] = item.value.join(",");
      } else if (id == "ld_overall_data_confidence") {
        jsonObject["ld_overall_data_confidence"] = item.value;
      // } else if (id == "state") {
      //   jsonObject["state"] = item.value;
      } else {
        jsonObject.item_filter.discrete[id] = item.value.map((value) => {
          let d = filterKeys[negativeKeyMap[item.id]];

          let idx = d.indexOf(value);

          const key = `000${idx}`.slice(-4);

          return `${key}${value.toLowerCase()}`;
        });
      }
    }
  });

  return jsonObject;
}

export const getCertURL = (data) => {
  let lab = data["Lab"].toLowerCase();

  switch (lab) {
    case "gia":
      return `https://www.gia.edu/report-check?reportno=${data["Cert Num"]}`;
  }
};

export  const TimeSince = ({ dateString }) => {
  const calculateTimeSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const differenceInMilliseconds = now - date;

    const seconds = Math.floor(differenceInMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days`;
    } else if (hours > 0) {
      return `${hours} hours`;
    } else if (minutes > 0) {
      return `${minutes} minutes`;
    } else {
      return `${seconds} seconds`;
    }
  };

  return <span>{calculateTimeSince(dateString)}</span>;
};