import React from "react";
import "./TableFilter.css";
import { capitalizeWords, cn } from "../../utils/utils";
import Tick from "../../assets/svgs/tick.svg";
import Cross from "../../assets/svgs/cross.svg";
import { Chip } from "@mui/material";

const FilterLabels = ({ label,value, isFiltered, setIsFiltered,setFiltered, count = null }) => {
  const displayValue = value ? value.toString().toUpperCase() :label;
  return (
    <>
      <Chip
        onClick={() => {
          setFiltered(value)
        }}
        className={cn(
          "m-1 p-0 cursor-pointer",
          isFiltered ? "bg-[#A4D9FF]" : "bg-[#F2F2F2]"
        )}
        size="small"
        label={
          <div className="flex justify-center items-center gap-1 ">
            <h5
              className={cn(
                "font-bold text-[0.6rem] m-0",
                isFiltered ? "text-[#0075FF]" : "text-[black]"
              )}
            >
              {displayValue}
            </h5>
            {isFiltered ? (
              <img
                src={Tick}
                style={{
                  width: "14px",
                  height: "14px",
                }}
              />
            ) : (
              <img
                src={Cross}
                style={{
                  width: "14px",
                  height: "14px",
                }}
                className="bg-[#F2F2F2]"
              />
            )}
          </div>
        }
      />
    </>
  );
};

export default FilterLabels;