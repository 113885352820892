import React, { useState } from "react";
import { motion } from "framer-motion";
import { cn } from "../utils/utils";
import amplitude from "../utils/ampli";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../redux/slices/pricingSlice";
import { useNavigate } from "react-router-dom";

const TabSlider = ({ tabsList, activeTab, setActiveTabCurrent, className }) => {
  const dispatch = useDispatch(); // Get dispatch function from react-redux
  const navigate = useNavigate();
  const handleTabClick = (index) => {
    const buttonClickedEvent = {
      event_type: `v2_pricetable_tab_switch`,
      event_properties: {
        value: activeTab,
      },
    };
    amplitude.track(buttonClickedEvent);

    setActiveTabCurrent(index); // Assuming setActiveTabCurrent handles local state
    console.log("activeTab TabSlider", activeTab);
    navigate(`/price_co-pilot?page=0`)
    // Dispatch action to update activeTab in Redux
    dispatch(setActiveTab(index));
  };

  const sliderVariants = {
    active: {
      left: `${tabsList[activeTab]["left"]}%`,
      width: `${tabsList[activeTab]["width"]}`,
    },
  };

  const tabVariants = {
    active: {
      color: "rgb(0, 0, 0)",
    },
    inactive: {
      color: "rgb(107, 114, 128)",
    },
  };

  return (
    <div>
    <div
      className={cn(
        "relative bg-white rounded-xl h-7 min-h-[25px] max-h-[30px] border-solid border-1 border-[#79bff1] text-center overflow-hidden justify-between flex xl:gap-4 md:gap-2 sm:gap-1 md:min-w-[150px]  lg:min-w-[210px]"
      )}
    >
      {tabsList.map(tab => (
        <div
          key={tab.ind}
          className={`flex items-center text-center  cursor-pointer w-100 ${
            tab.ind === activeTab
              ? "text-black font-medium rounded-lg"
              : "text-gray-500 font-normal"
          } font-medium text-xs whitespace-nowrap lg:px-3 md:px-1 z-1 w-1/2 justify-center`}
          onClick={() => handleTabClick(tab.ind)}
          variants={tabVariants}
          animate={activeTab === tab.ind ? "active" : "inactive"}
        >
          {tab.icon} &nbsp; {tab.key}
        </div>
      ))}

      <motion.div
        className="absolute bottom-0 left-0  bg-[#A4D9FF] rounded-xl h-full"
        style={{
          width: `${100 / tabsList.length}%`,
        }}
        variants={sliderVariants}
        animate="active"
        transition={{ duration: 0.3 }}
      />
    </div>
    </div>
  );
};

export default TabSlider;
