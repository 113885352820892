import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import React from "react";
import { FaLock,FaExpandAlt  } from "react-icons/fa";
import DefaultFilterSet from "./defaultFilterSet";
import BroadFilters from "./broadFilters";

const ExpandedFilter = ({ filters, isIcon, isFilterFormatTextOnly}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMouseOver = (event) => {
    event.stopPropagation();
    // console.log("event");
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMouseOut = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <PopupState variant="popover" popupId="comment-popover">
        {(popupState) => (
          <div className="max-h-[432px]">
            <div {...bindHover(popupState)}>
              {isIcon ? (
                <FaLock height={"10px"} />
              ) : (
                <FaExpandAlt height={"10px"}/>
              )}
              
            </div>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="text-[0.685rem] p-2 px-4">
                <table>
                  <thead>
                    <tr>
                      <td colSpan={2}>{isIcon ? ("Filters have been locked for this stone as following: ") : ("Not enough Data, Expanded the Filter to the following: ")}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {isFilterFormatTextOnly ? 
                      <tr>
                        <td>
                          <BroadFilters filters={filters} />
                        </td>
                      </tr>
                      : 
                      <tr>
                        <td>
                          <DefaultFilterSet filters={filters} />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
                <p className="text-[0.77rem] font-bold">
                </p>
              </div>
            </HoverPopover>
          </div>
        )}
      </PopupState>
    </>
  );
};

export default ExpandedFilter;
