import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openToast: false,
  severity: "success",
  alertText: "This is default message"
};

export const commonComponentSlice = createSlice({
  name: "commonComponent",
  initialState,
  reducers: {
    setSeverity: (state, action) => {
        state.severity = action.payload;
    },
    setAlertText: (state, action) => {
        state.alertText = action.payload;
    },
    setToast: (state, action) => {
      state.openToast = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setToast,
  setSeverity,
  setAlertText,
} = commonComponentSlice.actions;

export const commonComponent = (state) => state.commonComponent;

export default commonComponentSlice.reducer;
