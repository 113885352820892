import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const fakeSlice = createApi({
  reducerPath: 'fakeApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://mocki.io/v1/' }),
  endpoints: (builder) => ({
    getConfigFakeApi: builder.query({
      query: () => 'ba023ee9-1004-420c-b007-c49d4b224ff9',
    }),
  }),
});

export const { useGetConfigFakeApiQuery } = fakeSlice;
