import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import amplitude from "../utils/ampli";
import { Button, ButtonGroup, Grow, Paper, Popper, MenuItem, MenuList } from "@mui/material";
import { ArrowDropDownSharp } from "@mui/icons-material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  pricing,
  setBroadCount,
} from "../redux/slices/pricingSlice";

// Params
// onClose -
// refreshMarketItems (Object) - {isReset: true, isFilter: false, isDefault: false, lock: false, isCompetition: false} - any update of this will fire the backend API Call to fetch new data data based any one the boolean values
// userdiamond (Object) - used for amplitute
// setFilterConfigData - used to update filterConfigData state, filterConfigData is used to update filters(selected not selected etc) in filters. Eventually this will also be used in marketfilters(backend api call)
// configData - configData is the raw backend filter objects(which has available filters and their selected values(discrete - is_selected, ranges - from and to))
// setChecked - is used to set the competition switch false, in case of reset and default - resetting the competion also

const options = [
  { optionVal: 'Reset All Filters', val: "reset", key: "Reset" },
  { optionVal: 'Apply Default Filters', val: "default", key: "Reset" }
];

const FilterActions = ({
  onClose,
  refreshMarketItems,
  userdiamond,
  setFilterConfigData,
  setChecked,
  setMappedItemSearchCriteria,
  setCurrentPage,
  setMarketFrom,
}) => {
  

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const itemDetailsData = useSelector(pricing).itemDetails;
  const dispatch = useDispatch();
  const handleClick = (e) => {
    handleAction(selectedOption.val, e);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setSelectedOption(options[index]);
    setOpen(false);
    handleAction(options[index].val, event);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  

  const handleAction = (action, e) => {
    let itemConfigData = JSON.parse(JSON.stringify(itemDetailsData?.searchAndRankCriteria));
    switch (action) {
      case "reset": // "Broaden All Filters"
        // update itemConfigData - set is_selected to true for all
        // update itemConfigData - set from to 0 and to to 100 for all range filters
        // update itemConfigData/Review - what about KTS, Cert tags - should be blank?
        Object.keys(itemConfigData).forEach((key) => {
          if (Array.isArray(itemConfigData[key].value)) {
            itemConfigData[key].value.forEach(
              (item) => (item.is_selected = true)
            );
            // setMappedItemSearchCriteria(itemConfigData)
          } else if (
            typeof itemConfigData[key].value === "object" &&
            itemConfigData[key].value !== null
          ) {
            itemConfigData[key].value.from = itemConfigData[key].value.min;
            itemConfigData[key].value.to = itemConfigData[key].value.max;
          }
        });
        setFilterConfigData(itemConfigData);
        setMappedItemSearchCriteria(itemConfigData)
        setChecked(false);
        setCurrentPage(1)
        setMarketFrom(0)
        dispatch(setBroadCount(false));
        refreshMarketItems({
          isReset: true,
          isFilter: false,
          isDefault: false,
          lock: false,
          isBroad: false,
        });
        break;
      case "default": // "Apply Default Filters"
        // using the itemConfigData as the default
        setFilterConfigData(itemDetailsData?.searchAndRankCriteria);
        setMappedItemSearchCriteria(itemDetailsData?.searchAndRankCriteria)
        setChecked(false);
        setCurrentPage(1)
        setMarketFrom(0)
        dispatch(setBroadCount(false));
        refreshMarketItems({
          isReset: false,
          isFilter: false,
          isDefault: true,
          lock: false,
          isBroad:false,
        });
        break;
      default:
        console.error("Unknown action:", action);
    }

    const buttonClickedEvent = {
      event_type: `v4_itemDetails_${action.toLowerCase()}`,
      event_properties: {
        id: userdiamond["LD ID"],
      },
    };
    amplitude.track(buttonClickedEvent);
    onClose(e);
  };

  return (
    <>
      
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        size="small"
        className="text-xs"
      >
        <Button onClick={handleClick}>{selectedOption.key}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownSharp />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.val}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      className="rounded-xl"
                    >
                      {option.optionVal}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default FilterActions;
