import React from "react";
// import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value}°C`;
}

const FilterMinMaxNew = ({ label, filtered, setFiltered, dMin, dMax, val }) => {
  const [value, setValue] = React.useState([
    filtered ? filtered.min : dMin,
    filtered ? filtered.max : dMax,
  ]);

  const handleChange = (event, newValue) => {
    // console.log({
    //   newValue,
    // });
    setValue(newValue);
    setFiltered(val, {
      min: newValue[0],
      max: newValue[1],
    });
  };

  const handleMinTextChange = (setVal) => {
    const x = setVal;

    // Test if the text matches the decimal pattern
    let y = !isNaN(parseFloat(setVal));

    if (y) {
      console.log("Min y", val, x);
      setFiltered(val, {
        min: parseFloat(x + ".0"),
        max: value[1],
      });
      setValue((prevValue) => [x, prevValue[1]]);
    } else {
      setFiltered(val, {
        min: parseFloat(x),
        max: value[1],
      });
      setValue((prevValue) => [parseFloat(x), prevValue[1]]);
    }
  };

  const handleMaxTextChange = (setVal) => {
    const x = setVal;

    let y = !isNaN(parseFloat(setVal));

    if (y) {
      console.log("Max y", val, x);

      setFiltered(val, {
        max: parseFloat(x + ".0"),
        min: value[0],
      });
      setValue((prevValue) => [prevValue[0], x]);
    } else {
      setFiltered(val, {
        max: parseFloat(x),
        min: value[0],
      });
      setValue((prevValue) => [prevValue[0], parseFloat(x)]);
    }
  };

  const handleBlurMin = (setVal) => {
    setVal = parseFloat(setVal);
    const x = isNaN(setVal) || setVal < dMin ? dMin : setVal;

    setValue((prevValue) => {
      let newValue = [];
      if (prevValue[1] < x) {
        newValue = [prevValue[1], prevValue[1]];
      } else {
        newValue = [x, prevValue[1]];
      }
      setFiltered(val, {
        max: newValue[1],
        min: newValue[0],
      });
      return newValue;
    });
  };

  const handleBlurMax = (setVal) => {
    setVal = parseFloat(setVal);
    const x = isNaN(setVal) || setVal > dMax ? dMax : setVal;

    setValue((prevValue) => {
      let newValue = [];
      if (prevValue[0] > x) {
        newValue = [prevValue[0], prevValue[0]];
      } else {
        newValue = [prevValue[0], x];
      }
      setFiltered(val, {
        max: newValue[1],
        min: newValue[0],
      });
      return newValue;
    });
  };

  const valueChanged = (setVal, filteredVal) => {
    return setVal != filteredVal;
  };

  const renderCaratMinMax = (val, minMaxVal, text) => {
    if (val == "Carat") {
      return <div className="text-xs font-medium m-0 inline text-neutral-400">{text}: {minMaxVal}</div>;
    }
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex gap-2 justify-between ">
        <h6 className="text-xs font-medium py-1 px-2 m-0 align-middle w-[55px] self-center">{label}</h6>
        <div className="flex flex-col">
          <div className="flex flex-row justify-around">
            {renderCaratMinMax(val, dMin, "Min")}
            {renderCaratMinMax(val, dMax, "Max")}
          </div>
          <div className="flex flex-row">
            <input
              type="number"
              className={`w-44 text-[0.7rem] border-[#EAEAEA] border-solid outline-none rounded-xl px-2 text-center py-1 ${valueChanged(value[0], dMin) ? "font-bold text-[#0075FF]" : ""}`}
              value={value[0]}
              onChange={(ev) => {
                handleMinTextChange(ev.target.value == "" ? "" : ev.target.value);
              }}
              onFocus={(ev) => {
                if (ev.target.value == 0) ev.target.value = "";
              }}
              onBlur={() => handleBlurMin(value[0])}
            />
            <div>-</div>
            <input
              type="number"
              className={`w-44 text-[0.7rem] border-[#EAEAEA] border-solid outline-none rounded-xl px-2 text-center py-1 ${valueChanged(value[1], dMax) ? "font-bold text-[#0075FF]" : ""}`}
              value={value[1]}
              onChange={(ev) => {
                handleMaxTextChange(ev.target.value == "" ? "" : ev.target.value);
              }}
              onFocus={(ev) => {
                if (ev.target.value == 100) ev.target.value = "";
              }}
              onBlur={() => handleBlurMax(value[1])}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMinMaxNew;
