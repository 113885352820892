import React, { useState } from "react";
import "./TableFilter.css";
import FilterLabels from "./FilterLabels";
import { diamondAttributes, filterOrdering } from "../../utils/utils";
import amplitude from "../../utils/ampli";
import { CheckSquare, XSquare } from "lucide-react";

const TableFilter = ({ filterOptions, label, data, setFiltered, filterKey }) => {

  const value = label.toLowerCase();
  const [mappedFilterOptions, setMappedFilterOptions] = useState([...filterOptions]);
  const specialKeys = ["locations", "key_to_symbols", "cert_tags"];
  const isSpecialKey = specialKeys.includes(value);
  if (!label || !data) return null;

  let newData = [];
  if (isSpecialKey) {
    newData = data.value || [];
  } else {
    newData = filterOrdering(diamondAttributes[value], data.value?.map(item => item.label) || data);
  }

  const handleSelectAll = () => {
    const updatedFilterOptions = mappedFilterOptions.map(item => ({
      ...item,
      is_selected: true
    }));
    setMappedFilterOptions(updatedFilterOptions);
    setFiltered(filterKey, updatedFilterOptions.map(item => item.key));

    const buttonClickedEvent = {
      event_type: `v1_filter_${filterKey}_all`,
      event_properties: {
        values: "all",
      },
    };
    console.log("tableFilter buttonClickedEvent", buttonClickedEvent)
    amplitude.track(buttonClickedEvent);
  };

  const handleDeSelectAll = () => {
    const updatedFilterOptions = mappedFilterOptions.map(item => ({
      ...item,
      is_selected: false
    }));
    setMappedFilterOptions(updatedFilterOptions);
    setFiltered(filterKey, []);
    const buttonClickedEvent = {
      event_type: `v1_filter_${filterKey}_none`,
      event_properties: {
        values: "none",
      },
    };
    console.log("tableFilter buttonClickedEvent", buttonClickedEvent)
    amplitude.track(buttonClickedEvent);
  };

  

  const handleChange = (key) => {
    const updatedFilterOptions = mappedFilterOptions.map(item => (
      key == item.key ? {...item, is_selected: !item.is_selected} : item
    ));
    setMappedFilterOptions(updatedFilterOptions);
    let selectedKeys = updatedFilterOptions.reduce((acc, item) => {
      if (item.is_selected) {
        acc.push(item.key);
      }
      return acc;
    }, []);
    setFiltered(filterKey, selectedKeys);

    const item = updatedFilterOptions.find(item => item.key === key);
    const buttonClickedEvent = {
      event_type: `v1_filter_${key}`,
      event_properties: {
        values: item.label,
      },
    };
    console.log("tableFilter buttonClickedEvent", buttonClickedEvent)
    amplitude.track(buttonClickedEvent);
  }

  return (
    <>
      <div className="h-[1px] w-full border-x-0 border-t-0 border-b border-solid border-[#D8D8D8] px-2" />
      <div className="my-0 px-2 mb-2">
        <div className="flex justify-between items-start">
          <label style={{ fontWeight: "bold", color: "gray", fontSize: "12px", marginBottom: "5px" }}>
            {label.toUpperCase()}
          </label>
          <div className="flex items-start">
            <button
              className="border-none text-xs font-medium bg-transparent py-0 rounded-2xl hover:text-gray-400 p-0"
              onClick={handleSelectAll}
            >
              <CheckSquare height={"14px"} />
            </button>
            <button
              className="border-none text-xs font-medium bg-transparent py-0 rounded-2xl hover:text-red-400 p-0"
              onClick={handleDeSelectAll}
            >
              <XSquare height={"14px"} />
            </button>
          </div>
        </div>
        <div className="mt-0">
          {mappedFilterOptions.map((item, index) => {
            const itemKey = item.key;
            const itemLabel = item.label;
            const isFiltered = item.is_selected;
            
            return (
              <FilterLabels
                key={itemKey}
                value={itemLabel}
                isFiltered={isFiltered}
                setFiltered={() => handleChange(itemKey)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TableFilter;