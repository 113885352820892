import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Bold } from "lucide-solid";

const AnimatedText = ({ text }) => {
  const [currentColor, setCurrentColor] = useState("#000"); // Initial color
  const [currentSize, setCurrentSize] = useState(16); // Initial font size
  const [currentWeight, setCurrentWeight] = useState('bold'); // Initial font size
  const controls = useAnimation();

  // useEffect(() => {
  //   // Trigger animation when the text changes
  //   const newColor = getRandomColor();
  //   setCurrentColor(newColor);

  //   controls.start({
  //     color: newColor,
  //   });
  // }, [text]); // Watch for changes in the 'text' prop

  const getRandomColor = () => {
    const colorOptions = ["#f0c14b", "#4caf50", "#2196f3", "#e57373", "#000"]; // Dark shades of yellow, green, blue, red, and black
    const randomIndex = Math.floor(Math.random() * colorOptions.length);
    return colorOptions[randomIndex];
  };

  const getRandomSize = () => {
    // Generate a random font size, with a maximum of 0.685rem
    const maxSize = 0.685;
    const newSize = Math.random() * (maxSize - 0.5) + 0.5; // Font size between 0.5 and 0.685
    return `${newSize}rem`;
  };

  return (
    <motion.div
      style={{ color: currentColor, fontSize: currentSize, fontWeight: currentWeight  }}
      animate={controls}
      transition={{ duration: 0.5 }} // You can adjust the animation duration
    >
      {text}
    </motion.div>
  );
};

export default AnimatedText;
