import { forwardRef } from "react";
import { cn } from "../../utils/utils";

const CellContainerWithRef = forwardRef(function CellContainer(
  { children, className, headerId },
  ref
) {
  return (
    <div
      ref={ref}
      className={cn(
        "px-1 min-w-[52px] w-full flex items-center   h-full  ",
        {
          "min-w-[30px]": headerId === "select",
          "min-w-[52px]": headerId !== "select"
        },
        className
      )}
    >
      {children}
    </div>
  );
});

export default CellContainerWithRef;
