import React from "react";
import {
  colorPalette,
  KTSMap,
  comparisonGenerator2,
  findCommonElements,
  transformObject,
  compareAttributes,
  colorValues,
  compareAttributes2,
  clarityValues,
  cutValues,
  polishValues,
  symmetryValues,
  fluorescenceValues,
} from "../../../utils/utils";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Box, Chip, Popper } from "@mui/material";
import amplitude from "../../../utils/ampli";

function ObjectTable({ obj1, obj2, activeTab }) {
  // Extracting the keys from the first item in the object to use as table headers
  const objeData = comparisonGenerator2(obj1, obj2);

  const data = transformObject(objeData, activeTab);
  const headers = Object.keys(data[0]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    const buttonClickedEvent = {
      event_type: `v2_itemDetails_comparisonModal_ktsHover`,
    };
    amplitude.track(buttonClickedEvent);
    event.stopPropagation();

    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleOut = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const commons = findCommonElements(
    data[0]["Key To Symbol"],
    data[1]["Key To Symbol"]
  );

  const compareFunction = (data1, data2, header) => {
    // [
    //   "Location",
    //   "Carat",
    //   "Rap %",
    //   "$/Ct",
    //   "Total Price",
    //   "Color",
    //   "Clarity",
    //   "Cut",
    //   "Polish",
    //   "Sym",
    //   "Fluor",
    //   "Shade",
    //   "Measurements",
    //   "Table %",
    //   "Depth %",
    //   "Ratio",
    //   "Milky",
    //   "BIC",
    //   "Key To Symbol",
    // ];

    // console.log({
    //   header,
    // });

    switch (header) {
      case "Color":
        return compareAttributes2(data1, data2, "Color", colorValues);

      case "Clarity":
        return compareAttributes2(data1, data2, "Clarity", clarityValues);
      case "Cut":
        return compareAttributes2(data1, data2, "Cut", cutValues);
      case "Polish":
        return compareAttributes2(data1, data2, "Polish", polishValues);
      case "Sym":
        return compareAttributes2(data1, data2, "Sym", symmetryValues);
      case "Fluor":
        return compareAttributes2(data1, data2, "Fluor", fluorescenceValues);

      case "Carat":
        if (data1[header] == data2[header]) {
          return <p className="!text-black m-0">{data2[header]}</p>;
        }

        const val = data1[header] < data2[header];

        if (val) {
          return <p className="!text-green-500 m-0">{data2[header]}</p>;
        } else {
          return <p className="!text-red-500 m-0">{data2[header]}</p>;
        }

      case "Amount": {
        if (data1[header] == data2[header].toFixed(0)) {
          return <p className="!text-black m-0">{data2[header]}</p>;
        }

        const val = data1[header] < data2[header];

        if (val) {
          return (
            <p className="!text-green-500 m-0">{data2[header].toFixed(0)}</p>
          );
        } else {
          return (
            <p className="!text-red-500 m-0">{data2[header].toFixed(0)}</p>
          );
        }
      }

      case "$/Ct": {
        if (data1[header] == data2[header]) {
          return <p className="!text-black m-0">{data2[header].toFixed(0)}</p>;
        }

        const val = data1[header] < data2[header];

        if (val) {
          return (
            <p className="!text-green-500 m-0">{data2[header].toFixed(0)}</p>
          );
        } else {
          return (
            <p className="!text-red-500 m-0">{data2[header].toFixed(0)}</p>
          );
        }
      }
      case "Rap %": {
        if (data1[header] == data2[header]) {
          return <p className="!text-black m-0">{data2[header].toFixed(2)}</p>;
        }

        const val = data1[header] > data2[header];

        if (val) {
          return (
            <p className="!text-green-500 m-0">{data2[header].toFixed(2)}</p>
          );
        } else {
          return (
            <p className="!text-red-500 m-0">{data2[header].toFixed(2)}</p>
          );
        }
      }

      default:
        return data2[header];
    }
  };

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        className="z-[10000]"
        disablePortal={true}
      >
        <div className="px-3 py-2  rounded-lg bg-white border flex flex-col border-gray-300 ">
          <PopperRow data={data[0]} commons={commons} />
          <hr className="my-2" />
          <PopperRow data={data[1]} commons={commons} />
        </div>
      </Popper>
      <table className="w-full">
        <thead
          style={{
            backgroundColor: "white",
          }}
        >
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="min-w-[60px] px-2 py-1 text-center max-h-[3rem] whitespace-nowrap"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {console.log(data)}
          {data.map((item, rowIndex) => (
            <tr key={rowIndex} className="h-3 max-h-3 bg-white">
              {headers.map((header, cellIndex) => (
                <>
                  {header == "Key To Symbol" ? (
                    <td
                      key={cellIndex}
                      className="td-cG flex items-center whitespace-nowrap"
                      style={{
                        minWidth: "60px",
                        padding: "4px 6px",
                        maxWidth: header == "Key To Symbol" ? "150px" : "auto",
                        overflowX: "hidden",
                      }}
                      onMouseEnter={handleClick}
                      onMouseLeave={handleOut}
                    >
                      {item[header].map((kts, index) => {
                        const color = colorPalette.get(kts.toLowerCase());

                        if (commons.includes(kts)) {
                          return (
                            <div
                              style={{
                                marginBottom: "4px",
                                color: color?.foreground ?? "black",
                                fontWeight: "600",
                                whiteSpace: "nowrap",
                                marginRight: " 4px",
                              }}
                              className="text-xs mx-1"
                            >
                              <Chip
                                label={
                                  <p className="m-0 p-0 flex justify-center items-center">
                                    {KTSMap[kts.toLowerCase().replace(" ", "")]
                                      ? KTSMap[
                                          kts.toLowerCase().replace(" ", "")
                                        ]
                                      : kts.charAt(0).toUpperCase() +
                                        kts.slice(1)}
                                  </p>
                                }
                                style={{
                                  backgroundColor: "transparent",
                                  color: color?.foreground ?? "black",
                                }}
                                className="text-[0.66rem] m-0 p-0 pr-1"
                                size="small"
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div className="text-xs mx-1">
                              <Chip
                                label={
                                  KTSMap[kts.toLowerCase().replace(" ", "")]
                                    ? KTSMap[kts.toLowerCase().replace(" ", "")]
                                    : kts.charAt(0).toUpperCase() + kts.slice(1)
                                }
                                style={{
                                  backgroundColor:
                                    color?.background ?? "transparent",
                                  color: color?.foreground ?? "black",
                                }}
                                className="text-[0.66rem] m-0 p-0 pr-1"
                                size="small"
                              />
                            </div>
                          );
                        }
                      })}
                    </td>
                  ) : (
                    <td
                      style={{
                        minWidth: "60px",
                        padding: "4px 6px",
                        maxWidth: header == "Key To Symbol" ? "150px" : "auto",
                        overflowX:
                          header == "Key To Symbol" ? "auto" : "hidden",
                      }}
                      className="text-center whitespace-nowrap max-w-[70px] bg-white overflow-clip"
                    >
                      {item[header] == null || item[header] == ""
                        ? "--"
                        : header == "Location" &&
                          typeof item[header] == "string"
                        ? item[header].charAt(0).toUpperCase() +
                          item[header].slice(1)
                        : compareFunction(data[0], item, header)}

                      {/* {item[header]} */}
                    </td>
                  )}
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default ObjectTable;

const PopperRow = ({ data, commons }) => {
  return (
    <div className="flex gap-2 items-center">
      {data["Key To Symbol"].map((item) => {
        const color = colorPalette.get(item.toLowerCase());

        if (commons.includes(item)) {
          return (
            <div
              key={item}
              style={{
                marginBottom: "4px",
                color: color?.foreground ?? "black",
                fontWeight: "600",
                whiteSpace: "nowrap",
                marginRight: " 4px",
              }}
              className="text-xs"
            >
              <Chip
                label={
                  <p className="m-0 p-0 flex justify-center items-center">
                    {KTSMap[item.toLowerCase().replace(" ", "")]
                      ? KTSMap[item.toLowerCase().replace(" ", "")]
                      : item.charAt(0).toUpperCase() + item.slice(1)}
                  </p>
                }
                style={{
                  backgroundColor: "transparent",
                  color: color?.foreground ?? "black",
                }}
                className="text-[0.66rem] m-0 p-0"
                size="small"
              />
            </div>
          );
        } else {
          return (
            <div className="text-xs">
              <Chip
                label={
                  KTSMap[item.toLowerCase().replace(" ", "")]
                    ? KTSMap[item.toLowerCase().replace(" ", "")]
                    : item.charAt(0).toUpperCase() + item.slice(1)
                }
                style={{
                  backgroundColor: color?.background ?? "transparent",
                  color: color?.foreground ?? "black",
                }}
                className="text-[0.66rem] m-0 p-0"
                size="small"
              />
            </div>
          );
        }
      })}
    </div>
  );
};
