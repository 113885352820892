import React, { useEffect, useState } from "react";
import { ClickAwayListener, Grow, Popper, Slider } from "@mui/material";
import { motion } from "framer-motion";
import {
  convertTimestampToReadableDate2,
  debounce as dbnce,
} from "../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { pricing, setDiamondRepriced } from "../redux/slices/pricingSlice";

import {
  CheckCircle2,
  ChevronRight,
  Loader,
  XCircle,
} from "lucide-react";

import debounce from "lodash.debounce";
import amplitude from "../utils/ampli";
import { Fragment } from "react";
import RepricingInput from "./repricingInput";

const generateValue = (a, b, c, d, e, changedValues) => {
  //   console.log({ a, b, c, d, e, changedValues });

  if (!changedValues) return { ...{ a, b, c, d, e } };
  const newValues = {};

  if ("a" in changedValues) {
    newValues.c = parseFloat(changedValues.a * e);
    newValues.b = ((changedValues.a - d) / d) * 100;
    // newValues.b = Math.round(newValues.b * 100) / 100;
    // newValues.b = Math.max(newValues.b, -100);
    // newValues.b = Math.min(newValues.b, 100);
  } else if ("b" in changedValues) {
    newValues.a = parseFloat((d * changedValues.b) / 100 + d);
    newValues.c = parseFloat(newValues.a * e);
  } else if ("c" in changedValues) {
    newValues.a = changedValues.c / e;
    newValues.b = parseFloat((((newValues.a - d) / d) * 100).toFixed(2));
    // newValues.b = Math.round(newValues.b * 100) / 100;
    // newValues.b = Math.max(newValues.b, -100);
    // newValues.b = Math.min(newValues.b, 100);
  }

  return { ...{ a, b, c, d, e }, ...newValues, ...changedValues };
};

function binarySearch(arr, target) {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (arr[mid] === target) {
      return mid; // Found the target
    } else if (arr[mid] < target) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  // If the target is not in the array, return the index where it should be inserted
  return left;
}

const PricingPopOver = ({
  open,
  handleClose,
  anchorEl,
  data,
  anchorData,
  fieldName,
  handleSave,
  loader,
  key,
}) => {
  const [inputValue, setInputValue] = useState(data.value);
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [rapRank, setRapRank] = useState(data.row["Rap Rank"]);
  

  const sort_by = useSelector(pricing)?.config?.sort_by ?? "sell_offer_cents";
  const sort_array = data.row["Sort_array"];
  const sortByMapping = {
    price_per_carat: "$/Ct",
    ppc: "$/Ct",
    rap_discount: "Rap %",
    total_price: "Total Price",
  };
  
  const mapping = {
    "$/Ct": "a",
    "Rap %": "b",
    "Total Price": "c",
  };

  useEffect(() => {
    let rank = binarySearch(sort_array, data.row[sortByMapping[sort_by]]);
    setRapRank(rank);
  }, []);


  useEffect(() => {
    setInputValue(data.value);
    updateRapRankFromInput(data.value);
  }, [data.value]);

  const updateRapRankFromInput = (value) => {
    const newValues = generateValue(
      data.row["$/Ct"],
      data.row["Rap %"],
      data.row["Total Price"],
      data.row["Rap $/Ct"],
      data.row["Carat"],
      {
        [mapping[fieldName]]: parseFloat(value),
      }
    );

    const sortValue = newValues[mapping[sortByMapping[sort_by]]];
    const newRank = binarySearch(sort_array, sortValue) + 1;
    setRapRank(newRank);
  };

  

  const handleChange = (value) => {
   
    if (value === "" || value == null) {
      setInputValue(null);
      return;
    }

    if (value === "-") {
      setInputValue(value);
      return;
    }

    const newValues = generateValue(
      data.row["$/Ct"],
      data.row["Rap %"],
      data.row["Total Price"],
      data.row["Rap $/Ct"],
      data.row["Carat"],

      {
        [mapping[fieldName]]: parseFloat(value),
      }
    );


    data.table.options.meta?.handleRepricingChange(
      data.rowIndex,
      data.columnId,
      newValues
    );

    setInputValue(value);
    updateRapRankFromInput(value);
  };

  const handleSliderChange = (newRapRank) => {
    const value = rapRank - 1;
    const sortValue = data.row.Sort_array[value];

    const newValues = generateValue(
      data.row["$/Ct"],
      data.row["Rap %"],
      data.row["Total Price"],
      data.row["Rap $/Ct"],
      parseFloat(data.row["Carat"]),

      {
        [mapping[sortByMapping[sort_by]]]: sortValue,
      }
    );

    data.table.options.meta?.handleRepricingChange(
      data.rowIndex,
      data.columnId,
      newValues
    );

    setInputValue(newValues[mapping[fieldName]]);
    setRapRank(newRapRank);
  };

  const debouncedSliderChange = debounce(() => handleSliderChange(), 300);

  const handleCancel = (e, isClose = false) => {
    const newValues = generateValue(
      data.row["$/Ct"],
      data.row["Rap %"],
      data.row["Total Price"],
      data.row["Rap $/Ct"],
      data.row["Carat"],

      {
        [mapping[fieldName]]: anchorData,
      }
    );

    data.table.options.meta?.handleRepricingReset(
      data.rowIndex,
      data.columnId,
      newValues
    );

    setInputValue(anchorData);
    if (isClose) {
      handleClose(e);
      dispatch(setDiamondRepriced(false));
      setInputValue(data.value);
    }
  };

  const pricingLD = data.row["Suggested_pricing"];

  const overall = pricingLD.ld_overall_reco_hash;

  const newPricing = {
    // "INVENTORY BASED PRICING": pricingLD.ld_sales_reco_hash,
    "COMPETITION BASED PRICING": pricingLD.ld_competition_reco_hash,
    "MARKET BASED PRCING": pricingLD.ld_market_reco_hash,
  };

  const rapKeys = ["1st", "5th", "10th", "25th", "50th"];

  const x = [-52.4, -53.7, -56.7];
  const y = [-57.8, -57.2, -56.6, -51.3, -48.7];

  const lowestX = Math.min(...x);
  x.sort((a, b) => a - b);

  const sortedY = [...y].sort((a, b) => a - b);

  const rankInY = binarySearch(sortedY, lowestX);

  // const h = 310 - (rankInY + 1) * 25;


  return (
    <div onClick={(ev) => ev.stopPropagation()} key={key}>
      <Popper
        open={open}
        id={data.row["LD ID"] + data.columnId}
        anchorEl={anchorEl}
        onClose={(ev) => handleCancel(ev, true)}
        style={{
          borderRadius: "12px",
        }}
        // onBlur={(ev) => handleCancel(ev, true)}
        className="relative mt-3 rounded-xl mb-3 z-[10000]"
      >
        <motion.div
          className="absolute top-0 right-0 cursor-pointer "
          onClick={(ev) => handleCancel(ev, true)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.2 }}
        >
          <XCircle className="hover:fill-red-200" />
        </motion.div>

        <ClickAwayListener onClickAway={(ev) => handleCancel(ev, true)}>
          <Grow
            in={open}
            timeout={400}
            style={{
              transformOrigin: "0 0 0",
            }}
          >
            <div
              className="px-3 py-4 flex flex-row  bg-white border-1 border-solid border-[#EAEAEA] rounded-lg shadow-md lg:shadow-m gap-4 h-max ml-14"
              style={{ boxShadow: " 0px 9px 33px -12px rgba(184,182,184,1)" }}
            >
              <div className="max-w-[180px] h-max flex flex-col justify-between">
                <div className="w-full">
                  <div className="flex gap-2">
                    {/* <input
                      type="text"
                      className="max-w-[130px] border-1 border-solid border-[#3a2e2e50] rounded-lg p-1 font-medium text-xs text-right px-2 outline-gray-200"
                      value={inputValue}
                      onChange={handleChange}
                    /> */}
                    <RepricingInput
                      className="max-w-[100px] border-1 border-solid border-[#3a2e2e50] rounded-lg p-1 font-medium text-xs text-right px-2 outline-gray-200"
                      inputValue={inputValue}
                      setInputValue={handleChange}
                      disabled={loader}
                    />

                    <button
                      className=" bg-[#daf0ff] border-1 border-solid border-[#EAEAEA] rounded-md font-medium text-xs text-green-500  disabled:opacity-25"
                      onClick={(ev) => {
                        if (inputValue !== null || inputValue > 0) {
                          // amplitude events.
                          const buttonClickedEvent = {
                            event_type: `v2_pricetable_repricing_${fieldName}`,
                            event_properties: {
                              value: inputValue,
                            },
                          };
                          amplitude.track(buttonClickedEvent);
                          handleSave(ev, "");
                        }
                      }}
                      disabled={
                        loader ? true : false || anchorData == inputValue
                      }
                    >
                      {loader ? (
                        <Loader height={"18px"} width={"18px"} />
                      ) : (
                        <CheckCircle2 height={"18px"} width={"18px"} />
                      )}
                    </button>

                    <button
                      className=" bg-[#daf0ff] border-1 border-solid border-[#EAEAEA] rounded-md font-medium text-xs text-red-500"
                      onClick={(ev) => handleCancel(ev, false)}
                    >
                      <XCircle height={"18px"} width={"18px"} />
                    </button>
                  </div>
                  <div className=" flex justify-center items-center">
                    <p className="text-[0.66rem] mx-2">1</p>

                    <Slider
                      key={data.row["LD ID"]}
                      size="small"
                      step={1}
                      className="text-[#D9D9D9]"
                      valueLabelDisplay="auto"
                      min={1}
                      max={data.row["Rapnet Price Array"].length}
                      onChange={(e, value) => {
                        setRapRank(value);
                        // debouncedSliderChange(e);
                      }}
                      onChangeCommitted={(e, value) => debouncedSliderChange(value)}
                      value={rapRank}
                    />

                    <p className="text-[0.66rem] mx-2">
                      {data.row["Rapnet Price Array"].length}
                    </p>
                  </div>
                  <div className="flex justify-center items-center mb-1">
                    <p className="text-[0.66rem] font-semibold text-[#C7C7C7] flex">
                      Est. Rap Rank :{" "}
                      <p className="text-gray-400 ml-2">{rapRank}</p>
                    </p>
                  </div>
                </div>
                <div className="h-max bg-[#F3F3F3] rounded-lg">
                  {data.row["Comment"] ? (
                    <div className="h-max overflow-y-auto px-2 py-1">
                      {data.row["Comment"].map((item, idx) => {
                        if (!item.is_repricing_comment) {
                          return (
                            <Fragment key={idx}>
                              <div className="text-[0.55rem] font-base my-1 text-[#9A9A9A] italic">
                                @{item.user_name}
                                {" : "}
                                {convertTimestampToReadableDate2(item.date)}
                              </div>

                              <div className="text-[0.66rem] font-base my-1 mb-2 text-[#5A5A5A] italic">
                                {item.comment}
                              </div>
                            </Fragment>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <div className="w-full flex border-1 border-solid border-[#3a2e2e50] rounded-lg mt-1 pl-1">
                    <textarea
                      className=" w-full border-none text-xs  overflow-x-hidden overflow-y-auto  max-h-[100px]  outline-none rounded-lg min-h-[36px] "
                      value={comment}
                      onChange={(ev) => setComment(ev.target.value)}
                    />
                    <button
                      className="bg-[#daf0ff] border-1 border-solid border-[#EAEAEA] rounded-md font-medium text-xs py-1 h-[36px] text-green-500 self-end"
                      onClick={(ev) => {
                        if (inputValue !== null || inputValue > 0) {
                          const buttonClickedEvent = {
                            event_type: `v2_pricetable_comment_${fieldName}`,
                            event_properties: {
                              value: inputValue,
                            },
                          };
                          amplitude.track(buttonClickedEvent);
                          handleSave(ev, comment);
                          setComment("");
                        }
                      }}
                    >
                      <ChevronRight size={"20px"} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Grow>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default PricingPopOver;
