import React, { useMemo, useRef} from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getFacetedRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import CellContainer from "./cellContainer";
import {
  KTSMap,
  clarityValues,
  cn,
  colorPalette,
  colorValues,
  compareAttributes,
  cutValues,
  filterValues,
  findCommonElements,
  findInsertionIndex,
  fluorMapping,
  fluorescenceValues,
  formatter,
  getRapnetData,
  getValuesAtIndexes,
  isValueInRange,
  milkyValues,
  polishValues,
  shadeValues,
  strToInt,
  symmetryValues,
} from "../../utils/utils";
import { Chip, Popper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  pricing,
  saveItemDetails,
  setAnchorData,
  setFilteredData,
  setFilteredRowCount,
  setSorting,
  setUserTable,
  updateItemDetails,
} from "../../redux/slices/pricingSlice";
import {
  pricingModal,
  setDefault,
  setModalData,
  setOpen,
  setSelectedItem,
} from "../../redux/slices/pricingModalSlice";
import Repricing from "../ui/repricing";
import axios from "axios";
import { auth } from "../../redux/slices/authSlice";
import { apiSlice } from "../../redux/slices/apiSlice";
import RepricingCell from "./cellComponents/repricingCell";
import amplitude from "../../utils/ampli";

const ComparisonTable = ({ data, marketFilters, updateMetaTags }) => {
  const columnHelper = createColumnHelper();

  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const selectedItem = data[0];

  const accountDetails = useSelector(auth).userDetails;
  const token = useSelector(auth).token;
  const valueSort = (rowA, rowB, columnId) => {
    let a = rowA.getValue(columnId);
    let b = rowB.getValue(columnId);
    if (a === null || a === "") {
      return b === null || b === "" ? 0 : -1;
    } else if (b === null || b === "") {
      return 1;
    } else {
      return a > b ? -1 : b > a ? 1 : 0;
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("Stock No", {
        id: "Stock Num",
        header: "Stock Num", // Added header
        cell: (info) => {
          if (
            info.row.original["Stock No"] == null ||
            info.row.original["Stock No"] == ""
          )
            return (
              <p className="m-0 p-0 max-w-8">{info.row.original["Cert Num"]}</p>
            );

          return (
            <p className="m-0 p-0 max-w-20 w-20 overflow-hidden">
              {info.row.original["Stock No"]}
            </p>
          );
        },
      }),
      columnHelper.accessor("Carat", {
        id: "Carat",
        header: "Carat", // Added header
        cell: (info) => {
          const val = info.getValue() >= selectedItem["Carat"];

          if (val) {
            return <p className="!text-green-500 m-0">{info.getValue()}</p>;
          } else {
            return <p className="!text-red-500 m-0">{info.getValue()}</p>;
          }

          return info.getValue();
        },
      }),
      columnHelper.accessor("Color", {
        id: "Color",
        header: "Color", // Added header
        cell: (info) => {
          return compareAttributes(selectedItem, info, "Color", colorValues);

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Clarity", {
        id: "Clarity",
        header: "Clarity", // Added header
        cell: (info) => {
          return compareAttributes(
            selectedItem,
            info,
            "Clarity",
            clarityValues
          );

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Cut", {
        id: "Cut",
        header: "Cut", // Added header
        cell: (info) => {
          if (
            info.row.original["Special Cut"] !== null &&
            info.row.original["Shape"]?.toLowerCase() !== "round"
          ) {
            return (
              <p className="text-green-500 m-0 p-0">
                {info.row.original["Special Cut"]}
              </p>
            );
          }

          return compareAttributes(selectedItem, info, "Cut", cutValues);
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Polish", {
        id: "Polish",
        header: "Polish", // Added header
        cell: (info) => {
          return compareAttributes(selectedItem, info, "Polish", polishValues);
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Sym", {
        id: "Symmetry",
        header: "Sym", // Added header
        cell: (info) => {
          return compareAttributes(selectedItem, info, "Sym", symmetryValues);
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Fluor", {
        id: "Fluor",
        header: "Fluor", // Added header
        cell: (info) => {
          return compareAttributes(
            selectedItem,
            info,
            "Fluor",
            fluorescenceValues
          );
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Shade", {
        id: "Shade",
        header: "Shade", // Added header
        cell: (info) => {
          return compareAttributes(selectedItem, info, "Shade", shadeValues);
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Milky", {
        id: "Milky",
        header: "Milky", // Added header
        cell: (info) => {
          return compareAttributes(selectedItem, info, "Milky", milkyValues);
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Rap %", {
        id: "Rap %",
        header: "Rap %", // Added header
        cell: (info) => {
          if (info.row.original["Suggested_pricing"]) {
            const value = strToInt(info.getValue());

            const data = {
              value: value,
              item_id: info.row.original["LD ID"],
              is_total_price: false,
              is_price_per_carat: false,
              is_rap_discount: true,
            };

            return (
              <RepricingCell
                info={info}
                table={info.table}
                data={data}
                fieldName={"Rap %"}
                isFormatted={false}
                marketFilters={marketFilters}
                updateMetaTags={updateMetaTags}
              />
            );
          }

          const val = info.getValue() < selectedItem["Rap %"];

          if (val) {
            return <p className="!text-green-500 m-0">{info.getValue()}</p>;
          } else {
            return <p className="!text-red-500 m-0">{info.getValue()}</p>;
          }

          return info.getValue();
        },
        sortingFn: valueSort,
      }),
      columnHelper.accessor("$/Ct", {
        id: "$/Ct",
        header: "$/Ct", // Added header
        cell: (info) => {
          if (info.row.original["Suggested_pricing"]) {
            const value = strToInt(info.getValue())?.toFixed(0);

            const data = {
              value: value,
              item_id: info.row.original["LD ID"],
              is_total_price: false,
              is_price_per_carat: true,
              is_rap_discount: false,
            };

            return (
              <RepricingCell
                info={info}
                table={info.table}
                data={data}
                fieldName={"$/Ct"}
                isFormatted={true}
                marketFilters={marketFilters}
                updateMetaTags={updateMetaTags}
              />
            );
          }

          const val =
            formatter.format(info.getValue()) >=
            formatter.format(selectedItem["$/Ct"]);

          if (val) {
            return (
              <p className="!text-green-500 m-0">
                {formatter.format(info.getValue())}
              </p>
            );
          } else {
            return (
              <p className="!text-red-500 m-0">
                {formatter.format(info.getValue())}
              </p>
            );
          }

          return formatter.format(info.getValue());
        },
      }),
      columnHelper.accessor("Total Price", {
        id: "Total Price",
        header: "Total Price", // Added header
        cell: (info) => {
          if (info.row.original["Suggested_pricing"]) {
            const value = strToInt(info.getValue())?.toFixed(0);

            const data = {
              value: value,
              item_id: info.row.original["LD ID"],
              is_total_price: true,
              is_price_per_carat: false,
              is_rap_discount: false,
            };

            return (
              <RepricingCell
                info={info}
                table={info.table}
                data={data}
                fieldName={"Total Price"}
                isFormatted={true}
                marketFilters={marketFilters}
                updateMetaTags={updateMetaTags}
                
              />
            );
          }

          const val =
            formatter.format(info.getValue()) >=
            formatter.format(selectedItem["Total Price"]);

          if (val) {
            return (
              <p className="!text-green-500 m-0">
                {formatter.format(info.getValue())}
              </p>
            );
          } else {
            return (
              <p className="!text-red-500 m-0">
                {formatter.format(info.getValue())}
              </p>
            );
          }

          if (info.row.original["Total Price"])
            return formatter.format(info.getValue());
        },
      }),

      columnHelper.accessor("Location", {
        id: "Location",
        header: "Location", // Added header
        cell: (info) => info.getValue(),
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Measurements", {
        id: "Measurements",
        header: "Measurements", // Added header
        cell: (info) => info.getValue(),
        filterFn: "measurementFilter",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("Table %", {
        id: "Table",
        header: "Table", // Added header
        cell: (info) => info.getValue(),
        filterFn: "minMaxFilter",
      }),
      columnHelper.accessor("Depth %", {
        id: "Depth",
        header: "Depth", // Added header
        cell: (info) => info.getValue(),
        filterFn: "minMaxFilter",
      }),
      columnHelper.accessor("Ratio", {
        id: "Ratio",
        header: "Ratio",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("BIC", {
        id: "BIC",
        header: "BIC", // Added header
        cell: (info) => {
          return info.getValue();
        },
      }),
      columnHelper.accessor("Key To Symbol", {
        id: "Key To Symbol",
        header: "Key To Symbols", // Added header
        cell: function Cell({ getValue, row, column, table }) {
          const value = getValue();

          const [anchorEl, setAnchorEl] = React.useState(null);

          const open = Boolean(anchorEl);

          const handleClick = (event) => {
            const buttonClickedEvent = {
              event_type: `v2_itemDetails_comparisonModal_ktsHover`,
            };
            amplitude.track(buttonClickedEvent);
            event.stopPropagation();

            setAnchorEl(anchorEl ? null : event.currentTarget);
          };

          const handleOut = (event) => {
            event.stopPropagation();
            setAnchorEl(null);
          };

          if (value == null || value == "") {
            return "";
          }

          const ktsArray = value.split(",").map((x) => x.trim());

          const array1 = ktsArray;

          const common1 = selectedItem["Key To Symbol"]
            ? findCommonElements(
                selectedItem["Key To Symbol"]?.split(",").map((x) => x.trim()),
                array1
              )
            : [];

          return (
            <>
              <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom"
                className="z-[10000]"
                disablePortal={true}
              >
                <div className="px-3 py-2  rounded-lg bg-white border flex flex-col border-gray-300 ">
                  <PopperRow data={data[0]} commons={common1} />
                  <hr className="my-2" />
                  <PopperRow data={data[1]} commons={common1} />
                </div>
              </Popper>
              <div
                className="flex flex-col justify-center items-start gap-1 overflow-x-scroll no-scrollbar whitespace-nowrap w-full"
                onMouseEnter={handleClick}
                onMouseLeave={handleOut}
              >
                <div>
                  {array1.map((item, idx) => {
                    const color = colorPalette.get(item.trim().toLowerCase());

                    return (
                      <Chip
                        key={idx}
                        label={
                          !common1.includes(item)
                            ? ` +  ${item.toUpperCase()}`
                            : item.toUpperCase()
                        }
                        className="text-[0.625rem] h-4 font-bold  whitespace-nowrap mr-1 tracking-wide"
                        style={{
                          backgroundColor: !common1.includes(item)
                            ? color?.background
                            : "transparent",
                          color: color?.foreground,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          );
        },
        filterFn: "multiValueFilter", // Added filterFn
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,

    meta: {
      handleRepricingChange: (rowIndex, columnId, value) => {
        // console.log({
        //   rc: value,
        // });
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              "$/Ct": value.a,
              "Rap %": value.b,
              "Total Price": value.c,
            };
          }
          return row;
        });

        dispatch(updateItemDetails(newData));
      },

      handleRepricingReset: (rowIndex, columnId, value) => {
        // console.log(value);
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              "$/Ct": value.a,
              "Rap %": value.b,
              "Total Price": value.c,
            };
          }
          return row;
        });
        dispatch(updateItemDetails(newData));
      },

      updateEditState: (value, rowIndex) => {
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            dispatch(
              apiSlice.util.invalidateTags([`Rapnet matching ${row["LD ID"]}`])
            );
            return {
              ...row,
              "Rap %": value["rap_percent"],
              "$/Ct": value["price_per_carat"],
              "Total Price": value["updated_price"],
              "Rap Diff": value["Rap Diff"],
              "LD Rank": value["updated_ld_rank"],
              "LD Rank %": value["LD Rank %"],
              "Rap Rank": value["updated_rapnet_rank"],
              State: value["state"],
              Comment: value["notes"] ? value["notes"] : row["Comment"],
              "Reprice Date": value["repriced_datetime"],
            };
          }
          return row;
        });
        dispatch(setUserTable(newData));
        dispatch(apiSlice.util.invalidateTags(["priceTable"]));
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <table className="w-full ">
      {/* {console.log({
        data,
      })} */}
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="text-center ">
            {headerGroup.headers.map((header) => (
              <th key={header.id} className="min-w-[50px] whitespace-nowrap">
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={cn(
              "!h-8 max-h-8 text-center",
              row.original["isHeader"] ? "bg-[#EBF2FF]" : "bg-white"
            )}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="whitespace-nowrap">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ComparisonTable;

const PopperRow = ({ data, commons }) => {
  return (
    <div className="flex gap-2 items-center">
      {data && data["Key To Symbol"] ? (
        data["Key To Symbol"]
          .split(",")
          .map((x) => x.trim())
          .map((item) => {
            const color = colorPalette.get(item.toLowerCase());

            if (commons.includes(item)) {
              return (
                <div
                  key={item}
                  style={{
                    marginBottom: "4px",
                    color: color?.foreground ?? "black",
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                    marginRight: " 4px",
                  }}
                  className="text-xs"
                >
                  <Chip
                    label={
                      <p className="m-0 p-0 flex justify-center items-center">
                        {KTSMap[item.toLowerCase().replace(" ", "")]
                          ? KTSMap[item.toLowerCase().replace(" ", "")]
                          : item.charAt(0).toUpperCase() + item.slice(1)}
                      </p>
                    }
                    style={{
                      backgroundColor: "transparent",
                      color: color?.foreground ?? "black",
                    }}
                    className="text-[0.66rem] m-0 p-0"
                    size="small"
                  />
                </div>
              );
            } else {
              return (
                <div className="text-xs">
                  <Chip
                    label={
                      KTSMap[item.toLowerCase().replace(" ", "")]
                        ? KTSMap[item.toLowerCase().replace(" ", "")]
                        : item.charAt(0).toUpperCase() + item.slice(1)
                    }
                    style={{
                      backgroundColor: color?.background ?? "transparent",
                      color: color?.foreground ?? "black",
                    }}
                    className="text-[0.66rem] m-0 p-0"
                    size="small"
                  />
                </div>
              );
            }
          })) : ("")
      }
    </div>
  );
};
