import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  cn,
  convertToJsonObject,
  filterKeys,
  getMinMaxOfData,
  getMinMaxOfMeasureMentData,
  getRatioMinMaxOfData,
  milkyValues,
  negativeFiltersList,
  negativeKeyMap,
} from "../../utils/utils";
import _ from "lodash";
import FilterMinMaxNew from "../filterMinMaxNew";
import TableFilterTop from "../TableFilter/TableFilterTop";
import amplitude from "../../utils/ampli";
import TableFilter2 from "../TableFilter/tableFilter2";
import { setFilteredRedux } from "../../redux/slices/pricingSlice";
import { useDispatch } from "react-redux";
import HalfExpandedCard from "./halfExpandedCard";

const TopFilterDrawer = ({ open, onClose, data, filtered, setFiltered }) => {
  // local state.
  const [filterState, setFilterState] = useState([]);
  const dispatch = useDispatch();

  // we set the filter state to the filtered value, when the drawer is opened.
  // this is done so that the drawer shows the filtered values.
  useEffect(() => {
    if (open) {
      setFilterState(filtered);
    }
  }, [open, filtered, dispatch]);


  const mFilters = [
    {
      label: "Carat",
      key: "Carat",
      min: 0,
      max: 100,
    },
    {
      label: "Length",
      key: "Length",
      min: 0,
      max: 100,
    },
    {
      label: "Width",
      key: "Width",
      min: 0,
      max: 100,
    },
    {
      label: "Depth",
      key: "Depth",
      min: 0,
      max: 100,
    },
    {
      label: "Ratio",
      key: "Ratio",
      min: 0,
      max: 100,
    },
  ];

  const mMFilters = [
    {
      label: "Table%",
      key: "Table%",
      min: 0,
      max: 100,
    },
    {
      label: "Depth%",
      key: "Depth%",
      min: 0,
      max: 100,
    },
  ];
  const negativeFilters = {
    shape: "Shape",
    color: "Color",
    clarity: "Clarity",
    cut: "Cut",
    polish: "Polish",
    sym: "Symmetry",
    flr: "Fluor",
    lab: "Lab",
    shade: "Shade",
    milky: "Milky",
    ld_diff: "LD Reco Confidence",
    locations: "Location",
    // repriced_filter_options: "Repriced Options",
  };



  const handleMeasurement = (key, value) => {
    setFilterState((prev) => {
      let x = [...prev];
      // we find if the prev state has any obj with id of key
      // it basically checks if the obj exist if yes then we
      // only update the values, if not then create and push the object.
      let obj = x.find((obj) => obj.id === "Measurements");
      
      // if there is no object, or if length of filtered is zero(means 1st filter is being applied)
      // then we create and push a new object to array
      
      if (!obj || x.length == 0) {
        x.push({
          id: "Measurements",
          value: {
            [key]: value,
          },
        });
      } else {
        // if the item exist, then we map through the obj to get the item and update its value.
        // TODO: need to optimize this bit as we loop twice for obj and then for this block.
        x.map((item) => {
          if (item.id == "Measurements") {
            let val = item.value;
            item.value = {
              ...val,
              [key]: value,
            };
          }
        });
      }
      return x; // return the new state array
    });
  };

  const handleSetFilter = (key, value) => {
    console.log({
      filterState,
    });
    // we the key, for which the value is being filtered.

    // we set the filtered value.
    setFilterState((prev) => {
      let x = [...prev];

      // we find if the prev state has any obj with id of key
      // it basically checks if the obj exist if yes then we
      // only update the values, if not then create and push the object.

      let obj = x.find((obj) => obj.id === key);
      
      // if there is no object, or if length of filtered is zero(means 1st filter is being applied)
      // then we create and push a new object to array

      if (!obj || x.length == 0) {
        if (negativeFiltersList.includes(key)) {
          let a = filterKeys[negativeKeyMap[key]].filter(
            (item) => !value.includes(item)
          );

          x.push({
            id: key,
            value: a,
          });
        } else {
          x.push({
            id: key,
            value: value,
          });
        }
      } else {
        // if the item exist, then we map through the obj to get the item and update its value.
        // TODO: need to optimize this bit as we loop twice for obj and then for this block.

        if (value.length == 0) {
          x = x.filter((item) => item.id !== key);
        } else {
          if (negativeFiltersList.includes(key)) {
            let a = filterKeys[negativeKeyMap[key]].filter(
              (item) => !value.includes(item)
            );

            x = x.map((item) => {
              if (item.id == key) {
                return {
                  ...item,
                  value: a,
                };
              }
              return item;
            });
          } else {
            x = x.map((item) => {
              console.log({ item, key, value });
              if (item.id == key) {
                return {
                  ...item,
                  value,
                };
              }
              return item;
            });
          }
        }
      }

      return x; // return the new state array
    });
  };

  // const handleSetFilter = (key, value) => {
  //   // we the key, for which the value is being filtered.

  //   // we set the filtered value.
  //   setFilterState((prev) => {
  //     let x = [...prev];

  //     // we find if the prev state has any obj with id of key
  //     // it basically checks if the obj exist if yes then we
  //     // only update the values, if not then create and push the object.

  //     let obj = x.find((obj) => obj.id === key);

  //     // if there is no object, or if length of filtered is zero(means 1st filter is being applied)
  //     // then we create and push a new object to array

  //     if (!obj || x.length == 0) {
  //       x.push({
  //         id: key,
  //         value: value,
  //       });
  //     } else {
  //       // if the item exist, then we map through the obj to get the item and update its value.
  //       // TODO: need to optimize this bit as we loop twice for obj and then for this block.

  //       if (value.length == 0) {
  //         x = x.filter((item) => item.id !== key);
  //       } else {
  //         x.map((item) => {
  //           if (item.id == key) {
  //             item.value = value;
  //           }
  //         });
  //       }
  //     }

  //     return x; // return the new state array
  //   });
  // };

  // const resetFilters = (e) => {
  //   setFilterState([]);
  //   dispatch(setFilteredRedux([]));
  //   setFiltered([]);
  //   onClose(e);
  // };


  function getObjectById(array, id) {
    let x = array.find((obj) => obj.id === id);
    return x || { id, value: [] };
  }

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      {/* {console.log({
        filterState,
        filtered,
        a: convertToJsonObject(filterState),
      })} */}
      <div className="w-[90vw] min-w-[400px] md:w-[30vw] md:min-w-[450px] md:max-w-[450px] min-h-full h-[full] flex flex-col overflow-hidden">
        <header className="min-h-[60px] border-x-0  border-t-0 border-b border-solid border-[#D8D8D8] flex items-center gap-4">
          <svg
            className="ml-4 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="15px"
            height="15px"
            viewBox="0 -2 32 32"
            onClick={onClose}
          >
            <g id="Lager_86" data-name="Lager 86" transform="translate(0 -3)">
              <path
                id="Path_94"
                data-name="Path 94"
                d="M16.587,6.42,27.167,17,16.587,27.58a2,2,0,0,0,2.833,2.833L31.414,18.42a2.013,2.013,0,0,0,0-2.84L19.42,3.587A2,2,0,0,0,16.587,6.42Z"
                fill="#161615"
              />
              <path
                id="Rectangle_45"
                data-name="Rectangle 45"
                d="M0,0H26a2,2,0,0,1,2,2V2a2,2,0,0,1-2,2H0A0,0,0,0,1,0,4V0A0,0,0,0,1,0,0Z"
                transform="translate(28 19) rotate(180)"
                fill="#161615"
              />
            </g>
          </svg>
          <h4 className="m-0 font-medium text-base">Filter Options.</h4>
        </header>

        <div className="overflow-auto h-full ">
          <div className="flex flex-col px-3 py-3 gap-2">
            <h6 className="my-0 px-2 mb-2 m-0 font-bold text-xs text-[#838383]">DIMENSIONS</h6>
            <div className="grid grid-col-1 gap-2">
              {mFilters.map((item) => {
                let obj = filtered.find((obj) => obj.id === item.label);

                return (
                  <FilterMinMaxNew
                    label={item.label}
                    setFiltered={handleSetFilter}
                    filtered={obj ? obj.value : null}
                    dMin={item.min}
                    dMax={item.max}
                    val={item.key}
                  />
                );
              })}
            </div>
          </div>
          <div className="h-[1px] w-[90%] border-x-0  border-t-0 border-b border-solid border-[#D8D8D8] m-auto" />
          <div className="flex flex-col px-3 py-3 gap-2">
            <h6 className="my-0 px-2 mb-2 m-0 font-bold text-xs text-[#838383]">
              PROPORTIONS
            </h6>
            <div className="grid grid-col-1 gap-2">
              {mMFilters.map((item) => {
                let obj = filtered.find((obj) => obj.id === item.key);

                return (
                  <FilterMinMaxNew
                    label={item.label}
                    setFiltered={handleSetFilter}
                    filtered={obj ? obj.value : null}
                    dMin={item.min}
                    dMax={item.max}
                    val={item.key}
                  />
                );
              })}
            </div>
          </div>
          <div className="h-[1px] w-[90%] border-x-0  border-t-0 border-b border-solid border-[#D8D8D8] m-auto" />

          {Object.entries(negativeFilters).map(([key, value]) => {
            if (key.toLowerCase() == "shape") {
              return (
                <HalfExpandedCard>
                  <>
                    {/* looping through all the filters */}
                    <div className="flex flex-col px-3  gap-2">
                      {/* <h6 className="m-0 font-bold text-xs text-[#838383]">LOCATION</h6> */}
                      <TableFilter2
                        data={filterKeys[key]}
                        label={value}
                        filtered={
                          filterState.length > 0
                            ? getObjectById(filterState, value)
                            : { id: value, value: [] }
                        }
                        setFiltered={handleSetFilter}
                      />
                    </div>
                  </>
                </HalfExpandedCard>
              );
            } else {
              return (
                <>
                  {/* looping through all the filters */}
                  <div className="flex flex-col px-3  gap-2">
                    {/* <h6 className="m-0 font-bold text-xs text-[#838383]">LOCATION</h6> */}
                    <TableFilter2
                      data={filterKeys[key]}
                      label={value}
                      filtered={
                        filterState.length > 0
                          ? getObjectById(filterState, value)
                          : { id: value, value: [] }
                      }
                      setFiltered={handleSetFilter}
                    />
                  </div>
                </>
              );
            }
          })}
          <div className="flex flex-col px-3  gap-2">
            {/* <h6 className="m-0 font-bold text-xs text-[#838383]">LOCATION</h6> */}
            <TableFilterTop
              data={filterKeys.ktsCount}
              label="KEY TO SYMBOLS"
              filtered={
                filterState.length > 0
                  ? getObjectById(filterState, "Key To Symbol")
                  : { id: "Key To Symbol", value: [] }
              }
              setFiltered={handleSetFilter}
            />
          </div>
        </div>

        <div className="min-h-12 w-full  bg-white  border-x-0  border-t border-b-0 border-solid border-[#D8D8D8] flex items-center justify-evenly gap-4 px-4 py-2">
          <button
            className={cn(
              "h-8 max-w-[180px] w-full border-none rounded-xl cursor-pointer text-sm font-medium",
              filterState !== filtered 
                ? "bg-[#A4D9FF]"
                : "bg-gray-200 text-gray-400"
            )}
            onClick={(e) => {
              // amplitude events.
              const buttonClickedEvent = {
                event_type: `v2_itemDetails_filter`,
                event_properties: {
                  value: filterState,
                },
              };
              amplitude.track(buttonClickedEvent);
              dispatch(setFilteredRedux(filterState));
              setFiltered(filterState);
              onClose(e);
            }}
          >
            Apply
          </button>
          <button
            className="h-8 max-w-[180px] w-full bg-white border-[#EAEAEA] border-solid outline-none rounded-xl text-sm font-medium"
            onClick={(e) => {
              const buttonClickedEvent = {
                event_type: `v2_itemDetails_reset`,
                event_properties: {
                  value: filterState,
                },
              };
              amplitude.track(buttonClickedEvent);
              setFilterState([]);
              dispatch(setFilteredRedux([]));
              setFiltered([]);
              onClose(e);
  }}
            
          >
            Reset
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default TopFilterDrawer;

//  <div className="flex flex-col px-3  gap-2">
//             {/* <h6 className="m-0 font-bold text-xs text-[#838383]">LOCATION</h6> */}
//             <TableFilter
//               data={filterKeys.locations}
//               label="LOCATION"
//               filtered={
//                 filterState.length > 0
//                   ? getObjectById(filterState, "Location")
//                   : { id: "Location", value: [] }
//               }
//               setFiltered={handleSetFilter}
//             />
//           </div>
//<div className="h-[1px] w-[90%] border-x-0  border-t-0 border-b border-solid border-[#D8D8D8] m-auto" />
