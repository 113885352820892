import React from 'react'

import ItemImage from '../ItemImage/ItemImage'
import Accordion from 'react-bootstrap/Accordion';
import './ExpandableRows.css'

const ExpandableRows = ({ data, isCsr }) => {
    let is_active = 'active'

    const handleIdClick = (id) => {
        window.open(`${process.env.REACT_APP_API_URL}/#/items/${id}`, "")
    }

    return (
        <div className="row " >

            {/*Raw Data Column*/}
            <div className="col-lg-8 col-sm-12">
                <div className="tab-content pt-2 profile">
                    <div className="tab-pane fade show active profile-overview" id="profile-overview">
                        <div className='row col-lg-12 card-parent-expandable'>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row fs" style={{ fontSize: "0.8rem" }}>
                                        <div className="col-lg-6">
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">ID</div>
                                                <div className="col-6  col-lg-6 col-md-4 id-handler" onClick={() => handleIdClick(data.id)}>{data.id}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4  label">Stock Number</div>
                                                <div className="col-6    col-lg-6 col-md-4">{data.item_attributes['diamond#stock_number'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4  label">Shape</div>
                                                <div className="col-6    col-lg-6 col-md-4">{data.item_attributes['diamond#shape'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6  col-lg-6 col-md-4 label">Carats</div>
                                                <div className="col-6   col-lg-6 col-md-4">{data.item_attributes['gem#carats'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6  col-lg-6 col-md-4  label">Color</div>
                                                <div className="col-6    col-lg-6 col-md-4">{data.item_attributes['diamond#color'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4  label">Clarity</div>
                                                <div className="col-6  col-lg-6 col-md-4">{data.item_attributes['diamond#clarity'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Cut</div>
                                                <div className="col-6  col-lg-6 col-md-4">{data.item_attributes['diamond#cut'].trans || 'N/A'}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Certificate #</div>
                                                <div className='col-6 col-lg-6 col-md-4'>: {data.item_attributes['diamond#cert_number'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4  label">Lab</div>
                                                <div className='col-6 col-lg-6 col-md-4'>: <a href={data.item_attributes['diamond#cert_url'].trans || 'N/A'} target="_blank"> {data.item_attributes['diamond#cert'].trans || 'N/A'}</a></div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Polish</div>
                                                <div className='col-6 col-lg-6 col-md-4'>: {data.item_attributes['diamond#polish'].trans || 'N/A'} </div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4  label">Symmetry</div>
                                                <div className='col-6 col-lg-6 col-md-4'>: {data.item_attributes['diamond#symmetry'].trans || 'N/A'} </div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4  label">Fluorescence</div>
                                                <div className='col-6 col-lg-6 col-md-4'>: {data.item_attributes['diamond#fluor'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Fluor Color</div>
                                                <div className='col-6 col-lg-6 col-md-4'>: {data.item_attributes['diamond#fluor_color'].trans || 'N/A'} </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="row fs">
                                        <div className="col-lg-6">
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Shade</div>
                                                <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#shade'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Milky</div>
                                                <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#milky'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Certificate Comment</div>
                                                <div className=' col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#cert_comment'].trans || 'N/A'} </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Eye Clean #</div>
                                                <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#eye_clean'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Black In Center</div>
                                                <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#black_in_center'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Key to Symbol</div>
                                                <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#key_to_symbol'].trans || 'N/A'} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Accordion >
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Other Physical Attributes
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row fs">
                                                <div className="col-lg-6">
                                                    <div className="row fs">
                                                        <div className="col-6 col-6 col-lg-6 col-md-4 label">Dimensions</div>
                                                        <div className='col-6 col-6 col-lg-6 col-md-8'>: {data.item_attributes['gem#dims'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-6 col-lg-6 col-md-4 label">Black in Side</div>
                                                        <div className='col-6 col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#black_in_side'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">White in Center</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#white_in_center'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">White in Side</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#white_in_side'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Open in Table</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#open_in_table'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Open in Crown</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#open_in_crown'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Open in Pavilion</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#open_in_pav'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Hearts & Arrows</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#hearts_arrows'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Availability</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#availability'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Treatment</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#treatment'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-6 col-6 col-lg-6 col-md-4 label">Laser Inscribed</div>
                                                        <div className='col-6 col-6 col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#laser_inscribed'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-6 col-lg-6 col-md-4 label">Location</div>
                                                        <div className='col-6 col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#location'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Report Date</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#report_date'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Report Type</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#report_type'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Lab Location</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#lab_location'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Full Shape Description</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#full_shape_desc'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Color Description</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#color_desc'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Country Of Origin</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#country_of_origin'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Star Length</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#star_length'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Member Comment</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.item_attributes['diamond#member_comments'].trans || 'N/A'}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Table %</div>
                                                        <div className='col-lg-6  col-md-8'>: {data.item_attributes['diamond#table_percentage'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Depth %</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#depth_percentage'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Crown Angle</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#crown_angle'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Crown Height</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#crown_height'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Pavilion Angle</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#pavilion_angle'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Pavilion Depth</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#pavilion_depth'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Girdle</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#girdle'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Girdle Condition</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#girdle_condition'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Girdle Minimum</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#girdle_min'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Girdle Maximum</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#girdle_max'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-6 col-lg-6 col-md-4 label">Girdle Percentage</div>
                                                        <div className='col-6 col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#girdle_percentage'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-6 col-lg-6 col-md-4 label">Culet</div>
                                                        <div className='col-6 col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#culet'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Culet Condition</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#culet_condition'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Type2A</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#type_twoa'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Length</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['gem#dim_l'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Width</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['gem#dim_w'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Depth</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['gem#dim_d'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Lower Half</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#lower_half'].trans || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Laser Inscription</div>
                                                        <div className='col-6 col-lg-6  col-md-8'>: {data.item_attributes['diamond#laser_inscription'].trans || 'N/A'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>



                                <br />
                                <div className="card-body">
                                    <div className="row fs">
                                        <div className="col-12 col-lg-6 col-md-6">
                                            <div className="row fs">
                                                <div className="col-6  col-lg-6 col-md-4 label">Price Per Carat</div>
                                                <div className='col-6  col-lg-6 col-md-8'>: {data.item_attributes['gem#price_per_carat'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6  col-lg-6 col-md-4 label">Rap Price / Carat</div>
                                                <div className='col-6   col-lg-6 col-md-8'>: {data.item_attributes['diamond#rap_price'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6   col-lg-6 col-md-4 label">Top Bid</div>
                                                <div className='col-6   col-lg-6 col-md-8'>: {data.item_attributes['gem#carats'].trans || 'N/A'} </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6 col-md-6">
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Total Price</div>
                                                <div className='col-6   col-lg-6 col-md-8'>: ${data.sell_offer_cents / 100}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Rap %</div>
                                                <div className='col-6   col-lg-6 col-md-8'>: {data.item_attributes['diamond#rap_percent'].trans || 'N/A'}</div>
                                            </div>
                                            <div className="row fs">
                                                <div className="col-6 col-lg-6 col-md-4 label">Top Bid Gap</div>
                                                <div className='col-6   col-lg-6 col-md-8'>: {data.item_attributes['diamond#polish'].trans || 'N/A'} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                {isCsr ? <Accordion>
                                    <Accordion.Item>
                                        <Accordion.Header>

                                            Supplier Details

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row fs">
                                                <div className="col-lg-6">
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Account</div>
                                                        <div className='col-6 col-lg-6 col-md-8'>: {data.account_name || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-6 col-md-4 label">Contact Person</div>
                                                        <div className='col-6  col-lg-6 col-md-8'>: {data.account_username || 'N/A'}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-4 col-md-4 label">Email</div>
                                                        <div className='col-6  col-lg-8  col-md-8'>: {data.account_email || 'N/A'}</div>
                                                    </div>
                                                    <div className="row fs">
                                                        <div className="col-6 col-lg-4 col-md-4 label">Phone</div>
                                                        <div className='col-6  col-lg-8  col-md-8'>: {data.account_phone || 'N/A'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion> : null}

                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Image Data Column*/}
            <div className="col-md-12 col-sm-12 col-12 col-lg-4 image-preview pt-2">
                <div className="card custom-card">
                    <div className="card-body">
                        <h5 className="card-title fs">Item Images</h5>
                        <p></p>
                        {
                            // console.log(data)
                        }
                        {data.asset_urls ?
                            <>{
                                <ItemImage assets={data.asset_urls} keyToSymbol={data.item_attributes["diamond#key_to_symbol"]} />

                            }
                            </>

                            : <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center"
                            }}>
                                No Images for this set of Diamonds
                            </div>}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ExpandableRows