import React from 'react';
import { ChevronFirst, ChevronLast, ChevronLeft, ChevronRight } from 'lucide-react'; // Assuming you're using these icons

const MarketPagination = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions = [50, 100, 250]
}) => {
  const calculateTotalPages = () => Math.ceil(totalCount / pageSize);

  const goToPage = (page) => {
    const totalPages = calculateTotalPages();
    const validPage = Math.max(1, Math.min(page, totalPages));
    onPageChange(validPage);
  };

  const goToFirstPage = () => goToPage(1);
  const goToLastPage = () => goToPage(calculateTotalPages());

  const handlePageInputChange = (e) => {
    const page = e.target.value ? Number(e.target.value) : 1;
    goToPage(page);
  };

  const handlePageSizeChange = (e) => {
    const newSize = Number(e.target.value);
    onPageSizeChange(newSize);
    goToPage(1);
  };

  return (
    <div className="flex items-center justify-end py-2 px-4 mt-2 gap-2"
    onClick={(e) => {  
                e.stopPropagation();
                e.preventDefault();
              }}
    >
      <button
        className={`border rounded p-1 flex justify-center items-center bg-blue-100  bg-opacity-10
            ${currentPage === 1 ? ' text-blue-300 cursor-not-allowed' : ' text-blue-600 cursor-pointer hover:bg-blue-300 hover:bg-opacity-20'}`}
        onClick={goToFirstPage}
        disabled={currentPage === 1}
      >
        <ChevronFirst className="h-4 w-4" />
      </button>
      <button
        className={`border rounded p-1 flex justify-center items-center  bg-blue-100  bg-opacity-10 
            ${currentPage === 1 ? 'text-blue-300 cursor-not-allowed' : 'text-blue-600 cursor-pointer hover:bg-blue-300 hover:bg-opacity-20'}`}
        onClick={() => goToPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeft className="h-4 w-4" />
      </button>
      <button
        className={`border rounded p-1 flex justify-center items-center  bg-blue-100  bg-opacity-10 
            ${currentPage === calculateTotalPages() ? 'text-blue-300 cursor-not-allowed' : 'text-blue-600 cursor-pointer hover:bg-blue-300 hover:bg-opacity-20'}`}
        onClick={() => goToPage(currentPage + 1)}
        disabled={currentPage === calculateTotalPages()}
      >
        <ChevronRight className="h-4 w-4" />
      </button>
      <button
        className={`border rounded p-1 flex justify-center items-center  bg-blue-100  bg-opacity-10 
            ${currentPage === calculateTotalPages() ? 'text-blue-300 cursor-not-allowed' : 'text-blue-600 cursor-pointer hover:bg-blue-300 hover:bg-opacity-20'}`}
        onClick={goToLastPage}
        disabled={currentPage === calculateTotalPages()}
      >
        <ChevronLast className="h-4 w-4" />
      </button>
      <span className="flex items-center gap-1 text-xs">
        <div>Page</div>
          <span className="text-blue-600 text-xs ">
            {Math.min(currentPage, calculateTotalPages())}
          </span> of {calculateTotalPages()}
        
      </span>
      <span className="flex items-center gap-1 text-xs">
        | Go to page:
        <input
          type="number"
          min="1"
          max={calculateTotalPages()}
          value={currentPage}
          onChange={handlePageInputChange}
          className="border p-1 rounded w-[2rem] text-center text-xs"
        />
      </span>
      <select
        value={pageSize}
        onChange={handlePageSizeChange}
        className="border px-2 py-1 rounded bg-transparent border-blue-800 text-xs"
      >
        {pageSizeOptions.map((size) => (
          <option key={size} value={size}>
            Show {size}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MarketPagination;