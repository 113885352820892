import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import amplitude from "../../utils/ampli";

export default function CustomTabs({ value, setValue, lab }) {
  const handleChange = (event, newValue) => {
      setValue(newValue);
      const buttonClickedEvent = {
        event_type: `v2_Custom_Tab_Report_Media`,
        event_properties: {
          value: newValue ? "Report" : "Media",
        },
      };
      amplitude.track(buttonClickedEvent);
  };

  useEffect(() => {
    if (lab !== 'GIA' && value === 1) {
      setValue(0);
    }
  }, [lab]);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      style={{
        margin: "auto 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Tab  
        label={
          <div className="flex justify-between items-center gap-1">
            <VideocamOutlinedIcon fontSize="small" />Media
          </div>
        }
        className="text-[0.687rem]"
      />
      {lab === 'GIA' && (
        <Tab 
          label={
            <div className="flex justify-between items-center gap-1">
              <VerifiedUserOutlinedIcon fontSize="small" />Report
            </div>
          }
          className="text-[0.687rem]"
        />
      )}
    </Tabs>
  );
}
