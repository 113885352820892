import { ArrowDown, ArrowUp } from "lucide-react";
import React, { useState } from "react";

const HalfExpandedCard = ({ children }) => {
  const [isExpanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div
      className={`border  mb-4 overflow-hidden ${
        isExpanded ? "h-auto" : "h-[200px]"
      } transition-all relative`}
    >
      {children}
      {!isExpanded && (
        <div
          className="absolute bottom-0  cursor-pointer text-xl bg-white w-full flex justify-center "
          onClick={toggleExpansion}
        >
          <ArrowDown />
        </div>
      )}
      {isExpanded && (
        <div
          className=" bottom-0  cursor-pointer text-xl bg-white w-full flex justify-center"
          onClick={toggleExpansion}
        >
          <ArrowUp />
        </div>
      )}
    </div>
  );
};

export default HalfExpandedCard;
