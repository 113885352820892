import React, { useEffect } from "react";
import { useState } from "react";
import ReactPlayer from "react-player";
import "./ItemImage.css";
import { useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/outline";
import KeyToSymbol from "../keyToSymbol/KeyToSymbol";

const ItemImage = ({ assets, keyToSymbol }) => {
  const [state, setState] = useState(assets[0]);
  const [active, setActive] = useState(0);
  const modalRef = useRef();
  const [keySymbols, setKeySymbols] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  const imageVideoclassNameifier = (asset) => {
    switch (asset.content_type) {
      case "image/jpeg" || "image/png":
        let x =
          "https://images.pexels.com/photos/16273384/pexels-photo-16273384.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            {isExpanded ? (
              <ArrowsPointingInIcon
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  bottom: "5",
                  right: "5",
                }}
              />
            ) : (
              <ArrowsPointingOutIcon
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  bottom: "5",
                  right: "5",
                }}
                onClick={handleShow}
              />
            )}
            <img
              src={asset.url}
              onError={(ev) => (ev.target.src = "assets/img/no-image.png")}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              onClick={handleShow}
            />
          </div>
        );

      case "html":
        if (asset.url.toLowerCase().includes(".pdf")) break;

        let y = "https://www.youtube.com/watch?v=goxmvGJkoi0&t=1707s";

        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            {isExpanded ? (
              <ArrowsPointingInIcon
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  bottom: "5",
                  right: "5",
                }}
              />
            ) : (
              <ArrowsPointingOutIcon
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  bottom: "5",
                  right: "5",
                }}
                onClick={handleShow}
              />
            )}
            <iframe
              src={`${asset.url}?&btn=0&zoomslide=0&sv=0&z=0`}
              style={{
                height: "100%",
                width: "100%",
              }}
              onClick={handleShow}
            ></iframe>
          </div>
        );

      case "video/mp4":
        return (
          <div className="player-div">
            <ReactPlayer
              controls={true}
              url={asset.url}
              style={{
                height: "100%",
              }}
            />
          </div>
        );
      default:
        console.log(asset.content_type.includes("image"), asset.content_type);
    }
  };

  const getKeyToSymbol = () => {
    if (keyToSymbol.ori) {
      let key_array = keyToSymbol.ori.split(",").map((item) => item.trim());
      let key_images = {};
      key_array.forEach((key) => {
        let filterKey = key.replaceAll("_", "");
        key_images[key] = `https://app.liquid.diamonds/assets/${filterKey}.jpg`;
      });

      setKeySymbols(key_images);
    } else {
      setKeySymbols({});
    }
  };

  const handleOnClick = (asset, index) => {
    setState(asset);
    setActive(index);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (keyToSymbol.ori !== null || keyToSymbol.ori !== "") {
      getKeyToSymbol();
    }
  }, []);

  return (
    <div className="item-container">
      <Modal show={show} onHide={handleClose} size="lg" fullscreen="true">
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div
            style={{
              width: "100%",
              height: "750px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {assets[active].content_type == "html" ? (
              <iframe
                src="https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/VISION360/Vision360.html?d=433595848"
                style={{
                  height: "750px",
                  width: "100%",
                }}
              ></iframe>
            ) : (
              <img
                src={assets[active].url}
                onError={(ev) => (ev.target.src = "assets/img/no-image.png")}
                style={{
                  alignItems: "center",
                  width: "100%",
                  // height: "100%",
                  objectFit: "contain",
                }}
              />
            )}
            {(assets[active].is_plot !== null) &
            (assets[active].content_type != "html") ? (
              <KeyToSymbol keySymbols={keySymbols} modal={true} />
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      <div className="item-display-holder">
        {imageVideoclassNameifier(state)}
      </div>
      <div
        style={{
          position: "relative",
          // minHeight: "470px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        className="side-item-container"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 10px",
            flexWrap: "wrap",
          }}
        >
          {assets.map((asset, index) => {
            return (
              <>
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    margin: "10px",
                    marginLeft: "0px",
                    border:
                      active == index ? "3px solid red" : "2px solid black",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                  onClick={() => handleOnClick(asset, index)}
                >
                  {asset.content_type !== "html" ? (
                    <img
                      src={asset.thumb_url}
                      onError={(ev) =>
                        (ev.target.src = "assets/img/no-image.png")
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <img
                      src={"assets/img/default_html.png"}
                      onError={(ev) =>
                        (ev.target.src = "assets/img/no-image.png")
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
              </>
            );
          })}
        </div>
        {/* {
                assets[active].isPlot ?
                    <KeyToSymbol keySymbols={keySymbols} modal={false} /> : null
            } */}
        <KeyToSymbol keySymbols={keySymbols} modal={false} />
      </div>
    </div>
  );
};

export default ItemImage;

// <div className="modal" tabIndex="-1" ref={modalRef}
//             >

//                 {/* {
//                     assets[active].isPlot ?
//                         <KeyToSymbol keySymbols={keySymbols} modal={true} /> : null
//                 } */} {
//                     assets[active].is_plot !== null ?
//                         <KeyToSymbol keySymbols={keySymbols} modal={true} />
//                         : null
//                 }

//                 <div className="modal-dialog"
//                     style={{
//                         minWidth: "60%",
//                         minHeight: "60%",
//                         margin: "auto",
//                         marginTop: "40px"
//                     }}
//                 >
//                     <div className="modal-content"

//                         style={
//                             assets[active].content_type == "html" ?
//                                 {
//                                     width: "100%",
//                                     display: "flex",
//                                     position: "relative",
//                                     minHeight: "750px"
//                                 } :
//                                 {
//                                     width: "100%",
//                                     display: "flex",
//                                     position: "relative"
//                                 }
//                         }

//                     >
//                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideModal}

//                             style={{
//                                 position: "absolute",
//                                 top: "0",
//                                 right: "0",
//                                 backgroundColor: "white"
//                             }}
//                         ></button>
//                         {
//                             assets[active].content_type == "html" ?
//                                 <iframe src="https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/VISION360/Vision360.html?d=433595848" style={{
//                                     height: "750px",
//                                     width: "100%"
//                                 }} ></iframe>
//                                 :
//                                 <img src={assets[active].url} style={{
//                                     width: "100%",
//                                     height: "100%",
//                                     objectFit: "contain",
//                                 }} />
//                         }

//                     </div>
//                 </div>
//             </div>
